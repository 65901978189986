import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const StatusIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58388 1.94574C7.58388 1.56958 7.88881 1.26465 8.26497 1.26465C11.9848 1.26465 15.0002 4.28002 15.0002 7.99988C15.0002 11.7197 11.9848 14.7351 8.26497 14.7351C7.88881 14.7351 7.58388 14.4302 7.58388 14.054C7.58388 13.6779 7.88881 13.3729 8.26497 13.3729C11.2325 13.3729 13.638 10.9674 13.638 7.99988C13.638 5.03234 11.2325 2.62683 8.26497 2.62683C7.88881 2.62683 7.58388 2.3219 7.58388 1.94574ZM5.53985 3.33894C5.97425 3.08823 6.12312 2.53284 5.87226 2.09842C5.62155 1.66402 5.06616 1.51531 4.63175 1.76601C4.19734 2.01688 4.04863 2.57228 4.29933 3.00653C4.55021 3.44093 5.1056 3.5898 5.53985 3.33894ZM3.26955 4.03543C3.70396 4.28629 3.85284 4.8417 3.60197 5.27611C3.35127 5.71036 2.79586 5.85923 2.36145 5.60853C1.92703 5.35767 1.77833 4.80226 2.02903 4.36784C2.27989 3.93343 2.8353 3.78472 3.26955 4.03543ZM2.8163 7.99924C2.8163 8.50079 2.40962 8.90731 1.90807 8.90731C1.40652 8.90731 1 8.50079 1 7.99924C1 7.49769 1.40652 7.09117 1.90807 7.09117C2.40962 7.09117 2.8163 7.49769 2.8163 7.99924ZM5.53993 12.6599C5.97435 12.9107 6.12305 13.4662 5.87235 13.9006C5.62149 14.3348 5.06608 14.4837 4.63167 14.233C4.19742 13.9821 4.04855 13.4267 4.29941 12.9923C4.55011 12.5581 5.10552 12.4092 5.53993 12.6599ZM3.27176 11.9635C3.70617 11.7128 3.85503 11.1574 3.60418 10.723C3.35346 10.2886 2.79806 10.1397 2.36366 10.3906C1.92925 10.6415 1.78054 11.1968 2.03124 11.6311C2.28212 12.0655 2.83751 12.2144 3.27176 11.9635ZM10.9285 7.08865C11.1984 6.85201 11.2254 6.44134 10.9888 6.17139C10.7521 5.90145 10.3415 5.87446 10.0715 6.11111L7.5 8.36547L6.42849 7.42611C6.15855 7.18947 5.74788 7.21645 5.51123 7.48639C5.27458 7.75634 5.30157 8.16701 5.57151 8.40366L7.07151 9.71865C7.31673 9.93363 7.68327 9.93363 7.92849 9.71865L10.9285 7.08865Z"
      fill="currentcolor"
    />
  </Svg>
);
