import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SuitCaseWorkIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_3286_19277)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2C5.22386 2 5 2.22386 5 2.5V3H8V2.5C8 2.22386 7.77615 2 7.5 2H5.5ZM9 3V2.5C9 1.67158 8.32845 1 7.5 1H5.5C4.67157 1 4 1.67158 4 2.5V3H3C2.17157 3 1.5 3.67158 1.5 4.5V9C1.5 9.82845 2.17157 10.5 3 10.5H10C10.8284 10.5 11.5 9.82845 11.5 9V4.5C11.5 3.67158 10.8284 3 10 3H9Z"
        fill="url(#paint0_linear_3286_19277)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3286_19277"
        x="8.9407e-08"
        y="0"
        width="13"
        height="13"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3286_19277"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3286_19277"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3286_19277"
        x1="6.5"
        y1="1"
        x2="6.5"
        y2="10.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop
          offset="1"
          stopColor="white"
          stopOpacity="0.5"
        />
      </linearGradient>
    </defs>
  </Svg>
);
