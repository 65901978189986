import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FrontIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 123 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M.7 29.008C.7 12.988 13.687 0 29.708 0h93.019v41.28c0 8.01-6.493 14.504-14.504 14.504H70.43c-7.702 0-13.946 6.244-13.946 13.946v90.091c0 8.01-6.494 14.503-14.504 14.503H.7V29.008z"
      fill="#001B38"
    />
    <circle
      cx="70.778"
      cy="70.078"
      r="37.654"
      transform="rotate(90 70.778 70.078)"
      fill="url(#front-logo-icon_svg__paint0_linear)"
    />
    <circle
      cx="70.778"
      cy="70.078"
      r="37.654"
      transform="rotate(90 70.778 70.078)"
      fill="url(#front-logo-icon_svg__paint1_linear)"
      opacity="0.5"
    />
    <defs>
      <linearGradient
        id="front-logo-icon_svg__paint0_linear"
        x1="42.552"
        y1="96.597"
        x2="99.568"
        y2="39.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#FF0057"
          stopOpacity="0.16"
        />
        <stop
          offset="0.861"
          stopColor="#FF0057"
        />
      </linearGradient>
      <linearGradient
        id="front-logo-icon_svg__paint1_linear"
        x1="42.552"
        y1="96.597"
        x2="99.568"
        y2="39.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#FF0057"
          stopOpacity="0.16"
        />
        <stop
          offset="0.861"
          stopColor="#FF0057"
        />
      </linearGradient>
    </defs>
  </Svg>
);
