import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ListSparkleIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M2.66666 12H4.66666M2.66666 8H5.99999M2.66666 4H13.3333M11.3333 6.66667L12.3333 9L14.6667 10L12.3333 11L11.3333 13.3333L10.3333 11L7.99999 10L10.3333 9L11.3333 6.66667Z"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
