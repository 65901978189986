import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const StrikeIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00012 2H10.6667C11.0348 2 11.3333 2.29848 11.3333 2.66667C11.3333 3.03486 11.0348 3.33333 10.6667 3.33333H5.99999C5.78662 3.33322 5.576 3.38432 5.38647 3.48233C5.19695 3.58034 5.03371 3.7224 4.91048 3.89658C4.78725 4.07076 4.70762 4.27197 4.67828 4.48331C4.64894 4.69465 4.67074 4.90995 4.74186 5.11111C4.86459 5.45824 4.68268 5.83914 4.33555 5.96187C3.98841 6.0846 3.60752 5.90269 3.48479 5.55556C3.34254 5.15323 3.29893 4.72264 3.35761 4.29996C3.41629 3.87728 3.57556 3.47486 3.82202 3.1265C4.06849 2.77813 4.39496 2.49401 4.774 2.29799C5.15295 2.10202 5.57351 1.99983 6.00012 2ZM12 8.66665H13.3333C13.7015 8.66665 14 8.36817 14 7.99998C14 7.63179 13.7015 7.33331 13.3333 7.33331H9.33331H2.66667C2.29848 7.33331 2 7.63179 2 7.99998C2 8.36817 2.29848 8.66665 2.66667 8.66665H9.33331C9.86375 8.66665 10.3725 8.87736 10.7475 9.25243C11.1226 9.62751 11.3333 10.1362 11.3333 10.6666C11.3333 11.1971 11.1226 11.7058 10.7475 12.0809C10.3725 12.4559 9.86375 12.6666 9.33331 12.6666H3.99998C3.63179 12.6666 3.33331 12.9651 3.33331 13.3333C3.33331 13.7015 3.63179 14 3.99998 14H9.33331C10.2174 14 11.0652 13.6488 11.6903 13.0237C12.3155 12.3985 12.6666 11.5507 12.6666 10.6666C12.6666 9.94126 12.4302 9.24024 12 8.66665Z"
      fill="#171717"
    />
  </Svg>
);
