import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ShareIcon = (props: SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M14.4593 7.51663L9.42727 2.73617C9.00261 2.33275 8.30143 2.63377 8.30143 3.21951V5.3333C8.30143 5.51739 8.14942 5.66633 7.96535 5.66926C2.57518 5.75499 1.13477 7.947 1.13477 13.5C2.11459 11.5403 2.61439 10.3807 7.96471 10.3347C8.1488 10.3331 8.30143 10.4825 8.30143 10.6666V12.7804C8.30143 13.3662 9.00261 13.6672 9.42726 13.2638L14.4593 8.4833C14.736 8.22046 14.736 7.77947 14.4593 7.51663Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
  </Svg>
);
