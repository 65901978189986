/* eslint-disable max-len */
import { FC } from 'react';

import { Svg, SvgProps, FILL_INHERIT_CLASS_NAME, FILL_NONE_CLASS_NAME } from '../../components/Svg/Svg.styles';

export const ImageAddHoverIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.25"
      y="5.25"
      width="19.5"
      height="19.5"
      rx="3.75"
      stroke="#C4C4C4"
      strokeWidth="1.5"
      fill="none"
    />
    <path
      className={FILL_NONE_CLASS_NAME}
      d="M5.75 23.2502L15.3281 15.7245C15.8699 15.2988 16.6319 15.2969 17.1758 15.7199L23.0002 20.25"
      stroke="#C4C4C4"
      strokeWidth="1.5"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M9.33301 12.666C10.2995 12.666 11.083 11.8825 11.083 10.916C11.083 9.94952 10.2995 9.16602 9.33301 9.16602C8.36651 9.16602 7.58301 9.94952 7.58301 10.916C7.58301 11.8825 8.36651 12.666 9.33301 12.666Z"
      fill="white"
    />
    <circle
      cx="21"
      cy="22"
      r="8"
      className={FILL_INHERIT_CLASS_NAME}
    />
    <path
      className="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9998 18.25C21.3136 18.25 21.568 18.5044 21.568 18.8182V25.1818C21.568 25.4956 21.3136 25.75 20.9998 25.75C20.686 25.75 20.4316 25.4956 20.4316 25.1818V18.8182C20.4316 18.5044 20.686 18.25 20.9998 18.25Z"
      fill="white"
    />
    <path
      className="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 21.9998C17.25 21.686 17.5044 21.4316 17.8182 21.4316H24.1818C24.4956 21.4316 24.75 21.686 24.75 21.9998C24.75 22.3136 24.4956 22.568 24.1818 22.568H17.8182C17.5044 22.568 17.25 22.3136 17.25 21.9998Z"
      fill="white"
    />
  </Svg>
);
