import { ComponentProps } from 'react';

import icon from './googledrive.png';

export const GoogleDriveIcon = (props: ComponentProps<'img'>) => (
  <img
    width="16"
    src={icon}
    {...props}
  />
);
