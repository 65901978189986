import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const IdIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.60114 2.00795C6.68457 2.02123 6.76456 2.05085 6.83656 2.09512C6.90855 2.13939 6.97114 2.19745 7.02073 2.26597C7.07032 2.33449 7.10595 2.41213 7.12558 2.49446C7.14522 2.57679 7.14846 2.66219 7.13514 2.74577L6.77429 5.00562H10.1857L10.58 2.54221C10.6127 2.37896 10.7071 2.23472 10.8436 2.13974C10.98 2.04476 11.1479 2.00641 11.312 2.03274C11.476 2.05906 11.6235 2.14802 11.7236 2.28095C11.8236 2.41389 11.8684 2.58047 11.8486 2.74577L11.4886 5.00562H13.3571C13.5276 5.00562 13.6912 5.07349 13.8117 5.19431C13.9323 5.31512 14 5.47897 14 5.64982C14 5.82067 13.9323 5.98453 13.8117 6.10534C13.6912 6.22615 13.5276 6.29402 13.3571 6.29402H11.2829L10.7343 9.72975H12.9286C13.0991 9.72975 13.2626 9.79762 13.3831 9.91843C13.5037 10.0392 13.5714 10.2031 13.5714 10.3739C13.5714 10.5448 13.5037 10.7087 13.3831 10.8295C13.2626 10.9503 13.0991 11.0181 12.9286 11.0181H10.5286L10.1343 13.4824C10.1016 13.6457 10.0072 13.7899 9.8707 13.8849C9.73424 13.9799 9.56638 14.0182 9.40232 13.9919C9.23826 13.9656 9.09074 13.8766 8.9907 13.7437C8.89066 13.6107 8.84587 13.4442 8.86571 13.2789L9.22571 11.0181H5.81429L5.42 13.4824C5.38734 13.6457 5.29288 13.7899 5.15642 13.8849C5.01995 13.9799 4.85209 14.0182 4.68804 13.9919C4.52398 13.9656 4.37646 13.8766 4.27642 13.7437C4.17638 13.6107 4.13158 13.4442 4.15143 13.2789L4.51143 11.0181H2.64286C2.47236 11.0181 2.30885 10.9503 2.18829 10.8295C2.06773 10.7087 2 10.5448 2 10.3739C2 10.2031 2.06773 10.0392 2.18829 9.91843C2.30885 9.79762 2.47236 9.72975 2.64286 9.72975H4.71714L5.26571 6.29402H3.07143C2.90093 6.29402 2.73742 6.22615 2.61686 6.10534C2.4963 5.98453 2.42857 5.82067 2.42857 5.64982C2.42857 5.47897 2.4963 5.31512 2.61686 5.19431C2.73742 5.07349 2.90093 5.00562 3.07143 5.00562H5.47143L5.86571 2.54221C5.89268 2.3737 5.98526 2.2228 6.12315 2.12264C6.26103 2.02247 6.43294 1.98122 6.60114 2.00795ZM9.43143 9.72975L9.98 6.29402H6.56857L6.02 9.72975H9.43143Z"
    />
  </Svg>
);
