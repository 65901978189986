import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LoadingIcon: FC<React.PropsWithChildren<SvgProps & { isBump?: boolean }>> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.4"
      d="M8 15C6.61553 15 5.26215 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26215 15 6.61553 15 8C14.9979 9.85588 14.2597 11.6351 12.9474 12.9474C11.6351 14.2597 9.85588 14.9979 8 15ZM8 2.75C6.96165 2.75 5.94662 3.05791 5.08326 3.63479C4.2199 4.21166 3.547 5.0316 3.14964 5.99091C2.75228 6.95023 2.64831 8.00583 2.85088 9.02423C3.05345 10.0426 3.55347 10.9781 4.28769 11.7123C5.02192 12.4465 5.95738 12.9466 6.97578 13.1491C7.99418 13.3517 9.04978 13.2477 10.0091 12.8504C10.9684 12.453 11.7883 11.7801 12.3652 10.9167C12.9421 10.0534 13.25 9.03835 13.25 8C13.2486 6.60804 12.695 5.27349 11.7108 4.28923C10.7265 3.30496 9.39196 2.75139 8 2.75Z"
    />
    <path d="M15 8H13.25C13.2486 6.60804 12.695 5.27349 11.7108 4.28922C10.7265 3.30496 9.39196 2.75139 8 2.75V1C9.85588 1.00208 11.6351 1.74025 12.9474 3.05255C14.2597 4.36486 14.9979 6.14412 15 8Z" />
  </Svg>
);
