import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PaletteIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00256 1C4.15102 1 1.00256 4.14845 1.00256 8C1.00256 11.8515 4.15102 15 8.00256 15C8.97907 15 9.72194 14.2079 9.72194 13.2553C9.72194 12.7925 9.53277 12.3854 9.27521 12.0947C9.26593 12.0843 9.25632 12.0741 9.24641 12.0642C9.20306 12.021 9.16882 11.9701 9.16882 11.8327L9.16862 11.8211C9.16779 11.7728 9.17668 11.7249 9.19476 11.6802C9.21284 11.6355 9.23974 11.5948 9.27385 11.5607C9.30797 11.5266 9.34861 11.4997 9.39334 11.4816C9.43808 11.4635 9.486 11.4547 9.53424 11.4556H10.8079C13.1109 11.4556 14.9975 9.56989 14.9975 7.26658L14.9975 7.26279C14.9779 3.76683 11.753 1 8.00256 1ZM2.3574 8C2.3574 4.89671 4.89927 2.35484 8.00256 2.35484C11.1569 2.35484 13.6269 4.66014 13.6427 7.26848C13.6416 8.82236 12.3623 10.1007 10.8079 10.1007H9.55098C9.32314 10.0977 9.09696 10.1401 8.88567 10.2255C8.67234 10.3117 8.47854 10.44 8.31584 10.6027C8.15314 10.7654 8.02485 10.9592 7.93863 11.1725C7.85316 11.384 7.81076 11.6104 7.81389 11.8384C7.8151 12.2873 7.96173 12.6861 8.27024 13.0038C8.33207 13.0781 8.36711 13.1696 8.36711 13.2553C8.36711 13.4939 8.197 13.6452 8.00256 13.6452C4.89927 13.6452 2.3574 11.1033 2.3574 8ZM7.9574 4.52257C7.9574 3.97385 8.40222 3.52902 8.95095 3.52902C9.49967 3.52902 9.94449 3.97385 9.94449 4.52257C9.94449 5.07129 9.49967 5.51612 8.95095 5.51612C8.40222 5.51612 7.9574 5.07129 7.9574 4.52257ZM10.4865 7.05162C10.4865 6.5029 10.9313 6.05807 11.48 6.05807C12.0287 6.05807 12.4735 6.5029 12.4735 7.05162C12.4735 7.60034 12.0287 8.04517 11.48 8.04517C10.9313 8.04517 10.4865 7.60034 10.4865 7.05162ZM5.78957 4.16129C5.24085 4.16129 4.79602 4.60611 4.79602 5.15483C4.79602 5.70356 5.24085 6.14838 5.78957 6.14838C6.33829 6.14838 6.78312 5.70356 6.78312 5.15483C6.78312 4.60611 6.33829 4.16129 5.78957 4.16129ZM3.53162 8.31612C3.53162 7.7674 3.97644 7.32257 4.52516 7.32257C5.07389 7.32257 5.51871 7.7674 5.51871 8.31612C5.51871 8.86484 5.07389 9.30967 4.52516 9.30967C3.97644 9.30967 3.53162 8.86484 3.53162 8.31612Z"
      fill="#171717"
    />
  </Svg>
);
