import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiSimplifyIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="magic stick star"
      clipPath="url(#clip0_1627_18720)"
    >
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94554 2.62104L7.08239 4.39291C7.10571 4.69477 6.95818 4.98431 6.70027 5.14287L5.18635 6.07364L7.03363 6.51999C7.25435 6.57332 7.42668 6.74565 7.48001 6.96637L7.92636 8.81365L8.85712 7.29973C9.01569 7.04182 9.30523 6.89429 9.60709 6.91761L11.379 7.05446L10.2268 5.70142C10.0305 5.47091 9.97964 5.14996 10.0951 4.87008L10.7728 3.22721L9.12992 3.9049C8.85004 4.02036 8.52909 3.96952 8.29858 3.77323L6.94554 2.62104ZM5.66332 1.74659C5.60802 1.03055 6.44499 0.604097 6.99177 1.06971L8.89604 2.69131L11.2082 1.73753C11.8721 1.46367 12.5363 2.12789 12.2625 2.79179L11.3087 5.10396L12.9303 7.00823C13.3959 7.55501 12.9694 8.39198 12.2534 8.33667L9.75967 8.14407L8.4497 10.2748C8.07357 10.8866 7.14578 10.7396 6.97711 10.0415L6.55633 8.30003L1.90872 12.9476C1.67224 13.1841 1.28883 13.1841 1.05236 12.9476C0.815881 12.7112 0.815881 12.3278 1.05236 12.0913L5.69997 7.44367L3.95847 7.02288C3.26039 6.85421 3.11345 5.92643 3.72524 5.55029L5.85593 4.24033L5.66332 1.74659Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1627_18720">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
