import { FC } from 'react';
import styled from 'styled-components';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

const Root = styled(Svg)`
  color: #230078;
`;

export const DovetailIcon: FC<SvgProps> = (props) => (
  <Root
    {...props}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10L10.5 12V8L7 5.978L10.5 4L14 6V10ZM6.9995 6L3.4995 8V4L0 2L3.5 0L6.9995 2V6ZM6.9995 14L3.4995 16L3.5 12L0 10L3.4995 8L6.9995 10V14Z"
      fill="currentcolor"
    />
  </Root>
);
