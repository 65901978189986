import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ChevronDoubleRight: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.67085 4.25423C3.89866 4.02642 4.26801 4.02642 4.49581 4.25423L6.41667 6.17508C6.87228 6.63069 6.87228 7.36939 6.41667 7.825L4.49581 9.74585C4.26801 9.97366 3.89866 9.97366 3.67085 9.74585C3.44305 9.51805 3.44305 9.1487 3.67085 8.9209L5.59171 7.00004L3.67085 5.07919C3.44305 4.85138 3.44305 4.48203 3.67085 4.25423ZM7.75419 4.25423C7.98199 4.02642 8.35134 4.02642 8.57915 4.25423L10.5 6.17508C10.9556 6.63069 10.9556 7.36939 10.5 7.825L8.57915 9.74585C8.35134 9.97366 7.98199 9.97366 7.75419 9.74585C7.52638 9.51805 7.52638 9.1487 7.75419 8.9209L9.67504 7.00004L7.75419 5.07919C7.52638 4.85138 7.52638 4.48203 7.75419 4.25423Z"
      fill="currentcolor"
    />
  </Svg>
);
