import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ReleaseNoteIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M2.66781 2.66663H13.3345M2.66781 5.33329H7.00114M2.6665 7.99996H4.66797M7.91105 14H5.33447V11.4234L10.1363 6.62158C10.8478 5.91008 12.0013 5.91008 12.7129 6.62158C13.4244 7.33308 13.4244 8.48666 12.7129 9.19816L7.91105 14Z"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>

);
