import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GainsightIcon = (props: SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 12.6133V11.2876H10.4832L15.2339 8.56395L15.2617 8.5482L14.5974 7.40131L9.81854 10.1407L12.5612 5.4225L12.5774 5.39471L11.4388 4.74159L11.4231 4.73233L8.66423 9.47785V4H7.33345V9.48341L4.59174 4.76382L4.57599 4.73603L3.43698 5.38915L3.42076 5.39888L6.17961 10.1449L1.42852 7.42123L1.40073 7.40548L0.736958 8.55237L5.51491 11.2918H0V12.6142H4.97481C6.38295 12.4683 7.13983 11.6846 7.52707 11.0514C7.74805 10.6864 7.90825 10.2879 8.00139 9.87158C8.05466 10.1143 8.18574 10.5756 8.47617 11.0514C8.86341 11.6832 9.61982 12.4674 11.0317 12.6142H16V12.6133Z"
      fill="currentcolor"
    />
  </Svg>
);
