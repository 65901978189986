import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiCompleteIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7385 4.23801C10.97 4.00653 10.97 3.63123 10.7385 3.39975C10.507 3.16827 10.1317 3.16827 9.90025 3.39975L4.23388 9.06612L1.88688 6.71911C1.6554 6.48763 1.28009 6.48763 1.04861 6.71911C0.81713 6.95059 0.81713 7.3259 1.04861 7.55738L3.81475 10.3235C3.92591 10.4347 4.07668 10.4971 4.23388 10.4971C4.39109 10.4971 4.54185 10.4347 4.65301 10.3235L10.7385 4.23801ZM12.9514 6.45099C13.1829 6.21951 13.1829 5.84421 12.9514 5.61273C12.7199 5.38125 12.3446 5.38125 12.1131 5.61273L8.38306 9.3428L7.97236 8.93209C7.74088 8.70061 7.36557 8.70061 7.13409 8.93209C6.90261 9.16357 6.90261 9.53888 7.13409 9.77036L7.96393 10.6002C8.19541 10.8317 8.57072 10.8317 8.8022 10.6002L12.9514 6.45099Z"
    />
  </Svg>
);
