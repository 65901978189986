import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DownArrowIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0301 8.22C13.1705 8.36063 13.2494 8.55125 13.2494 8.75C13.2494 8.94875 13.1705 9.13937 13.0301 9.28L8.78009 13.53C8.63946 13.6705 8.44884 13.7493 8.25009 13.7493C8.05134 13.7493 7.86072 13.6705 7.72009 13.53L3.47009 9.28C3.33761 9.13783 3.26549 8.94978 3.26892 8.75548C3.27234 8.56118 3.35106 8.37579 3.48847 8.23838C3.62588 8.10097 3.81127 8.02225 4.00557 8.01882C4.19987 8.0154 4.38792 8.08752 4.53009 8.22L7.50009 11.19V3.75C7.50009 3.55109 7.57911 3.36032 7.71976 3.21967C7.86041 3.07902 8.05118 3 8.25009 3C8.449 3 8.63977 3.07902 8.78042 3.21967C8.92107 3.36032 9.00009 3.55109 9.00009 3.75V11.19L11.9701 8.22C12.1107 8.07955 12.3013 8.00066 12.5001 8.00066C12.6988 8.00066 12.8895 8.07955 13.0301 8.22Z"
      fill="#171717"
    />
  </Svg>
);
