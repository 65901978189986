import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ProductBoardIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M5.33304 5.25L10.6661 10.4999H0L5.33304 5.25Z"
      fill="#FF2638"
    />
    <path
      d="M0 0L5.33304 5.24991L10.6661 0H0Z"
      fill="#FFC600"
    />
    <path
      d="M5.33398 5.24991L10.667 10.4998L16.0001 5.24991L10.667 0L5.33398 5.24991Z"
      fill="#0079F2"
    />
  </Svg>
);
