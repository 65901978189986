import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CommentIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path d="M8 1C4.1395 1 1 3.75567 1 7.14483C1 10.534 4.1395 13.2885 8 13.2885C8.40465 13.2886 8.80872 13.2578 9.20867 13.1963L12.3878 14.9288C12.4735 14.9752 12.5693 14.9997 12.6667 15C12.8214 15 12.9697 14.9385 13.0791 14.8291C13.1885 14.7197 13.25 14.5714 13.25 14.4167V11.1628C13.792 10.6414 14.2256 10.018 14.5259 9.32846C14.8263 8.63892 14.9874 7.89683 15 7.14483C15 3.75567 11.8605 1 8 1Z" />
  </Svg>
);
