import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PauseIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="pause">
      <g id="Icon">
        <path d="M3.16675 2C2.89061 2 2.66675 2.22386 2.66675 2.5V13.5C2.66675 13.7761 2.89061 14 3.16675 14H6.16675C6.44289 14 6.66675 13.7761 6.66675 13.5V2.5C6.66675 2.22386 6.44289 2 6.16675 2H3.16675Z" />
        <path d="M9.83342 2C9.55727 2 9.33342 2.22386 9.33342 2.5V13.5C9.33342 13.7761 9.55727 14 9.83342 14H12.8334C13.1096 14 13.3334 13.7761 13.3334 13.5V2.5C13.3334 2.22386 13.1096 2 12.8334 2H9.83342Z" />
      </g>
    </g>
  </Svg>
);
