import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AddIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 1C8.4184 1 8.75758 1.47485 8.75758 2.06061V13.9394C8.75758 14.5251 8.4184 15 8.00001 15C7.58161 15 7.24243 14.5251 7.24243 13.9394V2.06061C7.24243 1.47485 7.58161 1 8.00001 1Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8.00001C1 7.58161 1.47485 7.24243 2.06061 7.24243H13.9394C14.5251 7.24243 15 7.58161 15 8.00001C15 8.4184 14.5251 8.75758 13.9394 8.75758H2.06061C1.47485 8.75758 1 8.4184 1 8.00001Z"
    />
  </Svg>
);
