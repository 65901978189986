export function isCloudinarySrc(src: string) {
  return src.includes('res.cloudinary.com');
}

export function getOptimizedSrc(src: string, width: number) {
  if (!isCloudinarySrc) return src;

  return enhanceCloudinarySrc(src, getSizeOptimizations(width * 2));
}

function getSizeOptimizations(width?: number) {
  if (!width) return 'q_auto,f_auto';

  return `w_${width},c_limit,q_auto,f_auto`;
}

function enhanceCloudinarySrc(src: string, transformations: string) {
  return `${src.replace('/upload/', `/upload/${transformations}/`)}`;
}
