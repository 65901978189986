type ShareType = 'twitter' | 'linkedin';

const socialData: Record<ShareType, { baseUrl: string; textParam: string; urlParam: string }> = {
  linkedin: {
    baseUrl: 'https://www.linkedin.com/feed/?shareActive=true&mini=true',
    textParam: 'text',
    urlParam: 'shareUrl',
  },
  twitter: {
    baseUrl: 'https://twitter.com/intent/tweet',
    textParam: 'text',
    urlParam: 'url',
  },
};

export const shareSocial = ({
  type,
  text,
  url,
}: { type: ShareType; text: string; url?: string }) => {
  const data = socialData[type];
  const returnUrl = new URL(data.baseUrl);
  returnUrl.searchParams.append(data.textParam, text);

  if (url) {
    returnUrl.searchParams.append(data.urlParam, url);
  }

  return returnUrl.toString();
};
