import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const RescueRing: FC<SvgProps> = (props) => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 3.5L12.8659 7.13411M7.1289 12.8711L3.5 16.5M3.5 3.5L7.1289 7.1289M12.8659 12.8659L16.5 16.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10Z"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </Svg>
);
