import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ChartIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M4.21996 11.3334V7.33335H0.999959C0.815864 7.33335 0.666626 7.48259 0.666626 7.66669V10.8334C0.666626 11.1095 0.890484 11.3334 1.16663 11.3334H4.21996ZM4.21996 11.3334H7.7733M4.21996 11.3334L4.21997 1.00002C4.21997 0.815925 4.36921 0.666687 4.5533 0.666687H7.43997C7.62407 0.666687 7.7733 0.815925 7.7733 1.00002V11.3334M7.7733 11.3334L7.77327 4.00002H10.9933C11.1774 4.00002 11.3266 4.14926 11.3266 4.33335V11C11.3266 11.1841 11.1774 11.3334 10.9933 11.3334H7.7733Z"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </Svg>
);
