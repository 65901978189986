import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const EnjoyHqIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <g clipPath="url(#clip0_3264_74160)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05853 0H11.5582C12.9576 0 14.0582 1.10045 14.0054 2.45125V10.651C14.0054 11.9997 12.9069 13.1001 11.556 13.1001H9.9062L10.8069 15.9995L6.45744 13.1001H4.05853C2.65914 13.1001 1.55859 11.9996 1.55859 10.6004V2.45125C1.55859 1.10045 2.65914 0 4.05853 0ZM6.9712 8.9994C6.7159 8.85925 6.51185 8.65425 6.35901 8.38445C6.20639 8.1148 6.13008 7.7918 6.13008 7.41545V3.04426C6.13002 2.97684 6.11493 2.92538 6.0848 2.88989C6.05467 2.85441 6.00413 2.83667 5.93317 2.83667H4.8153C4.78993 2.83624 4.76478 2.84152 4.74172 2.85212C4.71867 2.86273 4.69829 2.87838 4.68209 2.89792C4.64672 2.93835 4.62773 2.99055 4.62887 3.04426V7.39425C4.62893 8.08995 4.77177 8.67385 5.05741 9.14585C5.34283 9.618 5.72686 9.96945 6.2095 10.2001C6.69215 10.4307 7.2298 10.5461 7.82245 10.5461C8.41865 10.5461 8.9572 10.4334 9.43815 10.208C9.91905 9.98265 10.3023 9.63485 10.588 9.1645C10.8737 8.6942 11.0166 8.1094 11.0166 7.4101V3.04958C11.0173 3.02168 11.0125 2.99391 11.0024 2.96787C10.9924 2.94184 10.9773 2.91806 10.9579 2.89792C10.9189 2.85708 10.8657 2.83667 10.7983 2.83667H9.7339C9.6699 2.83667 9.6184 2.85621 9.5794 2.8953C9.54035 2.93439 9.52085 2.98582 9.52085 3.04958V7.41545C9.52085 7.76685 9.4454 8.07925 9.29455 8.3526C9.1438 8.62595 8.9389 8.8371 8.6798 8.98615C8.4207 9.1352 8.135 9.2097 7.8227 9.2097C7.51035 9.2097 7.2265 9.1396 6.9712 8.9994Z"
        fill="#315CFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_3264_74160">
        <rect
          width="13"
          height="16"
          fill="white"
          transform="translate(1.5)"
        />
      </clipPath>
    </defs>
  </Svg>
);
