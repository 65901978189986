import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ZendeskIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    data-no-fill
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 965 692.6"
    // style="enable-background:new 0 0 965 692.6;"
  >

    <g>
      <polygon
        points="453.1,208.5 453.1,646.3 90.5,646.3"
        fill="currentcolor"
      />
      <path
        d="M453.1,51.4c0,100.1-81.2,181.3-181.3,181.3S90.5,151.5,90.5,51.4H453.1z"
        fill="currentcolor"
      />
      <path
        d="M512.9,646.3C512.9,546.1,594,465,694.2,465s181.3,81.2,181.3,181.3H512.9z"
        fill="currentcolor"
      />
      <polygon
        points="512.9,489.1 512.9,51.4 875.5,51.4"
        fill="currentcolor"
      />
    </g>
  </Svg>

);
