import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PenFilledIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4193 6.99932L9.00121 4.58125L3.27984 10.3027L4.51825 11.5412L5.69791 12.7209L11.4193 6.99932ZM12.6684 4.14702C13.1112 4.5898 13.1111 5.30749 12.6684 5.75013L11.4193 6.99932L10.1257 5.70576L9.00121 4.58125L10.2503 3.33201C10.693 2.88937 11.4108 2.88928 11.8534 3.33201L12.6684 4.14702ZM5.69791 12.7209L3.00061 13L3.27984 10.3027L5.69791 12.7209Z"
    />
  </Svg>
);
