import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinearStatusDoneIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    aria-label="Done"
    data-no-fill
  >
    <path
      fill="#5e6ad2"
      stroke="#5e6ad2"
      d="M9.54541 3.54541L9.89896 3.89896L9.54541 3.54541L5.5 7.59081L4.45459 6.54541C3.92739 6.0182 3.07261 6.0182 2.54541 6.54541C2.0182 7.07261 2.0182 7.92739 2.54541 8.45459L4.54541 10.4546C5.07261 10.9818 5.92739 10.9818 6.45459 10.4546L11.4546 5.45459C11.9818 4.92739 11.9818 4.07261 11.4546 3.54541L11.101 3.89896L11.4546 3.54541C10.9274 3.0182 10.0726 3.0182 9.54541 3.54541ZM0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
    />
  </Svg>
);
