// @todo get rid of this
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/static-property-placement */
import React from 'react';

const callbacks: VoidFunction[] = [];

declare global {
  interface Window { twttr: any }
}

function addScript(src: string, cb: () => any) {
  if (callbacks.length === 0) {
    callbacks.push(cb);
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.onload = () => callbacks.forEach((callback) => callback());
    document.body.appendChild(s);
  } else {
    callbacks.push(cb);
  }
}

interface ITweetEmbedProps {
  id: string;
  options?: any;
  placeholder?: string | React.ReactNode;
  protocol?: string;
  onTweetLoadSuccess?: (twitterWidgetElement: HTMLElement) => any;
  onTweetLoadError?: (err: Error) => any;
  className?: string;
}

interface ITweetEmbedState {
  isLoading: boolean;
}

export class TweetEmbed extends React.Component<ITweetEmbedProps> {
  _div?: HTMLDivElement;

  static defaultProps = {
    protocol: 'https:',
    options: {},
    className: null,
  };

  state: ITweetEmbedState = {
    isLoading: true,
  };

  componentDidMount() {
    this.loadTweetForProps(this.props);
  }

  shouldComponentUpdate(
    nextProps: ITweetEmbedProps,
  ) {
    return (
      this.props.id !== nextProps.id ||
      this.props.className !== nextProps.className
    );
  }

  UNSAFE_componentWillUpdate(nextProps: ITweetEmbedProps) {
    if (this.props.id !== nextProps.id) {
      this.loadTweetForProps(nextProps);
    }
  }

  loadTweetForProps(props: ITweetEmbedProps) {
    const renderTweet = () => {
      const { twttr } = window;
      twttr.ready().then(({ widgets }: any) => {
        // Clear previously rendered tweet before rendering the updated tweet id
        if (this._div) {
          this._div.innerHTML = '';
        }

        const {
          options, onTweetLoadSuccess, onTweetLoadError,
        } = props;
        widgets
          .createTweetEmbed(this.props.id, this._div, options)
          .then((twitterWidgetElement: any) => {
            this.setState({
              isLoading: false,
            });
            onTweetLoadSuccess && onTweetLoadSuccess(twitterWidgetElement);
          })
          .catch(onTweetLoadError);
      });
    };

    const { twttr } = window;
    if (!(twttr && twttr.ready)) {
      const isLocal = window.location.protocol.includes('file');
      const protocol = isLocal ? this.props.protocol : '';

      addScript(`${protocol}//platform.twitter.com/widgets.js`, renderTweet);
    } else {
      renderTweet();
    }
  }

  render() {
    const {
      props, state,
    } = this;

    return (
      <div
        className={props.className}
        ref={(c) => {
          this._div = c ?? undefined;
        }}
      >
        {state.isLoading && props.placeholder}
      </div>
    );
  }
}
