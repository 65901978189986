import {
  FILL_CURRENT_COLOR_CLASS_NAME,
  STROKE_CURRENT_COLOR_CLASS_NAME,
  Svg, SvgProps,
} from '../../components/Svg/Svg.styles';

export const RecordIcon = ({
  isBump, ...props
}: SvgProps & { isBump?: boolean }) => {
  return isBump ? (
    <Svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3420_17411)">
        <path
          d="M3.54353 13C2.2497 13 1.5 12.3013 1.5 11.0194V5.01094C1.5 3.73512 2.29202 3 3.54353 3H9.96433C11.2582 3 11.9595 3.73512 11.9595 5.01094V10.9891C11.9595 12.2649 11.2098 13 9.91596 13H3.54353ZM12.8422 9.93803V6.08627L14.922 4.28797C15.1699 4.06926 15.4299 3.9356 15.6778 3.9356C16.1675 3.9356 16.5 4.28797 16.5 4.81652V11.2078C16.5 11.7363 16.1675 12.0887 15.6778 12.0887C15.4299 12.0887 15.1699 11.955 14.922 11.7424L12.8422 9.93803Z"
          fill="url(#paint0_linear_3420_17411)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3420_17411"
          x="0.333333"
          y="0"
          width="17.3333"
          height="17.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3420_17411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3420_17411"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3420_17411"
          x1="9"
          y1="3"
          x2="9"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0.5"
          />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={STROKE_CURRENT_COLOR_CLASS_NAME}
        d="M10 1.66666C9.33696 1.66666 8.70107 1.93005 8.23223 2.39889C7.76339 2.86773 7.5 3.50362 7.5 4.16666V9.99999C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 9.99999V4.16666C12.5 3.50362 12.2366 2.86773 11.7678 2.39889C11.2989 1.93005 10.663 1.66666 10 1.66666Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={STROKE_CURRENT_COLOR_CLASS_NAME}
        d="M15.8332 8.33334V10C15.8332 11.5471 15.2186 13.0308 14.1246 14.1248C13.0307 15.2188 11.5469 15.8333 9.99984 15.8333C8.45274 15.8333 6.96901 15.2188 5.87505 14.1248C4.78109 13.0308 4.1665 11.5471 4.1665 10V8.33334"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        // Dirty solution but the only one working
        style={{ fill: 'transparent' }}
      />
      <path
        className={STROKE_CURRENT_COLOR_CLASS_NAME}
        d="M10 15.8333V18.3333"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="red"
      />
      <rect
        className={FILL_CURRENT_COLOR_CLASS_NAME}
        x="8.3335"
        y="2.5"
        width="3.33333"
        height="9.16667"
      />
    </Svg>
  );
};
