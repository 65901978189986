import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

type Props = SvgProps & {
  isBump?: boolean;
};

export const GridCircleIcon: FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3420_17415)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99998 2.00134C2.89633 2.00134 2.00165 2.89603 2.00165 3.99968V5.33301C2.00165 6.43666 2.89633 7.33138 3.99998 7.33138H5.33331C6.43697 7.33138 7.33165 6.43666 7.33165 5.33301V3.99968C7.33165 2.89603 6.43697 2.00134 5.33331 2.00134H3.99998ZM3.99998 8.66797C2.89633 8.66797 2.00165 9.56264 2.00165 10.6663V11.9996C2.00165 13.1032 2.89633 13.998 3.99998 13.998H5.33331C6.43697 13.998 7.33165 13.1032 7.33165 11.9996V10.6663C7.33165 9.56264 6.43697 8.66797 5.33331 8.66797H3.99998ZM8.66833 3.99968C8.66833 2.89603 9.563 2.00134 10.6667 2.00134H12C13.1037 2.00134 13.9983 2.89603 13.9983 3.99968V5.33301C13.9983 6.43666 13.1037 7.33138 12 7.33138H10.6667C9.563 7.33138 8.66833 6.43666 8.66833 5.33301V3.99968ZM8.66833 11.333C8.66833 9.8611 9.86147 8.66797 11.3333 8.66797C12.8052 8.66797 13.9983 9.8611 13.9983 11.333C13.9983 12.8048 12.8052 13.998 11.3333 13.998C9.86147 13.998 8.66833 12.8048 8.66833 11.333ZM11.3333 9.99797C10.5961 9.99797 9.99834 10.5956 9.99834 11.333C9.99834 12.0702 10.5961 12.668 11.3333 12.668C12.0707 12.668 12.6683 12.0702 12.6683 11.333C12.6683 10.5956 12.0707 9.99797 11.3333 9.99797Z"
          fill="url(#paint0_linear_3420_17415)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3420_17415"
          x="-0.666667"
          y="0"
          width="17.3333"
          height="17.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3420_17415"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3420_17415"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3420_17415"
          x1="7.99999"
          y1="2.00134"
          x2="7.99999"
          y2="13.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0.5"
          />
        </linearGradient>
      </defs>
    </Svg>
  );
};
