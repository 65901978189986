import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SummaryIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M2.66675 4V10C2.66675 10.7364 3.2637 11.3333 4.00008 11.3333H12.0001"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4.66675H11.3333"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8H8.66667"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.6667V10L13.6667 11.3333L12 12.6667Z"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
