import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GroupCanceledIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    data-icon-status
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.79001 3.79C3.89548 3.68466 4.03845 3.6255 4.18751 3.6255C4.33657 3.6255 4.47954 3.68466 4.58501 3.79L7.00001 6.205L9.41501 3.79C9.48376 3.71596 9.57121 3.66183 9.66815 3.63332C9.76509 3.60481 9.86792 3.60297 9.96581 3.628C10.0637 3.65303 10.153 3.704 10.2244 3.77554C10.2957 3.84708 10.3465 3.93654 10.3713 4.0345C10.3963 4.13228 10.3946 4.23501 10.3662 4.33188C10.3378 4.42876 10.2839 4.51619 10.21 4.585L7.79501 7L10.21 9.415C10.2841 9.48375 10.3382 9.57121 10.3667 9.66814C10.3952 9.76508 10.397 9.86791 10.372 9.9658C10.347 10.0637 10.296 10.153 10.2245 10.2244C10.1529 10.2957 10.0635 10.3465 9.96551 10.3713C9.86773 10.3963 9.765 10.3946 9.66813 10.3662C9.57125 10.3378 9.48382 10.2839 9.41501 10.21L7.00001 7.795L4.58501 10.21C4.47831 10.3093 4.33727 10.3634 4.19154 10.3609C4.0458 10.3583 3.90671 10.2994 3.80351 10.1965C3.70058 10.0933 3.64166 9.95421 3.63914 9.80847C3.63662 9.66274 3.6907 9.5217 3.79001 9.415L6.20501 7L3.79001 4.585C3.68467 4.47953 3.6255 4.33656 3.6255 4.1875C3.6255 4.03844 3.68467 3.89547 3.79001 3.79Z"
      fill="#770808"
    />
  </Svg>
);
