import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TableOfContentIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33301 4.00004C1.33301 3.63185 1.63148 3.33337 1.99967 3.33337H13.9997C14.3679 3.33337 14.6663 3.63185 14.6663 4.00004C14.6663 4.36823 14.3679 4.66671 13.9997 4.66671H1.99967C1.63148 4.66671 1.33301 4.36823 1.33301 4.00004ZM1.33301 8.00004C1.33301 7.63185 1.63148 7.33337 1.99967 7.33337H13.9997C14.3679 7.33337 14.6663 7.63185 14.6663 8.00004C14.6663 8.36823 14.3679 8.66671 13.9997 8.66671H1.99967C1.63148 8.66671 1.33301 8.36823 1.33301 8.00004ZM1.33301 12C1.33301 11.6319 1.63148 11.3334 1.99967 11.3334H7.33301C7.7012 11.3334 7.99967 11.6319 7.99967 12C7.99967 12.3682 7.7012 12.6667 7.33301 12.6667H1.99967C1.63148 12.6667 1.33301 12.3682 1.33301 12Z"
    />
  </Svg>
);
