import { FC } from 'react';

import freshDesk from './fresh-desk.png';

type Props = {
  className?: string;
  size?: number;
};

export const FreshDeskIcon: FC<React.PropsWithChildren<Props>> = ({
  className, size,
}) => (
  <img
    className={className}
    width={size || 16}
    src={freshDesk}
    alt="FreshDesk"
  />
);
