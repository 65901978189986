import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const GiftIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 18 19"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 9.73244V16C16 17.1046 15.1046 18 14 18H4C2.89543 18 2 17.1046 2 16V9.73244M16 9.73244V9.5M16 9.73244C16.5978 9.38663 17 8.74028 17 8C17 6.89543 16.1046 6 15 6H3C1.89543 6 1 6.89543 1 8C1 8.74028 1.4022 9.38663 2 9.73244M16 9.73244C15.7058 9.90261 15.3643 10 15 10H3C2.63571 10 2.29417 9.90261 2 9.73244M2 9.5V9.73244M9 6V4.33333M9 6H7.33333C5.49238 6 4 4.50762 4 2.66667C4 1.74619 4.74619 1 5.66667 1C7.50762 1 9 2.49238 9 4.33333M9 6H10.6667C12.5076 6 14 4.50762 14 2.66667C14 1.74619 13.2538 1 12.3333 1C10.4924 1 9 2.49238 9 4.33333M9 6V18"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </Svg>
);
