import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const BubbleIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 -1 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M3.50171 1H12.5017C13.7443 1 14.7517 2.00736 14.7517 3.25V9.25C14.7517 10.4926 13.7443 11.5 12.5017 11.5H8.00171L4.25171 13.75V11.5H3.50171C2.25907 11.5 1.25171 10.4926 1.25171 9.25V3.25C1.25171 2.00736 2.25907 1 3.50171 1Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </Svg>
);
