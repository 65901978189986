import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TypeformIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <g clipPath="url(#clip0_3374_3628)">
      <g clipPath="url(#clip1_3374_3628)">
        <path
          d="M7.57747 0.0295318C9.64118 -0.139717 11.1233 0.405883 12.7181 1.99352C14.4399 3.7076 15.5846 5.92889 15.8931 8.29505C16.2364 10.9264 15.7643 12.89 14.3102 14.3276C12.9198 15.7023 10.889 16.1557 8.1761 15.9298L8.06379 15.92C5.75363 15.7084 4.11964 14.9539 2.53644 13.3567C0.845345 11.6507 -0.0457731 9.71371 0.00181137 7.68065C0.0250855 6.68534 0.308131 5.83707 0.853997 5.00476C1.21186 4.45911 1.57529 4.03346 2.33551 3.25284L2.58922 2.99397L2.86889 2.7116C4.67056 0.907665 5.8567 0.170657 7.57747 0.0295318ZM12.5955 2.11575C11.0366 0.563875 9.60448 0.0366816 7.59171 0.201753C5.89544 0.340868 4.73053 1.07903 2.90743 2.91836L2.77554 3.05165C1.88729 3.95112 1.47103 4.41101 1.10435 4.94286L1.051 5.02127L0.99923 5.09927C0.470438 5.90554 0.197735 6.72283 0.175243 7.68467C0.128838 9.66731 0.99987 11.5607 2.65988 13.2353C4.23907 14.8285 5.86142 15.5637 8.19055 15.7576L8.30609 15.7668C10.9152 15.9667 12.8603 15.5176 14.188 14.205C15.5987 12.8103 16.058 10.8998 15.7211 8.31731C15.4177 5.99006 14.2909 3.80354 12.5955 2.11575ZM10.4365 5.81418V6.65833H8.63668V11.4965H7.74014V6.65833H5.9403V5.81418H10.4365Z"
          fill="currentcolor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3374_3628">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3374_3628">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
