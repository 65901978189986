import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

type Props = SvgProps & {
  isBump?: boolean;
};

export const GearIcon: FC<React.PropsWithChildren<Props>> = ({
  isBump, ...props
}) => {
  return isBump ? (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      data-no-fill
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3420_17327)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5.77991C2 5.05735 2.38973 4.39099 3.01949 4.03675L7.01947 1.78679C7.62833 1.44433 8.37167 1.44433 8.98053 1.78679L12.9805 4.03677C13.6103 4.391 14 5.05737 14 5.77993V10.2203C14 10.9429 13.6103 11.6093 12.9805 11.9635L8.98047 14.2135C8.37167 14.5559 7.62833 14.5559 7.01953 14.2135L3.01954 11.9637C2.38975 11.6095 2 10.9431 2 10.2205V5.77991ZM5.66669 8C5.66669 6.71133 6.71133 5.66667 8 5.66667C9.28867 5.66667 10.3333 6.71133 10.3333 8C10.3333 9.28867 9.28867 10.3333 8 10.3333C6.71133 10.3333 5.66669 9.28867 5.66669 8Z"
          fill="url(#paint0_linear_3420_17327)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3420_17327"
          x="-0.666667"
          y="0"
          width="17.3333"
          height="17.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3420_17327"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3420_17327"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3420_17327"
          x1="8"
          y1="1.52995"
          x2="8"
          y2="14.4703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0.5"
          />
        </linearGradient>
      </defs>
    </Svg>

  ) : (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 13 14"
      data-no-fill
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.45837 1.211C6.10524 0.847145 6.89508 0.847146 7.54195 1.21101L11.0836 3.20317C11.7528 3.57955 12.1668 4.28757 12.1668 5.05528V8.94465C12.1668 9.71237 11.7528 10.4204 11.0836 10.7968L7.54191 12.7889C6.89506 13.1528 6.10525 13.1528 5.45839 12.789L1.91676 10.797C1.24761 10.4206 0.833496 9.71256 0.833496 8.94482L0.833496 5.05526C0.833496 4.28755 1.24758 3.57953 1.9167 3.20316L5.45837 1.211Z"
        stroke="currentColor"
        strokeWidth="1.41667"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.62518 7.00001C8.62518 8.17361 7.67379 9.12501 6.50018 9.12501C5.32658 9.12501 4.37518 8.17361 4.37518 7.00001C4.37518 5.8264 5.32658 4.87501 6.50018 4.87501C7.67379 4.87501 8.62518 5.8264 8.62518 7.00001Z"
        stroke="currentColor"
        strokeWidth="1.41667"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
