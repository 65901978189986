import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiRephraseIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="rotate clockwise, rotate">
      <path
        id="Icon"
        d="M2.9008 7C2.9008 4.74484 4.72897 2.91667 6.98413 2.91667C8.13563 2.91667 8.96903 3.31303 9.7762 4.08333H8.75008C8.42792 4.08333 8.16675 4.3445 8.16675 4.66667C8.16675 4.98883 8.42792 5.25 8.75008 5.25H10.6459C11.2097 5.25 11.6667 4.79296 11.6667 4.22917V2.33333C11.6667 2.01117 11.4056 1.75 11.0834 1.75C10.7612 1.75 10.5001 2.01117 10.5001 2.33333V3.16342C9.55382 2.28777 8.47248 1.75 6.98413 1.75C4.08464 1.75 1.73413 4.10051 1.73413 7C1.73413 9.8995 4.08464 12.25 6.98413 12.25C9.27093 12.25 11.2148 10.7882 11.9352 8.74995C12.0426 8.4462 11.8834 8.11293 11.5797 8.00557C11.2759 7.89821 10.9426 8.05741 10.8353 8.36116C10.2744 9.94791 8.76113 11.0833 6.98413 11.0833C4.72897 11.0833 2.9008 9.25516 2.9008 7Z"
      />
    </g>
  </Svg>
);
