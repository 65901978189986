import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FileDownloadIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H8.17157C8.70201 1 9.21071 1.21071 9.58579 1.58579L14.4142 6.41421C14.7893 6.78929 15 7.29799 15 7.82843V17C15 18.1046 14.1046 19 13 19H12.5M9 1.5V5C9 6.10457 9.89543 7 11 7H14.5M8 12V18M8 18L10.5 15.5M8 18L5.5 15.5"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
