import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const OneDriveIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <g clipPath="url(#clip0_3264_74145)">
      <path
        d="M6.10107 5.77207L6.10122 5.77152L9.46005 7.78467L11.4615 6.94189C11.8682 6.76595 12.3068 6.67574 12.7498 6.67617C12.8237 6.67617 12.8966 6.67952 12.9692 6.68437C12.7286 5.74549 12.2206 4.89676 11.507 4.24131C10.7934 3.58587 9.90493 3.15192 8.94952 2.99222C7.9941 2.83251 7.01289 2.95392 6.12513 3.34169C5.23737 3.72946 4.48128 4.36689 3.94873 5.17652C3.96584 5.17631 3.98269 5.17523 3.99984 5.17523C4.74216 5.17423 5.46998 5.38096 6.10107 5.77207Z"
        fill="#0364B8"
      />
      <path
        d="M6.10162 5.77109L6.10147 5.77164C5.47038 5.38053 4.74257 5.1738 4.00024 5.17481C3.98309 5.17481 3.96622 5.17588 3.94913 5.1761C3.22258 5.1851 2.51222 5.39204 1.89437 5.77467C1.27653 6.1573 0.774569 6.70117 0.44243 7.34782C0.11029 7.99448 -0.039471 8.71947 0.00924069 9.44489C0.0579524 10.1703 0.303295 10.8687 0.718904 11.4651L3.6809 10.2179L4.99761 9.66344L7.92938 8.42894L9.46044 7.78424L6.10162 5.77109Z"
        fill="#0078D4"
      />
      <path
        d="M12.9698 6.68496C12.8972 6.68011 12.8242 6.67676 12.7504 6.67676C12.3073 6.67633 11.8688 6.76688 11.4621 6.94282L9.46057 7.78526L10.041 8.13311L11.9434 9.27338L12.7735 9.77088L15.6116 11.472C15.8695 10.9929 16.0032 10.4568 16.0003 9.91269C15.9975 9.36857 15.8582 8.83386 15.5954 8.35754C15.3325 7.88122 14.9544 7.47851 14.4957 7.1863C14.037 6.89408 13.5123 6.72171 12.9698 6.68496Z"
        fill="#1490DF"
      />
      <path
        d="M12.7736 9.7698L11.9435 9.2723L10.041 8.13204L9.46066 7.78418L7.92959 8.42887L4.99783 9.66337L3.68112 10.2178L0.719116 11.4651C1.0872 11.9946 1.57789 12.4271 2.14929 12.7257C2.72069 13.0243 3.35582 13.1801 4.00045 13.1797H12.7505C13.3371 13.1799 13.9129 13.0211 14.4167 12.7203C14.9204 12.4194 15.3334 11.9877 15.6117 11.4709L12.7736 9.7698Z"
        fill="#28A8EA"
      />
    </g>
    <defs>
      <clipPath id="clip0_3264_74145">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
