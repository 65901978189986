import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const JiraIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M14.8487 14.1662L8.44128 1.32808C8.31537 1.05033 8.18886 1 8.03753 1C7.91162 1 7.76029 1.05033 7.60896 1.30321C6.70097 2.74348 6.29722 4.41116 6.29722 6.15464C6.29722 8.58096 7.53329 10.8557 9.34927 14.5203C9.55145 14.9242 9.70279 15 10.0557 15H14.4958C14.8232 15 15 14.8739 15 14.621C15 14.4948 14.9746 14.419 14.8487 14.1662ZM5.54056 8.10127C5.03571 7.34324 4.88438 7.2923 4.75848 7.2923C4.63257 7.2923 4.5569 7.34263 4.3299 7.79806L1.12591 14.2159C1.02482 14.4184 1 14.4942 1 14.5949C1 14.7975 1.17615 14.9994 1.55448 14.9994H6.07022C6.37288 14.9994 6.59988 14.7471 6.72579 14.2668C6.87712 13.6604 6.92797 13.1292 6.92797 12.4979C6.92797 10.7289 6.14588 9.01092 5.54056 8.10127Z"
      fill="#1868DB"
    />
  </Svg>
);
