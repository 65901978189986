import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TxtIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      // eslint-disable-next-line max-len
      d="M6.75 5.25H11.25M6.75 8.25H11.25M6.75 11.25H8.25M5.25 15.75H12.75C13.5784 15.75 14.25 15.0784 14.25 14.25V3.75C14.25 2.92157 13.5784 2.25 12.75 2.25H5.25C4.42157 2.25 3.75 2.92157 3.75 3.75V14.25C3.75 15.0784 4.42157 15.75 5.25 15.75Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
