import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiSummarizeIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="filter 2, sort">
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 2.69714C0.875 2.36978 1.14038 2.1044 1.46774 2.1044H12.5323C12.8596 2.1044 13.125 2.36978 13.125 2.69714C13.125 3.0245 12.8596 3.28988 12.5323 3.28988H1.46774C1.14038 3.28988 0.875 3.0245 0.875 2.69714ZM2.71909 7.00001C2.71909 6.67265 2.98447 6.40727 3.31183 6.40727H10.6882C11.0155 6.40727 11.2809 6.67265 11.2809 7.00001C11.2809 7.32737 11.0155 7.59275 10.6882 7.59275H3.31183C2.98447 7.59275 2.71909 7.32737 2.71909 7.00001ZM4.56317 11.3029C4.56317 10.9755 4.82855 10.7101 5.15591 10.7101H8.84409C9.17145 10.7101 9.43683 10.9755 9.43683 11.3029C9.43683 11.6302 9.17145 11.8956 8.84409 11.8956H5.15591C4.82855 11.8956 4.56317 11.6302 4.56317 11.3029Z"
      />
    </g>
  </Svg>
);
