import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SortHorizontalIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.49997 19L2.20708 15.7071C1.81655 15.3166 1.81655 14.6834 2.20708 14.2929L5.49997 11M14.5 9L17.7929 5.70711C18.1834 5.31658 18.1834 4.68342 17.7929 4.29289L14.5 1M2.99997 15H18M1.99997 5H17"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
