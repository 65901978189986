import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ModjoIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M7.99729 0C3.58072 0 0 3.58072 0 8.00268C0 12.4247 3.58072 16 7.99729 16C12.4139 16 16 12.4192 16 8.00268C16 3.58611 12.4139 0 7.99729 0ZM4.64846 9.48027C4.64846 9.85236 4.34647 10.149 3.97438 10.149C3.60228 10.149 3.30029 9.85236 3.30029 9.48027V7.06436C3.30029 6.69227 3.60228 6.39028 3.97438 6.39028C4.34647 6.39028 4.64846 6.69227 4.64846 7.06436V9.48027ZM6.65992 12.43C6.65992 12.8021 6.35793 13.0988 5.98585 13.0988C5.61375 13.0988 5.31176 12.8021 5.31176 12.43V3.57532C5.31176 3.20323 5.61375 2.90124 5.98585 2.90124C6.35793 2.90124 6.65992 3.20323 6.65992 3.57532V12.43ZM8.67137 11.3569C8.67137 11.729 8.36939 12.0256 7.99729 12.0256C7.6252 12.0256 7.32321 11.729 7.32321 11.3569V5.45196C7.32321 5.07986 7.6252 4.77787 7.99729 4.77787C8.36939 4.77787 8.67137 5.07986 8.67137 5.45196V11.3569ZM10.8176 12.43C10.8176 12.8021 10.5157 13.0988 10.1436 13.0988C9.77147 13.0988 9.46948 12.8021 9.46948 12.43V3.57532C9.46948 3.20323 9.77147 2.90124 10.1436 2.90124C10.5157 2.90124 10.8176 3.20323 10.8176 3.57532V12.43ZM12.6943 9.48027C12.6943 9.85236 12.3976 10.149 12.0256 10.149C11.6535 10.149 11.3515 9.85236 11.3515 9.48027V7.06436C11.3515 6.69227 11.6535 6.39028 12.0256 6.39028C12.3976 6.39028 12.6943 6.69227 12.6943 7.06436V9.48027Z"
      fill="#4DD599"
    />
  </Svg>
);
