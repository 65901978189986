import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MagicBox: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 32 32"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4485 4.41622L27.584 5.11502L22.5265 13.3334H26.6668V29.3334H5.3335V13.3334H20.961L26.4485 4.41622ZM12.0002 18.6667V20H20.0002V18.6667H12.0002Z"
      fill="url(#paint0_linear_2190_32960)"
    />
    <path
      d="M17.3346 0.842651L18.8315 3.83646L21.8253 5.33336L18.8315 6.83027L17.3346 9.82408L15.8377 6.83027L12.8439 5.33336L15.8377 3.83646L17.3346 0.842651Z"
      fill="url(#paint1_linear_2190_32960)"
    />
    <path
      d="M10.0013 4.84265L11.276 7.39202L13.8253 8.6667L11.276 9.94138L10.0013 12.4907L8.72661 9.94138L6.17725 8.6667L8.72661 7.39202L10.0013 4.84265Z"
      fill="url(#paint2_linear_2190_32960)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2190_32960"
        x1="-1.40017"
        y1="19.045"
        x2="30.4253"
        y2="9.31812"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.141154"
          stopColor="#A990FF"
        />
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        />
        <stop
          offset="1"
          stopColor="#7FEA96"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2190_32960"
        x1="-1.40017"
        y1="19.045"
        x2="30.4253"
        y2="9.31812"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.141154"
          stopColor="#A990FF"
        />
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        />
        <stop
          offset="1"
          stopColor="#7FEA96"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2190_32960"
        x1="-1.40017"
        y1="19.045"
        x2="30.4253"
        y2="9.31812"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.141154"
          stopColor="#A990FF"
        />
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        />
        <stop
          offset="1"
          stopColor="#7FEA96"
        />
      </linearGradient>
    </defs>
  </Svg>
);
