import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PinterestIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path d="M8 1C4.15 1 1 4.15 1 8C1 10.975 2.8375 13.5125 5.4625 14.475C5.375 13.95 5.375 13.075 5.4625 12.4625C5.55 11.9375 6.25 8.9625 6.25 8.9625C6.25 8.9625 6.075 8.6125 6.075 8C6.075 7.0375 6.6875 6.25 7.3875 6.25C8 6.25 8.2625 6.6875 8.2625 7.2125C8.2625 7.825 7.9125 8.7 7.65 9.575C7.475 10.275 8 10.8 8.7 10.8C9.925 10.8 10.8875 9.4875 10.8875 7.5625C10.8875 5.9 9.6625 4.675 8 4.675C5.9875 4.675 4.85 6.1625 4.85 7.7375C4.85 8.35 5.1125 8.9625 5.375 9.3125C5.375 9.4875 5.375 9.575 5.375 9.6625C5.2875 9.925 5.2 10.3625 5.2 10.45C5.2 10.5375 5.1125 10.625 4.9375 10.5375C4.0625 10.1 3.5375 8.875 3.5375 7.825C3.5375 5.6375 5.1125 3.625 8.175 3.625C10.625 3.625 12.4625 5.375 12.4625 7.65C12.4625 10.1 10.975 12.025 8.7875 12.025C8.0875 12.025 7.3875 11.675 7.2125 11.2375C7.2125 11.2375 6.8625 12.55 6.775 12.9C6.6 13.5125 6.1625 14.3 5.9 14.7375C6.6 14.9125 7.3 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1Z" />
  </Svg>
);
