import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const LayerIcon: FC<SvgProps> = (props) => (
  <Svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.89998 9.00001L1.59246 11.1012C0.843692 11.4665 0.843692 12.5335 1.59246 12.8988L9.56156 16.7861C9.83826 16.9211 10.1617 16.9211 10.4384 16.7861L18.4075 12.8988C19.1563 12.5335 19.1563 11.4665 18.4075 11.1012L14.1 9.00001M5.89998 9.00001L1.59246 6.89878C0.843692 6.53352 0.843695 5.46649 1.59246 5.10124L9.56156 1.21387C9.83826 1.0789 10.1617 1.0789 10.4384 1.21387L18.4075 5.10124C19.1563 5.46649 19.1563 6.53352 18.4075 6.89878L14.1 9.00001M5.89998 9.00001L9.56156 10.7861C9.83826 10.9211 10.1617 10.9211 10.4384 10.7861L14.1 9.00001"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </Svg>
);
