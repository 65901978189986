import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DetailsIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.67942 0.993605C2.98473 0.559499 2.08337 1.05855 2.08337 1.87729V8.12279C2.08337 8.94153 2.98473 9.44058 3.67942 9.00648L8.67671 5.88373C9.33004 5.47547 9.33004 4.52461 8.67671 4.11636L3.67942 0.993605Z" />
  </Svg>
);
