import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const LoopLeftIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33341 11H4.66675C3.56218 11 2.66675 10.1046 2.66675 9V5C2.66675 3.89543 3.56218 3 4.66675 3H11.3334C12.438 3 13.3334 3.89543 13.3334 5V9C13.3334 10.1046 12.438 11 11.3334 11H8.00008M8.00008 11L10.0001 9M8.00008 11L10.0001 13"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

