import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AddMemberIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 5.99994C7.65685 5.99994 9 4.65679 9 2.99994C9 1.34308 7.65685 -6.10352e-05 6 -6.10352e-05C4.34315 -6.10352e-05 3 1.34308 3 2.99994C3 4.65679 4.34315 5.99994 6 5.99994Z" />
    <path d="M12 8.66783H10.3333V7.00116H8.66667V8.66783H7V10.3345H8.66667V12.0012H10.3333V10.3345H12V8.66783Z" />
    <path d="M5.31974 9.50116C5.31974 8.57033 5.57176 7.72033 5.98338 7.02616C5.76726 7.01199 5.54849 7.00116 5.31974 7.00116C3.19716 7.00116 1.63648 7.66366 0.773357 8.15283C0.299235 8.42199 0 9.01533 0 9.66783V12.0012H6C5.57908 11.3028 5.31974 10.4437 5.31974 9.50116Z" />
  </Svg>
);
