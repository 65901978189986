import { FC } from 'react';

import { ArrowRightUpIcon } from './ArrowRightUpIcon';
import { SvgProps } from '../../components/Svg/Svg.styles';

export const ArrowRightDownIcon: FC<SvgProps> = (props) => (
  <ArrowRightUpIcon
    style={{ transform: 'rotate(90deg)' }}
    {...props}
  />
);
