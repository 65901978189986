import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ArrowsAllSidesIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 48 48"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 8H14C10.6863 8 8 10.6863 8 14V16M32 8H34C37.3138 8 40 10.6863 40 14V16M40 32V34C40 37.3138 37.3138 40 34 40H32M16 40H14C10.6863 40 8 37.3138 8 34V32"
      stroke="currentcolor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
