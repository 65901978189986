import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const UpIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21995 10.78C3.0795 10.6394 3.00061 10.4488 3.00061 10.25C3.00061 10.0513 3.0795 9.86064 3.21995 9.72001L7.46995 5.47001C7.61058 5.32956 7.8012 5.25067 7.99995 5.25067C8.1987 5.25067 8.38932 5.32956 8.52995 5.47001L12.78 9.72001C12.9124 9.86219 12.9846 10.0502 12.9811 10.2445C12.9777 10.4388 12.899 10.6242 12.7616 10.7616C12.6242 10.899 12.4388 10.9778 12.2445 10.9812C12.0502 10.9846 11.8621 10.9125 11.72 10.78L7.99995 7.06001L4.27995 10.78C4.13933 10.9205 3.9487 10.9994 3.74995 10.9994C3.5512 10.9994 3.36058 10.9205 3.21995 10.78Z"
      fill="#171717"
    />
  </Svg>
);
