import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const H2Icon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.7731V3.35568H1.70599V7.34246H6.06984V3.35568H7.78043V12.7731H6.06984V8.77255H1.70599V12.7731H0Z"
      fill="#171717"
    />
    <path
      d="M9.5439 12.7731V11.5408L12.8133 8.33571C13.126 8.01995 13.3866 7.73945 13.5951 7.49421C13.8035 7.24896 13.9599 7.01138 14.0641 6.78146C14.1683 6.55154 14.2204 6.3063 14.2204 6.04572C14.2204 5.74836 14.153 5.49392 14.0181 5.2824C13.8832 5.06781 13.6978 4.90227 13.4617 4.78577C13.2257 4.66928 12.9574 4.61104 12.657 4.61104C12.3474 4.61104 12.0761 4.67541 11.8431 4.80417C11.6101 4.92986 11.4292 5.10919 11.3005 5.34218C11.1748 5.57516 11.1119 5.85259 11.1119 6.17448H9.48872C9.48872 5.57669 9.62514 5.05708 9.89797 4.61563C10.1708 4.17419 10.5463 3.83238 11.0246 3.5902C11.5059 3.34802 12.0577 3.22693 12.68 3.22693C13.3115 3.22693 13.8664 3.34495 14.3446 3.581C14.8228 3.81705 15.1938 4.14047 15.4574 4.55126C15.7241 4.96204 15.8575 5.43108 15.8575 5.95836C15.8575 6.3109 15.79 6.65731 15.6551 6.99759C15.5202 7.33786 15.2827 7.71493 14.9424 8.12878C14.6052 8.54264 14.1315 9.04386 13.5215 9.63245L11.8983 11.2833V11.3476H16V12.7731H9.5439Z"
      fill="#171717"
    />
  </Svg>
);
