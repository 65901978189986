export const isSafari = navigator.userAgent.includes('Safari') && navigator.vendor.includes('Apple Computer');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

export const getBrowser = () => {
  const browserInfo = navigator.userAgent;
  if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) return 'opera';
  if (browserInfo.includes('Edg')) return 'edge';
  if (browserInfo.includes('Chrome')) return 'chrome';
  if (browserInfo.includes('Safari')) return 'safari';
  if (browserInfo.includes('Firefox')) return 'firefox';
  return null;
};

export const isFileSystemAccessAPIEnabled = 'showOpenFilePicker' in window;
