import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LogoutIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.08244 2.35484C2.88947 2.35484 2.7044 2.4315 2.56795 2.56795C2.4315 2.7044 2.35484 2.88947 2.35484 3.08244V12.9176C2.35484 13.1105 2.4315 13.2956 2.56795 13.4321C2.7044 13.5685 2.88947 13.6452 3.08244 13.6452H5.89247C6.2666 13.6452 6.56989 13.9485 6.56989 14.3226C6.56989 14.6967 6.2666 15 5.89247 15H3.08244C2.53014 15 2.00046 14.7806 1.60993 14.3901C1.2194 13.9995 1 13.4699 1 12.9176V3.08244C1 2.53014 1.2194 2.00046 1.60993 1.60993C2.00046 1.2194 2.53014 1 3.08244 1H5.89247C6.2666 1 6.56989 1.30329 6.56989 1.67742C6.56989 2.05155 6.2666 2.35484 5.89247 2.35484H3.08244ZM10.331 4.00841C10.5955 3.74386 11.0244 3.74386 11.289 4.00841L14.7965 7.51594C14.8188 7.53776 14.8396 7.56111 14.8587 7.58581C14.8803 7.61368 14.8994 7.64284 14.916 7.673C14.9696 7.76995 15 7.88141 15 7.99999C15 8.19076 14.9212 8.36311 14.7943 8.48622L11.289 11.9915C11.0244 12.2561 10.5955 12.2561 10.331 11.9915C10.0664 11.727 10.0664 11.298 10.331 11.0335L12.6871 8.67741H5.89251C5.51838 8.67741 5.21509 8.37412 5.21509 7.99999C5.21509 7.62586 5.51838 7.32257 5.89251 7.32257H12.6871L10.331 4.96642C10.0664 4.70188 10.0664 4.27296 10.331 4.00841Z"
      fill="#171717"
    />
  </Svg>
);
