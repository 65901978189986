import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MicrosoftTeamsIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <g clipPath="url(#clip0_3264_73934)">
      <g clipPath="url(#clip1_3264_73934)">
        <path
          d="M11.1601 6.14062H15.293C15.6834 6.14062 16 6.45715 16 6.8476V10.6121C16 12.0471 14.8366 13.2104 13.4016 13.2104H13.3893C11.9543 13.2106 10.7909 12.0475 10.7906 10.6124C10.7906 10.6123 10.7906 10.6122 10.7906 10.6121V6.51012C10.7907 6.30605 10.9561 6.14062 11.1601 6.14062Z"
          fill="#5059C9"
        />
        <path
          d="M13.9533 5.39571C14.8781 5.39571 15.6278 4.64605 15.6278 3.72129C15.6278 2.79654 14.8781 2.04688 13.9533 2.04688C13.0286 2.04688 12.2789 2.79654 12.2789 3.72129C12.2789 4.64605 13.0286 5.39571 13.9533 5.39571Z"
          fill="#5059C9"
        />
        <path
          d="M8.74417 5.39581C10.0799 5.39581 11.1628 4.31296 11.1628 2.9772C11.1628 1.64144 10.0799 0.558594 8.74417 0.558594C7.40841 0.558594 6.32556 1.64144 6.32556 2.9772C6.32556 4.31296 7.40841 5.39581 8.74417 5.39581Z"
          fill="#7B83EB"
        />
        <path
          d="M11.969 6.14062H5.14707C4.76127 6.15017 4.45607 6.47035 4.46503 6.85616V11.1497C4.41115 13.465 6.24287 15.3863 8.55805 15.443C10.8732 15.3863 12.7049 13.465 12.6511 11.1497V6.85616C12.66 6.47035 12.3548 6.15017 11.969 6.14062Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.1"
          d="M8.93016 6.14062V12.1574C8.92831 12.4333 8.76111 12.6812 8.50597 12.7862C8.42474 12.8206 8.33743 12.8383 8.24923 12.8383H4.79248C4.74411 12.7155 4.69946 12.5927 4.66225 12.4662C4.532 12.0392 4.46554 11.5954 4.46504 11.149V6.85504C4.45609 6.46984 4.76078 6.15017 5.14597 6.14062H8.93016Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.55807 6.14062V12.5295C8.55805 12.6177 8.54034 12.705 8.50597 12.7862C8.40092 13.0413 8.15305 13.2085 7.87714 13.2104H4.96737C4.90411 13.0876 4.84458 12.9648 4.79248 12.8383C4.74039 12.7118 4.69946 12.5927 4.66225 12.4662C4.532 12.0393 4.46554 11.5954 4.46504 11.149V6.85504C4.45609 6.46984 4.76078 6.15017 5.14597 6.14062H8.55807Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.55806 6.14062V11.7853C8.55523 12.1602 8.25202 12.4634 7.87713 12.4662H4.66225C4.532 12.0393 4.46554 11.5954 4.46504 11.149V6.85504C4.45609 6.46984 4.76078 6.15017 5.14597 6.14062H8.55806Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.18596 6.14062V11.7853C8.18313 12.1602 7.87992 12.4634 7.50503 12.4662H4.66225C4.532 12.0393 4.46554 11.5954 4.46504 11.149V6.85504C4.45609 6.46984 4.76078 6.15017 5.14597 6.14062H8.18596Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M8.93026 4.21608V5.38817C8.867 5.39189 8.80747 5.39562 8.74421 5.39562C8.68095 5.39562 8.62142 5.3919 8.55816 5.38817C8.43256 5.37984 8.308 5.35991 8.18607 5.32864C7.43258 5.1502 6.81007 4.62177 6.51165 3.90725C6.4603 3.78726 6.42044 3.66266 6.39258 3.53516H8.24932C8.6248 3.53658 8.92883 3.8406 8.93026 4.21608Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.55823 4.58913V5.38913C8.43263 5.38079 8.30807 5.36087 8.18614 5.3296C7.43265 5.15116 6.81014 4.62272 6.51172 3.9082H7.8773C8.25277 3.90962 8.5568 4.21365 8.55823 4.58913Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.55823 4.58913V5.38913C8.43263 5.38079 8.30807 5.36087 8.18614 5.3296C7.43265 5.15116 6.81014 4.62272 6.51172 3.9082H7.8773C8.25277 3.90962 8.5568 4.21365 8.55823 4.58913Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.18614 4.58913V5.3296C7.43265 5.15116 6.81014 4.62272 6.51172 3.9082H7.50521C7.88069 3.90963 8.18471 4.21366 8.18614 4.58913Z"
          fill="black"
        />
        <path
          d="M0.682043 3.9082H7.504C7.88068 3.9082 8.18604 4.21357 8.18604 4.59025V11.4122C8.18604 11.7889 7.88067 12.0942 7.504 12.0942H0.682043C0.305358 12.0942 0 11.7889 0 11.4122V4.59025C0 4.21357 0.305365 3.9082 0.682043 3.9082Z"
          fill="url(#paint0_linear_3264_73934)"
        />
        <path
          d="M5.88817 6.50455H4.52445V10.218H3.65561V6.50455H2.29822V5.78418H5.88817V6.50455Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3264_73934"
        x1="1.42208"
        y1="3.37527"
        x2="6.76397"
        y2="12.6272"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop
          offset="0.5"
          stopColor="#4D55BD"
        />
        <stop
          offset="1"
          stopColor="#3940AB"
        />
      </linearGradient>
      <clipPath id="clip0_3264_73934">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3264_73934">
        <rect
          width="16"
          height="14.8837"
          fill="white"
          transform="translate(0 0.558594)"
        />
      </clipPath>
    </defs>
  </Svg>
);
