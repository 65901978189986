import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const BulbLight: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    {...props}
    viewBox="0 0 12 17"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 12.5V11.7188C8.25 11.4293 8.41917 11.1696 8.66839 11.0223C8.68086 11.0149 8.69329 11.0075 8.70569 11C10.2303 10.0813 11.25 8.40974 11.25 6.5C11.25 3.60051 8.8995 1.25 6 1.25C3.10051 1.25 0.75 3.60051 0.75 6.5C0.75 8.40974 1.76969 10.0813 3.29431 11C3.30671 11.0075 3.31914 11.0149 3.33161 11.0223C3.58083 11.1696 3.75 11.4293 3.75 11.7188V12.5M8.25 12.5V13.25C8.25 14.4926 7.24264 15.5 6 15.5C4.75736 15.5 3.75 14.4926 3.75 13.25V12.5M8.25 12.5H3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </Svg>
);
