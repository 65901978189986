/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

const STAR_ONE_DRAW_1 = 'M10.6667 5.33335C10.6667 4.96516 10.3682 4.66669 10 4.66669C9.63183 4.66669 9.33335 4.96516 9.33335 5.33335C9.33335 6.87234 8.99281 7.82831 8.41056 8.41056C7.82831 8.99281 6.87234 9.33335 5.33335 9.33335C4.96516 9.33335 4.66669 9.63183 4.66669 10C4.66669 10.3682 4.96516 10.6667 5.33335 10.6667C6.87234 10.6667 7.82831 11.0072 8.41056 11.5895C8.99281 12.1717 9.33335 13.1277 9.33335 14.6667C9.33335 15.0349 9.63183 15.3334 10 15.3334C10.3682 15.3334 10.6667 15.0349 10.6667 14.6667C10.6667 13.1277 11.0072 12.1717 11.5895 11.5895C12.1717 11.0072 13.1277 10.6667 14.6667 10.6667C15.0349 10.6667 15.3334 10.3682 15.3334 10C15.3334 9.63183 15.0349 9.33335 14.6667 9.33335C13.1277 9.33335 12.1717 8.99281 11.5895 8.41056C11.0072 7.82831 10.6667 6.87234 10.6667 5.33335Z';
const STAR_TWO_DRAW_1 = 'M5.00002 1.33335C5.00002 0.965164 4.70154 0.666687 4.33335 0.666687C3.96516 0.666687 3.66669 0.965164 3.66669 1.33335C3.66669 2.29364 3.45346 2.83294 3.1432 3.1432C2.83294 3.45346 2.29364 3.66669 1.33335 3.66669C0.965164 3.66669 0.666687 3.96516 0.666687 4.33335C0.666687 4.70154 0.965164 5.00002 1.33335 5.00002C2.29364 5.00002 2.83294 5.21325 3.1432 5.52351C3.45346 5.83377 3.66669 6.37307 3.66669 7.33335C3.66669 7.70154 3.96516 8.00002 4.33335 8.00002C4.70154 8.00002 5.00002 7.70154 5.00002 7.33335C5.00002 6.37307 5.21325 5.83377 5.52351 5.52351C5.83377 5.21325 6.37307 5.00002 7.33335 5.00002C7.70154 5.00002 8.00002 4.70154 8.00002 4.33335C8.00002 3.96516 7.70154 3.66669 7.33335 3.66669C6.37307 3.66669 5.83377 3.45346 5.52351 3.1432C5.21325 2.83294 5.00002 2.29364 5.00002 1.33335Z';

const svgProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: 'none',
};

type AiIconProps = SvgProps & {
  isAnimated?: boolean;
  noFill?: boolean;
};

export const AiIcon = forwardRef<SVGSVGElement, AiIconProps>(({
  isAnimated, noFill, ...props
}, ref) => {
  if (noFill) {
    return (
      <Svg
        ref={ref}
        width="16"
        height="16"
        viewBox="0 0 24 24"
        data-no-fill
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 9C6.78472 9 9 6.78472 9 1.75C9 6.78472 11.2153 9 16.25 9C11.2153 9 9 11.2153 9 16.25C9 11.2153 6.78472 9 1.75 9Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 17.5C16.0486 17.5 17.5 16.0486 17.5 12.75C17.5 16.0486 18.9514 17.5 22.25 17.5C18.9514 17.5 17.5 18.9514 17.5 22.25C17.5 18.9514 16.0486 17.5 12.75 17.5Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
  if (isAnimated) {
    return (
      <Svg
        ref={ref}
        {...props}
        {...svgProps}
      >
        <g clipPath="url(#gradient)">
          <motion.g
            animate={{
              opacity: [0, 1, 0],
              scale: [0.2, 1, 0.2],
            }}
            transition={{
              duration: 1.4,
              repeat: Infinity,
              delay: 0.7,
            }}
          >
            <path d={STAR_ONE_DRAW_1} />
          </motion.g>
          <motion.g
            animate={{
              opacity: [0, 1, 0],
              scale: [0.2, 1, 0.2],
            }}
            transition={{
              duration: 1.4,
              repeat: Infinity,
            }}
          >
            <path d={STAR_TWO_DRAW_1} />
          </motion.g>
        </g>
      </Svg>
    );
  }

  return (
    <Svg
      ref={ref}
      {...props}
      {...svgProps}
    >
      <g clipPath="url(#gradient)">
        <path d={STAR_ONE_DRAW_1} />
        <path d={STAR_TWO_DRAW_1} />
      </g>
    </Svg>
  );
});
