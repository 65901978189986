import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiToneIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="microphone, mic, sound, podcast">
      <path
        id="vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94863 3.92652C3.94863 2.24121 5.31484 0.875 7.00015 0.875C8.68546 0.875 10.0517 2.24121 10.0517 3.92652V6.3853C10.0517 8.07061 8.68546 9.43683 7.00015 9.43683C5.31484 9.43683 3.94863 8.07061 3.94863 6.3853V3.92652ZM7.00015 2.06048C5.96957 2.06048 5.13411 2.89594 5.13411 3.92652V6.3853C5.13411 7.41589 5.96957 8.25134 7.00015 8.25134C8.03074 8.25134 8.86619 7.41589 8.86619 6.3853V3.92652C8.86619 2.89594 8.03074 2.06048 7.00015 2.06048ZM2.38342 8.34687C2.65803 8.16866 3.02511 8.24681 3.20331 8.52142C3.8444 9.5093 5.02256 10.7101 7.00015 10.7101C8.97774 10.7101 10.1559 9.5093 10.797 8.52142C10.9752 8.24681 11.3423 8.16866 11.6169 8.34687C11.8915 8.52507 11.9696 8.89215 11.7914 9.16675C11.1006 10.2314 9.77992 11.646 7.59289 11.8662V12.5323C7.59289 12.8596 7.32751 13.125 7.00015 13.125C6.67279 13.125 6.40741 12.8596 6.40741 12.5323V11.8662C4.22038 11.646 2.89974 10.2314 2.20887 9.16675C2.03067 8.89215 2.10882 8.52507 2.38342 8.34687Z"
      />
    </g>
  </Svg>
);
