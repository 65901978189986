import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DownIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7802 5.22001C12.921 5.36064 13 5.55126 13 5.75001C13 5.94876 12.921 6.13939 12.7802 6.28001L8.52199 10.53C8.38109 10.6705 8.19009 10.7494 7.99096 10.7494C7.79182 10.7494 7.60083 10.6705 7.45993 10.53L3.20168 6.28001C3.06895 6.13784 2.99668 5.94979 3.00012 5.75549C3.00355 5.56119 3.08242 5.3758 3.2201 5.23839C3.35778 5.10098 3.54352 5.02226 3.7382 5.01884C3.93288 5.01541 4.12129 5.08753 4.26374 5.22001L7.99096 8.94001L11.7182 5.22001C11.8591 5.07956 12.0501 5.00067 12.2492 5.00067C12.4483 5.00067 12.6393 5.07956 12.7802 5.22001Z"
      fill="#707070"
    />
  </Svg>

);
