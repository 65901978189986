import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CheckAltIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.78033 0.21967C8.07322 0.512563 8.07322 0.987437 7.78033 1.28033L3.28033 5.78033C3.13968 5.92098 2.94891 6 2.75 6C2.55109 6 2.36032 5.92098 2.21967 5.78033L0.21967 3.78033C-0.0732234 3.48744 -0.0732232 3.01256 0.21967 2.71967C0.512563 2.42678 0.987437 2.42678 1.28033 2.71967L2.75 4.18934L6.71967 0.21967C7.01256 -0.0732233 7.48744 -0.0732233 7.78033 0.21967Z"
      fill="#707070"
    />
  </Svg>
);
