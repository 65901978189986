import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TallyIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M179.251 67.0119C179.251 80.2348 174.369 98.4265 167.172 122.873L162.023 141.649L178.318 131.005C209.291 109.256 227.996 101.093 240.377 101.093C254.459 101.093 269 111.156 269 131.17C269 156.629 241.819 163.861 189.342 164.753L169.721 165.675L185.195 177.993C221.931 206.309 234.666 220.181 234.666 237.758C234.666 251.591 221.81 266 205.87 266C181.92 266 171.095 241.112 156.502 198.86L149.064 180.495L142.065 198.86C125.71 246.852 113.122 265.558 92.2576 265.558C75.4361 265.558 63.4615 250.267 63.4615 237.317C63.4615 217.973 81.0431 201.894 113.373 177.993L128.847 165.675L109.667 164.753C53.9319 164.303 30 156.287 30 130.728C30 110.715 44.9899 100.651 58.8047 100.651C75.4879 100.651 93.683 111.905 120.691 131.005L136.985 141.649L131.836 122.873C124.034 97.102 120.371 78.1962 120.371 67.0119C120.371 49.504 130.238 35 149.504 35C169.211 35 179.251 49.504 179.251 67.0119Z"
      fill="currentcolor"
    />
  </Svg>
);
