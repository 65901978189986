export const getOS = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.includes(platform)) {
    return 'macOS';
  }
  if (iosPlatforms.includes(platform)) {
    return 'iOS';
  }
  if (windowsPlatforms.includes(platform)) {
    return 'Windows';
  }
  if (userAgent.includes('Android')) {
    return 'Android';
  }
  if (platform.includes('Linux')) {
    return 'Linux';
  }

  return null;
};
