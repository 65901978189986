import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const BrushIcon = (props: SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M11.0001 10.3334L5.66681 5.00002M9.83355 11.5001L13.3907 7.94283C13.9114 7.42213 13.9114 6.57792 13.3907 6.05722L13.0001 5.66669H11.6668L11.6668 4.33335L9.94298 2.60952C9.42227 2.08881 8.57803 2.08882 8.05734 2.60954L4.50013 6.16691C4.04 6.62706 4.0402 7.37315 4.50058 7.83305C4.96095 8.29296 4.96115 9.03904 4.50103 9.49919L2.66711 11.3332C2.11479 11.8856 2.11488 12.7811 2.66733 13.3334C3.21965 13.8855 4.11497 13.8854 4.66718 13.3332L6.50092 11.4993C6.9611 11.0391 7.70727 11.0393 8.16725 11.4997C8.62721 11.9601 9.37337 11.9602 9.83355 11.5001Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
