import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CheckCircleFillIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99533 5.81463L5.91667 8.89329C5.81867 8.99129 5.69133 9.03996 5.56333 9.03996C5.43467 9.03996 5.30733 8.99129 5.20933 8.89329L3.67 7.35396C3.47467 7.15863 3.47467 6.84196 3.67 6.64663C3.86533 6.45129 4.18133 6.45129 4.37667 6.64663L5.56333 7.83263L8.288 5.10729C8.48333 4.91196 8.8 4.91196 8.99533 5.10729C9.19067 5.30263 9.19067 5.61929 8.99533 5.81463ZM6.33333 0.666626C2.84133 0.666626 0 3.50796 0 6.99996C0 10.4926 2.84133 13.3333 6.33333 13.3333C9.82533 13.3333 12.6667 10.4926 12.6667 6.99996C12.6667 3.50796 9.82533 0.666626 6.33333 0.666626Z"
      fill="currentcolor"
    />
  </Svg>
);
