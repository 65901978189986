import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GroupCompletedIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    data-icon-status
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.335 4.165C11.4403 4.27047 11.4995 4.41343 11.4995 4.5625C11.4995 4.71156 11.4403 4.85453 11.335 4.96L5.8975 10.3975C5.79204 10.5028 5.64907 10.562 5.5 10.562C5.35094 10.562 5.20797 10.5028 5.1025 10.3975L2.665 7.96C2.5657 7.8533 2.51162 7.71226 2.51414 7.56652C2.51666 7.42079 2.57557 7.2817 2.6785 7.1785C2.78171 7.07556 2.92079 7.01665 3.06653 7.01413C3.21227 7.01161 3.35331 7.06569 3.46 7.165L5.5 9.205L10.54 4.165C10.6455 4.05966 10.7884 4.00049 10.9375 4.00049C11.0866 4.00049 11.2295 4.05966 11.335 4.165Z"
      fill="#08773A"
    />
  </Svg>
);
