import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const EnvelopePillIcon: FC<SvgProps> = (props) => (
  <Svg
    width="19"
    height="16"
    viewBox="0 0 17 14"
    fill="none"
    data-no-fill
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75 2.75H2.75C1.92157 2.75 1.25 3.42157 1.25 4.25V11.75C1.25 12.5784 1.92157 13.25 2.75 13.25H13.25C14.0784 13.25 14.75 12.5784 14.75 11.75V8.75M1.24854 5.75C3.12896 7.1628 5.46653 8 7.99963 8C9.03867 8 10.0448 7.85914 11 7.59547M16.25 3.5C16.25 4.74264 15.2426 5.75 14 5.75C12.7574 5.75 11.75 4.74264 11.75 3.5C11.75 2.25736 12.7574 1.25 14 1.25C15.2426 1.25 16.25 2.25736 16.25 3.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
