import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SkypeIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path d="M14.475 9.13749C14.6359 8.08489 14.554 7.00941 14.2356 5.99332C13.9171 4.97722 13.3705 4.04741 12.6375 3.275C10.45 0.9125 7.03749 1.35 6.68749 1.4375C6.14529 1.15975 5.54661 1.01008 4.93749 1C3.89862 1.01734 2.90714 1.43774 2.17244 2.17244C1.43774 2.90714 1.01734 3.89862 1 4.93749C1.0169 5.61215 1.19704 6.27267 1.525 6.86249C1.30291 8.09643 1.42397 9.36766 1.875 10.5375C2.57344 12.0936 3.81891 13.339 5.37499 14.0375C6.56857 14.5128 7.86669 14.6637 9.13749 14.475C9.7199 14.8208 10.3852 15.0022 11.0625 15C12.1014 14.9826 13.0928 14.5622 13.8275 13.8275C14.5622 13.0928 14.9826 12.1014 15 11.0625C14.9831 10.3878 14.8029 9.72731 14.475 9.13749ZM7.99999 12.025C5.63749 12.025 4.58749 10.8875 4.58749 10.0125C4.58749 9.80363 4.67046 9.60333 4.81815 9.45564C4.96583 9.30796 5.16614 9.22499 5.37499 9.22499C6.33749 9.22499 6.16249 10.625 7.99999 10.625C8.96249 10.625 9.48749 10.1 9.48749 9.57499C9.48749 8.52499 8.26249 8.69999 6.59999 8.26249C4.93749 7.82499 4.67499 6.94999 4.67499 6.07499C4.67499 4.325 6.33749 3.625 7.91249 3.625C9.39999 3.625 11.0625 4.41249 11.0625 5.46249C11.0387 5.67111 10.9334 5.86185 10.7694 5.99302C10.6054 6.12418 10.3962 6.18511 10.1875 6.16249C9.31249 6.16249 9.48749 4.93749 7.73749 4.93749C6.86249 4.93749 6.42499 5.28749 6.42499 5.89999C6.42499 6.68749 7.99999 6.86249 9.22499 7.12499C10.8875 7.56249 11.325 8.52499 11.325 9.39999C11.4125 10.975 10.275 12.025 7.99999 12.025Z" />
  </Svg>
);
