import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TwitterIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M5.18811 2H10.8113C12.7767 2 14 3.38746 14 5.35092V10.6491C14 12.6125 12.7767 14 10.8106 14H5.18811C3.2227 14 2 12.6125 2 10.6491V5.35092C2 3.38746 3.22854 2 5.18811 2Z"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9998 5.0009C6.92427 4.88963 6.7986 4.823 6.66413 4.823H5.14493C5.01477 4.823 4.93761 4.96856 5.01067 5.07628L9.03727 11.0129C9.11273 11.1242 9.23847 11.1909 9.37293 11.1909H10.8921C11.0223 11.1909 11.0995 11.0453 11.0264 10.9376L6.9998 5.0009Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.354 8.59644L4.94531 11.3325"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8154 4.66675L8.55011 7.23988"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
