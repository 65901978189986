import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CodeBorderIcon = (props:SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 18 18"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 6.5L5.20711 8.29289C4.81658 8.68342 4.81658 9.31658 5.20711 9.70711L7 11.5M11 6.5L12.7929 8.29289C13.1834 8.68342 13.1834 9.31658 12.7929 9.70711L11 11.5M4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
