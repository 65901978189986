import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ForwardIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 5.66663C4.47083 5.66663 1 7.76663 1 13.8333C2.8375 10.8583 5.2 10.3333 8 10.3333V13.8333L15 7.99996L8 2.16663V5.66663Z" />
  </Svg>
);
