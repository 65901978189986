import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const WebPageCloseIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8432 8.5304V5.49537C12.8432 3.7171 11.7361 2.46154 9.956 2.46154H4.86491C3.09083 2.46154 1.97836 3.7171 1.97836 5.49537V10.2919C1.97836 12.0702 3.08544 13.3263 4.86491 13.3263H5.92169"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8432 6.37981H1.97836"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.99807 4.60427H3.96394M5.49844 4.60427H5.46431M6.99883 4.60427H6.96468"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8623 11.0865L9.41108 13.5384M9.41108 11.0865L11.8623 13.5384"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
