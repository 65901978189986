import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ParentIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1111 3.55556C4.1111 3.24873 3.86237 3 3.55555 3C3.24873 3 3 3.24873 3 3.55556V7.44444C3 8.18116 3.29266 8.8877 3.81359 9.40863C4.33452 9.92956 5.04105 10.2222 5.77776 10.2222H11.1032L9.27386 12.0516C9.0569 12.2686 9.0569 12.6203 9.27386 12.8373C9.49081 13.0542 9.84257 13.0542 10.0595 12.8373L12.8373 10.0595C13.0542 9.84255 13.0542 9.49079 12.8373 9.27383L10.0595 6.49605C9.84257 6.27909 9.49081 6.27909 9.27386 6.49605C9.0569 6.71301 9.0569 7.06477 9.27386 7.28173L11.1032 9.11111H5.77776C5.33573 9.11111 4.91181 8.93552 4.59925 8.62296C4.2867 8.3104 4.1111 7.88647 4.1111 7.44444V3.55556Z"
    />
  </Svg>
);
