import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CommentOutlineIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26541 3.05956C6.87872 3.00333 5.52489 3.39762 4.45603 4.15592C3.38861 4.9132 2.68546 5.97511 2.4512 7.13248C2.21735 8.28778 2.46095 9.48483 3.15131 10.5059C3.26926 10.6803 3.29938 10.8997 3.23278 11.0995L2.69463 12.7139L4.8358 12.2584C4.98326 12.227 5.13699 12.2457 5.27262 12.3116C6.48078 12.8981 7.89486 13.0771 9.24763 12.8091C10.6003 12.5412 11.7803 11.8488 12.5784 10.8806C13.3744 9.91487 13.739 8.73935 13.6246 7.57142C13.5101 6.4024 12.9204 5.29268 11.9395 4.45596C10.9567 3.61775 9.65208 3.11578 8.26541 3.05956ZM1.63963 14.2994C1.70424 14.3031 1.7682 14.2973 1.82976 14.2831L4.89635 13.6306C6.3252 14.2678 7.95379 14.4466 9.51091 14.1381C11.147 13.814 12.6121 12.9697 13.6238 11.7423C14.6375 10.5125 15.1241 8.98296 14.9729 7.43934C14.8218 5.89679 14.0471 4.47294 12.8187 3.42517C11.592 2.37888 9.9925 1.77365 8.3203 1.70585C6.64812 1.63805 4.99649 2.11135 3.6721 3.05093C2.34628 3.99153 1.43126 5.34226 1.12331 6.8637C0.837196 8.2772 1.09804 9.72697 1.84561 10.9767L1.03857 13.3978C1.01752 13.4574 1.00467 13.5203 1.00108 13.5849C0.996363 13.6681 1.00721 13.7501 1.03174 13.8274C1.06131 13.9212 1.10997 14.0046 1.17242 14.0744C1.23474 14.1442 1.31225 14.202 1.40212 14.2418C1.47615 14.2748 1.55649 14.2948 1.63963 14.2994Z"
    />
  </Svg>
);
