import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CalendarIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.333"
      d="M2.667 6.667h10.666m-8-3.334V2m5.334 1.333V2m-6 11.333h6.666a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H4.667a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Z"
    />
  </Svg>
);
