import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ActivityIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10323 1.63226C6.39481 1.63226 6.65368 1.81884 6.74588 2.09546L9.89677 11.5481L11.1509 7.78579C11.2431 7.50917 11.502 7.32259 11.7935 7.32259H14.3226C14.6967 7.32259 15 7.62588 15 8.00001C15 8.37413 14.6967 8.67742 14.3226 8.67742H12.2818L10.5394 13.9045C10.4472 14.1812 10.1884 14.3677 9.89677 14.3677C9.60519 14.3677 9.34632 14.1812 9.25412 13.9045L6.10323 4.45187L4.84911 8.21422C4.7569 8.49084 4.49803 8.67742 4.20645 8.67742H1.67742C1.30329 8.67742 1 8.37413 1 8.00001C1 7.62588 1.30329 7.32259 1.67742 7.32259H3.7182L5.46057 2.09546C5.55278 1.81884 5.81164 1.63226 6.10323 1.63226Z"
      fill="black"
    />
  </Svg>
);
