import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SettingsIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.3333 1H12.6667V3.33333H15V5.66667H8V3.33333H10.3333V1Z" />
    <path d="M10.3333 6.83333H12.6667V15H10.3333V6.83333Z" />
    <path d="M1 12.6667H3.33333V15H5.66667V12.6667H8V10.3333H1V12.6667Z" />
    <path d="M3.33333 1H5.66667V9.16667H3.33333V1Z" />
  </Svg>
);
