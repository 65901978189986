import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const WebhookIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.78937 6.41333C5.31728 5.9951 4.97354 5.45152 4.79806 4.84573C4.62258 4.23993 4.62257 3.59679 4.79803 2.99099C4.97348 2.38519 5.3172 1.84159 5.78927 1.42335C6.26134 1.0051 6.84238 0.729359 7.4649 0.628156C8.08743 0.526953 8.72589 0.604439 9.30614 0.851614C9.88638 1.09879 10.3846 1.50551 10.7429 2.02453C11.1012 2.54356 11.3049 3.15359 11.3304 3.78377C11.3558 4.41396 11.202 5.03843 10.8867 5.58466L9.73203 4.918C9.92969 4.57573 10.0215 4.18257 9.99587 3.78816C9.97024 3.39375 9.82833 3.01578 9.58805 2.70196C9.34777 2.38815 9.01989 2.15256 8.64582 2.02495C8.27175 1.89734 7.86825 1.88342 7.48627 1.98496C7.1043 2.0865 6.76097 2.29894 6.49964 2.59545C6.23831 2.89197 6.0707 3.25927 6.01796 3.65097C5.96522 4.04268 6.02973 4.44123 6.20333 4.7963C6.37693 5.15138 6.65185 5.44705 6.99337 5.646L7.57737 5.98333L4.48803 11.3333C4.63479 11.5875 4.69357 11.883 4.65526 12.174C4.61695 12.465 4.48368 12.7353 4.27614 12.9428C4.06859 13.1504 3.79836 13.2836 3.50736 13.3219C3.21635 13.3602 2.92084 13.3014 2.66665 13.1547C2.41246 13.0079 2.2138 12.7814 2.10149 12.5102C1.98917 12.239 1.96947 11.9384 2.04544 11.6549C2.12141 11.3714 2.28881 11.1208 2.52168 10.9422C2.75454 10.7635 3.03985 10.6667 3.33337 10.6667L5.78937 6.41333Z"
      fill="currentcolor"
    />
    <path
      d="M9.15466 3.25114C9.03763 3.04845 8.86932 2.88013 8.66662 2.7631C8.46393 2.64608 8.234 2.58447 7.99995 2.58447C7.7659 2.58447 7.53597 2.64608 7.33328 2.76311C7.13059 2.88014 6.96227 3.04846 6.84525 3.25115C6.72823 3.45385 6.66662 3.68378 6.66663 3.91783C6.66663 4.15188 6.72824 4.3818 6.84527 4.58449C6.9623 4.78719 7.13062 4.9555 7.33332 5.07252C7.53601 5.18954 7.76594 5.25115 7.99999 5.25114L11.0893 10.6018L11.6667 10.2685C12.0092 10.0707 12.4027 9.97891 12.7975 10.0048C13.1922 10.0306 13.5704 10.173 13.8842 10.4138C14.198 10.6546 14.4334 10.983 14.5606 11.3576C14.6877 11.7321 14.701 12.136 14.5986 12.5181C14.4962 12.9002 14.2828 13.2433 13.9854 13.5042C13.688 13.765 13.32 13.9317 12.9278 13.9834C12.5356 14.035 12.1369 13.9692 11.7821 13.7942C11.4274 13.6193 11.1324 13.343 10.9347 13.0005L9.77999 13.6671C10.0955 14.2132 10.5594 14.6586 11.1179 14.9515C11.6765 15.2445 12.3066 15.3729 12.9352 15.3221C13.5638 15.2712 14.1651 15.043 14.6692 14.664C15.1733 14.285 15.5596 13.7708 15.7831 13.1811C16.0067 12.5914 16.0583 11.9504 15.9321 11.3325C15.8059 10.7145 15.507 10.1451 15.0701 9.69032C14.6331 9.23551 14.0762 8.91399 13.4638 8.76308C12.8515 8.61218 12.2089 8.63809 11.6107 8.83781L9.15466 4.58447C9.27168 4.38178 9.33329 4.15185 9.33329 3.91781C9.33329 3.68376 9.27168 3.45383 9.15466 3.25114Z"
      fill="currentcolor"
    />
    <path
      d="M6.60005 12.6665C6.47392 13.2844 6.17506 13.8539 5.73819 14.3088C5.30132 14.7637 4.74436 15.0853 4.13202 15.2363C3.51967 15.3872 2.87705 15.3614 2.2788 15.1617C1.68055 14.9621 1.15123 14.5968 0.752294 14.1083C0.353363 13.6198 0.1012 13.0282 0.0250933 12.4021C-0.0510136 11.776 0.0520584 11.1412 0.322338 10.5713C0.592618 10.0015 1.01901 9.52004 1.552 9.18286C2.08499 8.84568 2.7027 8.66663 3.33339 8.6665V9.99984C2.93811 9.99984 2.5517 10.117 2.22294 10.3364C1.89419 10.5559 1.63784 10.8679 1.48627 11.2329C1.33471 11.598 1.29471 11.9998 1.37134 12.3876C1.44797 12.7754 1.6378 13.1317 1.91683 13.4117C2.19587 13.6917 2.55161 13.8827 2.93914 13.9606C3.32666 14.0385 3.72858 13.9999 4.09415 13.8495C4.45971 13.6991 4.77253 13.4438 4.99309 13.1158C5.21365 12.7878 5.33207 12.4018 5.33339 12.0065V11.3332H11.5121C11.6588 11.079 11.8853 10.8803 12.1565 10.768C12.4277 10.6557 12.7283 10.636 13.0118 10.7119C13.2954 10.7879 13.5459 10.9553 13.7246 11.1882C13.9032 11.421 14.0001 11.7063 14.0001 11.9998C14.0001 12.2933 13.9032 12.5787 13.7246 12.8115C13.5459 13.0444 13.2954 13.2118 13.0118 13.2877C12.7283 13.3637 12.4277 13.344 12.1565 13.2317C11.8853 13.1194 11.6588 12.9207 11.5121 12.6665H6.60005Z"
      fill="currentcolor"
    />
  </Svg>
);
