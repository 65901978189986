import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TrustpilotIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 799.89 761"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      fill="#00b67a"
      d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z"
    />
    <path
      fill="#005128"
      d="m574.04 536.24-21.23-65.78-152.72 110.91z"
    />
  </Svg>
);
