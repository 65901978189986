import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DescriptIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    data-no-fill
  >
    <g clipPath="url(#clip0_4759_12766)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.717896 14.2463C0.717896 15.0792 1.25841 15.6194 2.09163 15.6194L6.10532 15.6194C8.49969 15.6194 10.4584 14.8096 11.79 13.3919H0.717896V14.2463ZM6.10532 0.381424L2.09163 0.381348C1.25841 0.381348 0.717896 0.921614 0.717896 1.75452V2.60884H11.79C10.4584 1.19117 8.49969 0.381424 6.10532 0.381424ZM9.6081 10.1716C9.6081 10.8454 10.0472 11.2826 10.7242 11.2826H13.1293C13.4019 10.6063 13.5852 9.86287 13.6703 9.06059H10.7242C10.0472 9.06059 9.6081 9.49769 9.6081 10.1716ZM6.27447 5.83465C6.27447 6.50856 6.7136 6.94566 7.39063 6.94566H13.6703C13.5851 6.14337 13.4019 5.39992 13.1293 4.72365H7.39063C6.7136 4.72365 6.27447 5.16075 6.27447 5.83465ZM7.38987 10.1716C7.38987 9.49769 6.95073 9.06059 6.27371 9.06059H0.717896V11.2826H6.27371C6.95073 11.2826 7.38987 10.8455 7.38987 10.1716ZM4.05624 5.83465C4.05624 5.16075 3.61711 4.72365 2.94008 4.72365H0.717896V6.94566H2.94008C3.61711 6.94566 4.05624 6.50856 4.05624 5.83465Z"
        fill="#0062FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_4759_12766">
        <rect
          width="12.9524"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
