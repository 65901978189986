export const ERROR_CODE = {
  GENERIC: 'GENERIC',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  WRONG_CODE: 'WRONG_CODE',
  WRONG_PARAMETERS: 'WRONG_PARAMETERS',
  TOO_MANY_TRIES: 'TOO_MANY_TRIES',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',

  PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',
  PRODUCT_AREA_NOT_FOUND: 'PRODUCT_AREA_NOT_FOUND',
  PRODUCT_AREA_CATEGORY_NOT_FOUND: 'PRODUCT_AREA_CATEGORY_NOT_FOUND',
  BOARD_NOT_FOUND: 'BOARD_NOT_FOUND',
  SECTION_NOT_FOUND: 'SECTION_NOT_FOUND',
  DOCTYPE_TEMPLATE_NOT_FOUND: 'DOCTYPE_TEMPLATE_NOT_FOUND',
  ATTRIBUTE_DEFINITION_NOT_FOUND: 'ATTRIBUTE_DEFINITION_NOT_FOUND',
  ATTRIBUTE_VALUE_NOT_FOUND: 'ATTRIBUTE_VALUE_NOT_FOUND',
  STATUS_NOT_FOUND: 'STATUS_NOT_FOUND',
  DOCTYPE_NOT_FOUND: 'DOCTYPE_NOT_FOUND',
  DOC_NOT_FOUND: 'DOC_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  CONVERSATION_NOT_FOUND: 'CONVERSATION_NOT_FOUND',
  SWIMLANE_NOT_FOUND: 'SWIMLANE_NOT_FOUND',
  ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
  DOC_WITH_HIERARCHY_NOT_FOUND: 'DOC_WITH_HIERARCHY_NOT_FOUND',
  DOC_POSITION_NOT_FOUND: 'DOC_POSITION_NOT_FOUND',
  CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  COMPANY_NOT_FOUND: 'COMPANY_NOT_FOUND',
  VALUE_NOT_FOUND: 'VALUE_NOT_FOUND',
  PROVIDER_NOT_FOUND: 'PROVIDER_NOT_FOUND',
  BOARD_CONFIG_NOT_FOUND: 'BOARD_CONFIG_NOT_FOUND',
  LAST_BOARD_CONFIG_SAVED_NOT_FOUND: 'LAST_BOARD_CONFIG_SAVED_NOT_FOUND',
  PROPERTY_NOT_FOUND: 'PROPERTY_NOT_FOUND',
  SWIMLANE_BY_NOT_FOUND: 'SWIMLANE_BY_NOT_FOUND',
  FILTER_PROPERTY_RULE_NOT_FOUND: 'FILTER_PROPERTY_RULE_NOT_FOUND',
  INTEGRATION_NOT_FOUND: 'INTEGRATION_NOT_FOUND',
  BILLING_NOT_FOUND: 'BILLING_NOT_FOUND',
  SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_FOUND: 'SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_FOUND',
  GITHUB_STATUS_NOT_FOUND: 'GITHUB_STATUS_NOT_FOUND',
  DOC_GROUP_WITH_PROPERTY_VALUE_NOT_FOUND: 'DOC_GROUP_WITH_PROPERTY_VALUE_NOT_FOUND',
  GROUP_BY_PROPERTY_NOT_FOUND: 'GROUP_BY_PROPERTY_NOT_FOUND',
  DOC_ATTRIBUTE_NOT_FOUND: 'DOC_ATTRIBUTE_NOT_FOUND',
  DISPLAYABLE_PROPERTY_NOT_FOUND: 'DISPLAYABLE_PROPERTY_NOT_FOUND',
  RELEASE_NOT_FOUND: 'RELEASE_NOT_FOUND',
  RELEASE_NOTE_NOT_FOUND: 'RELEASE_NOTE_NOT_FOUND',
  CHANGELOG_NOT_FOUND: 'CHANGELOG_NOT_FOUND',
  COMMENT_NOT_FOUND: 'COMMENT_NOT_FOUND',
  THREAD_NOT_FOUND: 'THREAD_NOT_FOUND',
  NOTION_PAGE_OR_DATABASE_NOT_FOUND: 'NOTION_PAGE_OR_DATABASE_NOT_FOUND',
  AUDIO_NOT_FOUND: 'AUDIO_NOT_FOUND',
  SORT_BY_NOT_FOUND: 'SORT_BY_NOT_FOUND',
  INSIGHTS_NOT_FOUND: 'INSIGHTS_NOT_FOUND',
  OAUTH_CLIENT_NOT_FOUND: 'OAUTH_CLIENT_NOT_FOUND',
  CALENDAR_EVENTS_NOT_FOUND: 'CALENDAR_EVENTS_NOT_FOUND',
  CALL_SETTINGS_NOT_FOUND: 'CALL_SETTINGS_NOT_FOUND',
  BOT_NOT_FOUND: 'BOT_NOT_FOUND',
  AI_STATE_PROPERTY_NOT_FOUND: 'AI_STATE_PROPERTY_NOT_FOUND',
  BOARD_LINK_NOT_FOUND: 'BOARD_LINK_NOT_FOUND',
  SOURCE_NOT_FOUND: 'SOURCE_NOT_FOUND',
  QUOTE_NOT_FOUND: 'QUOTE_NOT_FOUND',
  NOTIFICATION_NOT_FOUND: 'NOTIFICATION_NOT_FOUND',
  FILE_NOT_FOUND: 'FILE_NOT_FOUND',
  DATASET_NOT_FOUND: 'DATASET_NOT_FOUND',
  KEYWORD_NOT_FOUND: 'KEYWORD_NOT_FOUND',

  // NOT CONNECTED
  DOCTYPE_NOT_CONNECTED_TO_ATTRIBUTE_DEFINITION: 'DOCTYPE_NOT_CONNECTED_TO_ATTRIBUTE_DEFINITION',
  USER_NOT_CONNECTED_TO_PRODUCT: 'USER_NOT_CONNECTED_TO_PRODUCT',
  CANT_CONNECT_BOT_TO_PRODUCT: 'CAN_NOT_CONNECT_BOT_TO_PRODUCT',
  CANT_REMOVE_LAST_CUSTOMER: 'CANT_REMOVE_LAST_CUSTOMER',

  PROPERTY_IS_NOT_A_CUSTOM_PROPERTY: 'PROPERTY_IS_NOT_A_CUSTOM_PROPERTY',
  USER_DO_NOT_BELONG_TO_PRODUCT: 'USER_DO_NOT_BELONG_TO_PRODUCT',
  ATTRIBUTE_DEFINITON_NOT_CREATED: 'ATTRIBUTE_DEFINITION_NOT_CREATED',
  LINEAR_ISSUE_NOT_UPDATED: 'LINEAR_ISSUE_NOT_UPDATED',
  // NOT CREATED
  PRODUCT_NOT_CREATED: 'PRODUCT_NOT_CREATED',
  ORGANIZATION_NOT_CREATED: 'ORGANIZATION_NOT_CREATED',
  SLACK_INTEGRATION_NOT_CREATED: 'SLACK_INTEGRATION_NOT_CREATED',
  INTERCOM_INTEGRATION_NOT_CREATED: 'INTERCOM_INTEGRATION_NOT_CREATED',
  HUBSPOT_INTEGRATION_NOT_CREATED: 'HUBSPOT_INTEGRATION_NOT_CREATED',
  NOTIFICATION_NOT_CREATED: 'NOTIFICATION_NOT_CREATED',
  VALUE_NOT_CREATED: 'VALUE_NOT_CREATED',
  BOARD_NOT_CREATED: 'BOARD_NOT_CREATED',
  LINEAR_ISSUE_NOT_CREATED: 'LINEAR_ISSUE_NOT_CREATED',
  LINEAR_PROJECT_NOT_CREATED: 'LINEAR_PROJECT_NOT_CREATED',
  DOCTYPE_NOT_CREATED: 'DOCTYPE_NOT_CREATED',
  CUSTOMER_EMAIL_OR_NAME_REQUIRED: 'CUSTOMER_EMAIL_OR_NAME_REQUIRED',
  CUSTOMER_WITH_EMAIL_ALREADY_EXISTS: 'CUSTOMER_WITH_EMAIL_ALREADY_EXISTS',
  PRODUCT_AREA_ALREADY_EXISTS: 'PRODUCT_AREA_ALREADY_EXISTS',
  PRODUCT_AREA__CATEGORY_ALREADY_EXISTS: 'PRODUCT_AREA__CATEGORY_ALREADY_EXISTS',
  KEYWORD_ALREADY_EXISTS: 'KEYWORD_ALREADY_EXISTS',
  BOT_NOT_CREATED: 'BOT_NOT_CREATED',
  CALENDAR_NOT_CREATED: 'CALENDAR_NOT_CREATED',
  FILE_NOT_CREATED: 'FILE_NOT_CREATED',
  // NOT UPDATED
  PRODUCT_NOT_UPDATED: 'PRODUCT_NOT_UPDATED',
  USER_NOT_UPDATED: 'USER_NOT_UPDATED',
  USER_PRODUCT_NOT_UPDATED: 'USER_PRODUCT_NOT_UPDATED',
  DOCTYPE_TEMPLATE_NOT_UPDATED: 'DOCTYPE_TEMPLATE_NOT_UPDATED',
  ATTRIBUTE_DEFINITION_NOT_UPDATED: 'ATTRIBUTE_DEFINITION_NOT_UPDATED',
  BOARD_NOT_UPDATED: 'BOARD_NOT_UPDATED',
  BOARD_LINK_NOT_UPDATED: 'BOARD_LINK_NOT_UPDATED',
  DOC_PARENT_NOT_UPDATED: 'DOC_PARENT_NOT_UPDATED',
  DOC_ASSIGNEE_NOT_UPDATED: 'DOC_ASSIGNEE_NOT_UPDATED',
  DOC_CUSTOMER_NOT_UPDATED: 'DOC_CUSTOMER_NOT_UPDATED',
  DOC_NOT_UPDATED: 'DOC_NOT_UPDATED',
  SWIMLANE_NOT_UPDATED: 'SWIMLANE_NOT_UPDATED',
  SWIMLANE_BY_NOT_UPDATED: 'SWIMLANE_BY_NOT_UPDATED',
  BOARD_CONFIG_NOT_UPDATED: 'BOARD_CONFIG_NOT_UPDATED',
  COMMENT_NOT_UPDATED: 'COMMENT_NOT_UPDATED',
  CUSTOMER_NOT_UPDATED: 'CUSTOMER_NOT_UPDATED',
  DOC_GROUP_WITH_PROPERTY_VALUE_NOT_UPDATED: 'DOC_GROUP_WITH_PROPERTY_VALUE_NOT_UPDATED',
  VALUE_NOT_UPDATED: 'VALUE_NOT_UPDATED',
  DOCTYPE_NOT_UPDATED: 'DOCTYPE_NOT_UPDATED',
  DOCS_NOT_MOVED: 'DOCS_NOT_MOVED',
  SLACK_INTEGRATION_NOT_UPDATED: 'SLACK_INTEGRATION_NOT_UPDATED',
  SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_UPDATED: 'SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_UPDATED',
  FILTER_PROPERTY_RULE_NOT_UPDATED: 'FILTER_PROPERTY_RULE_NOT_UPDATED',
  CANT_ADD_CUSTOMER_ON_CUSTOM_DOCTYPE: 'CANT_ADD_CUSTOMER_ON_CUSTOM_DOCTYPE',
  // NOT DELETED
  VALUE_NOT_DELETED: 'VALUE_NOT_DELETED',
  ATTRIBUTE_VALUE_NOT_UPDATED: 'ATTRIBUTE_VALUE_NOT_UPDATED',
  COMMENT_NOT_DELETED: 'COMMENT_NOT_DELETED',
  SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_DELETED: 'SWIMLANE_BY_FILTER_PROPERTY_RULE_NOT_DELETED',
  FILTER_PROPERTY_RULE_NOT_DELETED: 'FILTER_PROPERTY_RULE_NOT_DELETED',
  DOCTYPE_FILTER_RULE_CANT_BE_EMPTY_IN_ROADMAPS_SECTION: 'DOCTYPE_FILTER_RULE_CANT_BE_EMPTY_IN_ROADMAPS_SECTION',
  // INTEGRATION_NOT_FOUND: 'INTEGRATION NOT FOUND',
  NOTION_INTEGRATION_NOT_FOUND: 'NOTION_INTEGRATION_NOT_FOUND',
  NOT_BUILTIN_ATTRIBUTE_DEFINITION: 'NOT_BUILTIN_ATTRIBUTE_DEFINITION',
  // CANT SEE
  CANT_SEE_SWIMLANE: 'CANT_SEE_SWIMLANE',
  CANT_SEE_NOTIFICATION: 'CANT_SEE_NOTIFICATION',
  CANT_SEE_ORGANIZATION: 'CANT_SEE_ORGANIZATION',
  CANT_SEE_PRODUCT: 'CANT_SEE_PRODUCT',
  CANT_SEE_PRODUCT_AREA: 'CANT_SEE_PRODUCT_AREA',
  CANT_SEE_PRODUCT_AREA_CATEGORY: 'CANT_SEE_PRODUCT_AREA_CATEGORY',
  CANT_SEE_DISPLAYABLE_PROPERTY: 'CANT_SEE_DISPLAYABLE_PROPERTY',
  CANT_SEE_PROPERTY: 'CANT_SEE_PROPERTY',
  CANT_SEE_VALUE: 'CANT_SEE_VALUE',
  CANT_SEE_ATTRIBUTE_DEFINITION: 'CANT_SEE_ATTRIBUTE_DEFINITION',
  CANT_SEE_BOARD: 'CANT_SEE_BOARD',
  CANT_SEE_SOURCE_CATEGORY: 'CANT_SEE_SOURCE_CATEGORY',
  CANT_SEE_INTEGRATION: 'CANT_SEE_INTEGRATION',
  CANT_SEE_USER: 'CANT_SEE_USER',
  CANT_SEE_SWIMLANE_BY: 'CANT_SEE_SWIMLANE_BY',
  CANT_SEE_CLAP: 'CANT_SEE_CLAP',
  CANT_SEE_COMMENT: 'CANT_SEE_COMMENT',
  CANT_SEE_SECTION: 'CANT_SEE_SECTION',
  CANT_SEE_STATUS: 'CANT_SEE_STATUS',
  CANT_SEE_FILTER_PROPERTY_RULE_SELECTABLE_VALUE: 'CANT_SEE_FILTER_PROPERTY_RULE_SELECTABLE_VALUE',
  CANT_SEE_CUSTOMER: 'CANT_SEE_CUSTOMER',
  CANT_SEE_DOC: 'CANT_SEE_DOC',
  CANT_SEE_COMPANY: 'CANT_SEE_COMPANY',
  CANT_SEE_DOCTYPE: 'CANT_SEE_DOCTYPE',
  CANT_SEE_WEBHOOK: 'CANT_SEE_WEBHOOK',
  CANT_SEE_RELEASE: 'CANT_SEE_RELEASE',
  CANT_SEE_RELEASE_NOTE_TAG_VALUE: 'CANT_SEE_RELEASE_NOTE_TAG_VALUE',
  CANT_SEE_QUOTE: 'CANT_SEE_QUOTE',
  CANT_SUMMARIZE_DOC: 'CANT_SUMMARIZE_DOC',
  CANT_UPDATE_DOC_SOURCE: 'CANT_UPDATE_DOC_SOURCE',
  SHOULD_BE_INSTALLED_ON_GITHUB_ORGANIZATION: 'SHOULD_BE_INSTALLED_ON_GITHUB_ORGANIZATION',
  // ALREADY EXISTS
  COMPANY_ALREADY_EXISTS: 'COMPANY_ALREADY_EXISTS',
  PROPERTY_ALREADY_EXISTS: 'PROPERTY_ALREADY_EXISTS',
  PRODUCT_WITH_SLUG_ALREADY_EXISTS: 'PRODUCT_WITH_SLUG_ALREADY_EXISTS',
  DOCTYPE_ALREADY_EXISTS: 'DOCTYPE_ALREADY_EXISTS',
  USER_ALREADY_INVITED: 'USER_ALREADY_INVITED',
  USER_ALREADY_ONBOARDED: 'USER_ALREADY_ONBOARDED',
  RELEASE_ALREADY_EXISTS: 'RELEASE_ALREADY_EXISTS',
  RELEASE_WITH_DATE_ALREADY_EXISTS: 'RELEASE_WITH_DATE_ALREADY_EXISTS',
  DOC_ALREADY_LINKED_TO_RELEASE_NOTE: 'DOC_ALREADY_LINKED_TO_RELEASE_NOTE',
  THREAD_ALREADY_OPEN: 'THREAD_ALREADY_OPEN',
  RELEASE_NOTE_TAG_VALUE_ALREADY_EXISTS: 'RELEASE_NOTE_TAG_VALUE_ALREADY_EXISTS',
  // CANT_BE_CREATED
  RELEASE_NOTE_CANT_BE_CREATED_DOCTYPE_NOT_LINKED_TO_RELEASE_PROPERTY: 'RELEASE_NOTE_CANT_BE_CREATED_DOCTYPE_NOT_LINKED_TO_RELEASE_PROPERTY',
  DOCTYPE_NOT_LINKED_TO_AI_STATE_PROPERTY: 'DOCTYPE_NOT_LINKED_TO_AI_STATE_PROPERTY',
  NOT_A_BUSINESS_EMAIL: 'NOT_A_BUSINESS_EMAIL',
  CANT_CREATE_A_BOARD_LINK_WHEN_A_BOARD_LINK_ALREADY_EXISTS: 'CANT_CREATE_A_BOARD_LINK_WHEN_A_BOARD_LINK_ALREADY_EXISTS',
  BOARD_LINK_CANT_BE_CREATED_IN_BUILT_IN_SECTION: 'BOARD_LINK_CANT_BE_CREATED_IN_BUILT_IN_SECTION',
  CANT_CREATE_A_BOARD_IN_CUSTOM_SECTION: 'CANT_CREATE_A_BOARD_IN_CUSTOM_SECTION',
  // CANT_BE_UPDATED
  COMPANY_WITH_NAME_ALREADY_EXISTS: 'COMPANY_WITH_NAME_ALREADY_EXISTS',
  DEFAULT_COMPANY_CANT_BE_UPDATED: 'DEFAULT_COMPANY_CANT_BE_UPDATED',
  DEFAULT_CUSTOMER_CANT_BE_UPDATED: 'DEFAULT_CUSTOMER_CANT_BE_UPDATED',
  CUSTOMER_EMAIL_ALREADY_USED: 'CUSTOMER_EMAIL_ALREADY_USED',
  CUSTOMER_EMAIL_SHOULD_BE_DIFFERENT: 'CUSTOMER_EMAIL_SHOULD_BE_DIFFERENT',
  CANT_REMOVE_CUSTOMER_FROM_COMPANY: 'CANT_REMOVE_CUSTOMER_FROM_COMPANY',
  CANT_ADD_DOCTYPE_PARENT_WHEN_DOCTYPES_ALREADY_LINKED_TO_RELEASE: 'CANT_ADD_DOCTYPE_PARENT_WHEN_DOCTYPES_ALREADY_LINKED_TO_RELEASE',
  CANT_UNLINK_INSIGHT_DOCTYPE_WHEN_PARENT_IS_LINKED_TO_RELEASE_PROPERTY: 'CANT_UNLINK_INSIGHT_DOCTYPE_WHEN_PARENT_IS_LINKED_TO_RELEASE_PROPERTY',
  CANT_CLOSE_LOOP_STATUS_NOT_COMPLETED: 'CANT_CLOSE_LOOP_STATUS_NOT_COMPLETED',
  CANT_CLOSE_LOOP_ALREADY_CLOSED: 'CANT_CLOSE_LOOP_ALREADY_CLOSED',
  CANT_CLOSE_LOOP_WHEN_DOC_NO_NOT_HAVE_RELEASE_NOTE: 'CANT_CLOSE_LOOP_WHEN_DOC_NO_NOT_HAVE_RELEASE_NOTE',
  CANT_CLOSE_LOOP_WHEN_DOC_RELEASE_NOT_IS_NOT_PUBLISHED: 'CANT_CLOSE_LOOP_WHEN_DOC_RELEASE_NOT_IS_NOT_PUBLISHED',
  DOC_STATUS_CANT_BE_UPDATED_TO_LOOP_CLOSED: 'DOC_STATUS_CANT_BE_UPDATED_TO_LOOP_CLOSED',
  CANT_REMOVE_LAST_VIEW_IN_BUILT_IN_SECTION: 'CANT_REMOVE_LAST_VIEW_IN_BUILT_IN_SECTION',
  CANT_HAVE_CYCLIC_HIERARCHY_RULE: 'CANT_HAVE_CYCLIC_HIERARCHY_RULE',
  CANT_UPDATE_DOCTYPE_FILTER_RULE_VALUES_IN_FEEDBACK_SECTION: 'CANT_UPDATE_DOCTYPE_FILTER_RULE_VALUES_IN_FEEDBACK_SECTION',
  CANT_USE_BUILT_IN_DOCTYPES_IN_ROADMAPS_SECTION: 'CANT_USE_BUILT_IN_DOCTYPES_IN_ROADMAPS_SECTION',
  CANT_CANCEL_SUBSCRIPTION_ON_FREE_PLAN: 'CANT_CANCEL_SUBSCRIPTION_ON_FREE_PLAN',
  CANT_INVITE_BOT: 'CANT_INVITE_BOT',
  CANT_REMOVE_BOT: 'CANT_REMOVE_BOT',
  MARK_AS_PROCESSED_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON: 'MARK_AS_PROCESSED_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON',
  AUTO_LINK_TO_ROADMAP_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON: 'AUTO_LINK_TO_ROADMAP_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON',
  CANT_UPDATE_BUILTIN_DOCTYPE: 'CANT_UPDATE_BUILTIN_DOCTYPE',
  CANT_SET_AI_CREATED_AS_AI_STATE: 'CANT_SET_AI_CREATED_AS_AI_STATE',
  CANT_UPDATE_FLAG: 'CANT_UPDATE_FLAG',
  CANT_UPDATE_DOC: 'CANT_UPDATE_DOC',
  CANT_UPDATE_BUILTIN_STATUS: 'CANT_UPDATE_BUILTIN_STATUS',
  CANT_REMOVE_BUILTIN_RELEASE: 'CANT_REMOVE_BUILTIN_RELEASE',
  CANT_UPDATE_BUILTIN_RELEASE: 'CANT_UPDATE_BUILTIN_RELEASE',
  CANT_PUBLISH_BUILTIN_RELEASE: 'CANT_PUBLISH_BUILTIN_RELEASE',
  CANT_UNPUBLISH_BUILTIN_RELEASE: 'CANT_UNPUBLISH_BUILTIN_RELEASE',
  CAN_ONLY_EDIT_PUBLISHED_RELEASES: 'CAN_ONLY_EDIT_PUBLISHED_RELEASES',
  CAN_ONLY_DISCARD_EDITING_RELEASE_CHANGES: 'CAN_ONLY_DISCARD_EDITING_RELEASE_CHANGES',
  CANT_UPDATE_PUBLISHED_RELEASE: 'CANT_UPDATE_PUBLISHED_RELEASE',
  CANT_LINK_COMPANY_ATTRIBUTE_TO_DOC: 'CANT_LINK_COMPANY_ATTRIBUTE_TO_DOC',
  PASSWORD_NOT_VALID: 'PASSWORD_NOT_VALID',
  PASSWORD_DO_NOT_MATCH: 'PASSWORD_DO_NOT_MATCH',
  // ADMIN MUTATIONS
  PRODUCTSLUG_IS_NOT_MATCHING_PRODUCT_SLUG: 'PRODUCTSLUG_IS_NOT_MATCHING_PRODUCT_SLUG',
  IMPORT_ERROR: 'IMPORT_ERROR',
  // INTERNAL SERVER ERROR
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
} as const;

export type ErrorType = (typeof ERROR_CODE)[keyof typeof ERROR_CODE];
export const isErrorCode = (x: string): x is ErrorType => Object.keys(ERROR_CODE).includes(x);
