import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const BuildingDashIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 18 13"
    fill="none"
    data-no-fill
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 12.25H10.5M3 12.25V1.75C3 1.33579 3.33579 1 3.75 1H9.75C10.1642 1 10.5 1.33579 10.5 1.75V4M3 12.25H1.5M10.5 12.25V4M10.5 12.25H15M10.5 4H14.25C14.6642 4 15 4.33579 15 4.75V12.25M15 12.25H16.5M7.5 4.75H6M6 7.75H7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
