import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ImageIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25H4C3.0335 2.25 2.25 3.0335 2.25 4V12C2.25 12.274 2.31295 12.5332 2.42517 12.7641L8.49929 7.99153C9.08618 7.5304 9.91174 7.52836 10.5009 7.9866L13.75 10.5137V4C13.75 3.0335 12.9665 2.25 12 2.25ZM4 13.75C3.77089 13.75 3.55206 13.706 3.35152 13.6259L9.27157 8.97443C9.407 8.86801 9.59752 8.86754 9.73348 8.97329L13.6164 11.9933C13.658 12.0257 13.7024 12.052 13.7485 12.0723C13.7106 13.0053 12.9423 13.75 12 13.75H4ZM4 1C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1H4ZM6.05526 5.27757C6.05526 5.9219 5.53292 6.44423 4.88859 6.44423C4.24426 6.44423 3.72192 5.9219 3.72192 5.27757C3.72192 4.63324 4.24426 4.1109 4.88859 4.1109C5.53292 4.1109 6.05526 4.63324 6.05526 5.27757Z"
      fill="black"
    />
  </Svg>
);
