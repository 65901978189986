import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinkArrowIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M6.00008 2.66669H4.80008C4.05335 2.66669 3.67997 2.66669 3.39476 2.81201C3.14387 2.93984 2.9399 3.14381 2.81207 3.3947C2.66675 3.67991 2.66675 4.05329 2.66675 4.80002V11.2C2.66675 11.9468 2.66675 12.3202 2.81207 12.6054C2.9399 12.8562 3.14387 13.0602 3.39476 13.188C3.67997 13.3334 4.05335 13.3334 4.80008 13.3334H11.2001C11.9468 13.3334 12.3202 13.3334 12.6054 13.188C12.8563 13.0602 13.0603 12.8562 13.1881 12.6054C13.3334 12.3202 13.3334 11.9468 13.3334 11.2V10M9.33341 2.66669H13.3334M13.3334 2.66669V6.66669M13.3334 2.66669L7.33341 8.66669"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
