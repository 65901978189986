import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ExportIcon2: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41772 3.03797C2.41772 1.91243 3.33016 1 4.4557 1H8.12162C8.66213 1 9.1805 1.21471 9.56269 1.59691L12.9854 5.01959C13.3676 5.40179 13.5823 5.92015 13.5823 6.46066V12.962C13.5823 14.0876 12.6698 15 11.5443 15H11.1899C10.8473 15 10.5696 14.7223 10.5696 14.3797C10.5696 14.0372 10.8473 13.7595 11.1899 13.7595H11.5443C11.9847 13.7595 12.3418 13.4025 12.3418 12.962V6.49367H10.1266C9.00104 6.49367 8.08861 5.58124 8.08861 4.4557V2.24051H4.4557C4.01527 2.24051 3.65823 2.59755 3.65823 3.03797V12.962C3.65823 13.4025 4.01527 13.7595 4.4557 13.7595H4.81013C5.15269 13.7595 5.43038 14.0372 5.43038 14.3797C5.43038 14.7223 5.15269 15 4.81013 15H4.4557C3.33016 15 2.41772 14.0876 2.41772 12.962V3.03797ZM9.32912 3.11768L11.4646 5.25316H10.1266C9.68616 5.25316 9.32912 4.89613 9.32912 4.4557V3.11768ZM8 8.79747C8.34256 8.79747 8.62026 9.07517 8.62026 9.41772V12.1735L9.33357 11.4601C9.57579 11.2179 9.96852 11.2179 10.2107 11.4601C10.453 11.7024 10.453 12.0951 10.2107 12.3373L8.43859 14.1095C8.19636 14.3517 7.80364 14.3517 7.56142 14.1095L5.78927 12.3373C5.54704 12.0951 5.54704 11.7024 5.78927 11.4601C6.03149 11.2179 6.42421 11.2179 6.66644 11.4601L7.37975 12.1735V9.41772C7.37975 9.07517 7.65745 8.79747 8 8.79747Z"
    />
  </Svg>
);
