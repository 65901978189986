export const getRandomItem = <DataType>(data: DataType[]): DataType => {
  const randomIndex = Math.floor(Math.random() * Math.floor(data.length));
  return data[randomIndex] as DataType;
};

export const toObject = <T extends { id: string }>(array: T[]): Record<string, T> => Object.fromEntries(array.map((entry) => [entry.id, entry]));

export const getArrayWithoutDuplicates = <T>(originalArray: T[]) => [...new Set(originalArray)];

type GetComaSeparatedValueParams = {
  array: string[];
};
export const getComaSeparatedValue = ({ array = [] }: GetComaSeparatedValueParams) => array.reduce(
  (acc, curr) => (acc ? `${acc}, ${curr}` : curr),
  '',
);

export const getPairSplitArray = <T>(initialArray: T[]): T[][] => initialArray.reduce<T[][]>((result, _value, index, array) => {
  if (index % 2 === 0) result.push(array.slice(index, index + 2));

  return result;
}, []);

export const last = <T>(array?: T[] | null) => (array ? array[array.length - 1] : undefined);

type Options<T> = {
  idKey?: keyof T;
};
export const reOrder = <T extends Record<string, unknown>>(
  arrayToReOrder: T[],
  newIdsOrder: string[],
  options?: Options<T>,
) => {
  const { idKey = 'id' } = options || {};

  const idIndexMap: Record<string, number> = arrayToReOrder.reduce(
    (map: Record<string, number>, object: T, index: number) => ({
      ...map,
      [object[idKey] as string]: index,
    }),
    {},
  );

  return newIdsOrder.reduce((newObjects: T[], id: string) => {
    const objectIndex = idIndexMap[id];
    if (objectIndex !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newObjects.push(arrayToReOrder[objectIndex]!);
    }
    return newObjects;
  }, []);
};
