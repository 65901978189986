import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const WhatsNewIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <g clipPath="url(#clip0_394_19198)">
      <path
        d="M8.45885 11.3L7.21911 14.6641C7.18242 14.763 7.11635 14.8482 7.02978 14.9084C6.94321 14.9687 6.84028 15.0009 6.73484 15.0009C6.62939 15.0009 6.52647 14.9687 6.4399 14.9084C6.35333 14.8482 6.28726 14.763 6.25056 14.6641L5.01082 11.3C4.98466 11.229 4.9434 11.1645 4.88989 11.111C4.83638 11.0575 4.7719 11.0163 4.70089 10.9901L1.3368 9.75036C1.23795 9.71367 1.15269 9.6476 1.09248 9.56103C1.03227 9.47446 1 9.37153 1 9.26609C1 9.16064 1.03227 9.05772 1.09248 8.97115C1.15269 8.88458 1.23795 8.81851 1.3368 8.78181L4.70089 7.54207C4.7719 7.51591 4.83638 7.47465 4.88989 7.42114C4.9434 7.36763 4.98466 7.30315 5.01082 7.23214L6.25056 3.86805C6.28726 3.7692 6.35333 3.68394 6.4399 3.62373C6.52647 3.56352 6.62939 3.53125 6.73484 3.53125C6.84028 3.53125 6.94321 3.56352 7.02978 3.62373C7.11635 3.68394 7.18242 3.7692 7.21911 3.86805L8.45885 7.23214C8.48501 7.30315 8.52627 7.36763 8.57978 7.42114C8.63329 7.47465 8.69778 7.51591 8.76878 7.54207L12.1329 8.78181C12.2317 8.81851 12.317 8.88458 12.3772 8.97115C12.4374 9.05772 12.4697 9.16064 12.4697 9.26609C12.4697 9.37153 12.4374 9.47446 12.3772 9.56103C12.317 9.6476 12.2317 9.71367 12.1329 9.75036L8.76878 10.9901C8.69778 11.0163 8.63329 11.0575 8.57978 11.111C8.52627 11.1645 8.48501 11.229 8.45885 11.3V11.3Z"
        stroke="currentcolor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8662 1V4.09935"
        stroke="currentcolor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4167 2.55078H9.31738"
        stroke="currentcolor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9668 4.61719V6.68342"
        stroke="currentcolor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9998 5.64844H12.9336"
        stroke="currentcolor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_394_19198">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>

);
