import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ListIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg
    ref={ref}
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3.40625C15 3.00014 14.8387 2.61066 14.5515 2.32349C14.2643 2.03633 13.8749 1.875 13.4688 1.875H2.53125C2.12514 1.875 1.73566 2.03633 1.44849 2.32349C1.16133 2.61066 1 3.00014 1 3.40625V5.59375C1 5.99986 1.16133 6.38934 1.44849 6.67651C1.73566 6.96367 2.12514 7.125 2.53125 7.125H13.4688C13.8749 7.125 14.2643 6.96367 14.5515 6.67651C14.8387 6.38934 15 5.99986 15 5.59375V3.40625ZM13.4688 3.1875C13.5268 3.1875 13.5824 3.21055 13.6234 3.25157C13.6645 3.29259 13.6875 3.34823 13.6875 3.40625V5.59375C13.6875 5.65177 13.6645 5.70741 13.6234 5.74843C13.5824 5.78945 13.5268 5.8125 13.4688 5.8125H2.53125C2.47323 5.8125 2.41759 5.78945 2.37657 5.74843C2.33555 5.70741 2.3125 5.65177 2.3125 5.59375V3.40625C2.3125 3.34823 2.33555 3.29259 2.37657 3.25157C2.41759 3.21055 2.47323 3.1875 2.53125 3.1875H13.4688ZM15 10.4062C15 10.0001 14.8387 9.61066 14.5515 9.32349C14.2643 9.03633 13.8749 8.875 13.4688 8.875H2.53125C2.12514 8.875 1.73566 9.03633 1.44849 9.32349C1.16133 9.61066 1 10.0001 1 10.4062V12.5938C1 12.9999 1.16133 13.3893 1.44849 13.6765C1.73566 13.9637 2.12514 14.125 2.53125 14.125H13.4688C13.8749 14.125 14.2643 13.9637 14.5515 13.6765C14.8387 13.3893 15 12.9999 15 12.5938V10.4062ZM13.4688 10.1875C13.5268 10.1875 13.5824 10.2105 13.6234 10.2516C13.6645 10.2926 13.6875 10.3482 13.6875 10.4062V12.5938C13.6875 12.6518 13.6645 12.7074 13.6234 12.7484C13.5824 12.7895 13.5268 12.8125 13.4688 12.8125H2.53125C2.47323 12.8125 2.41759 12.7895 2.37657 12.7484C2.33555 12.7074 2.3125 12.6518 2.3125 12.5938V10.4062C2.3125 10.3482 2.33555 10.2926 2.37657 10.2516C2.41759 10.2105 2.47323 10.1875 2.53125 10.1875H13.4688Z"
    />
  </Svg>
));
