import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const H1Icon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M1 12.929V3.07104H2.7858V7.24433H7.35379V3.07104H9.1444V12.929H7.35379V8.74132H2.7858V12.929H1Z"
      fill="#171717"
    />
    <path
      d="M15 3.07104V12.929H13.2142V4.80871H13.1564L10.8508 6.28164V4.64505L13.3008 3.07104H15Z"
      fill="#171717"
    />
  </Svg>
);
