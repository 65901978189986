import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const EyeIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.35808 7.93931C2.72581 7.38502 3.46665 6.37731 4.46989 5.5142C5.4785 4.64573 6.68525 3.98344 8 3.98344C9.31475 3.98344 10.5206 4.64573 11.5301 5.5142C12.5334 6.37731 13.2742 7.38502 13.6419 7.93931C13.6543 7.95712 13.661 7.9783 13.661 8C13.661 8.0217 13.6543 8.04289 13.6419 8.0607C13.2742 8.61498 12.5334 9.62269 11.5301 10.4858C10.5215 11.3543 9.31475 12.0166 8 12.0166C6.68525 12.0166 5.47939 11.3543 4.46989 10.4858C3.46575 9.62269 2.72492 8.61587 2.35808 8.0607C2.34567 8.04289 2.33903 8.0217 2.33903 8C2.33903 7.9783 2.34567 7.95712 2.35808 7.93931ZM8 2.64459C6.23182 2.64459 4.72427 3.53002 3.59696 4.49935C2.4634 5.47314 1.64491 6.59242 1.24326 7.19669C1.08465 7.43424 1 7.71347 1 7.99911C1 8.28475 1.08465 8.56397 1.24326 8.80153C1.64491 9.40669 2.4634 10.526 3.59696 11.5007C4.72338 12.47 6.23182 13.3554 8 13.3554C9.76818 13.3554 11.2757 12.47 12.403 11.5007C13.5366 10.5269 14.3551 9.40758 14.7567 8.80242C14.9153 8.56487 15 8.28564 15 8C15 7.71437 14.9153 7.43514 14.7567 7.19758C14.3551 6.59242 13.5366 5.47314 12.403 4.49935C11.2766 3.53002 9.76818 2.64459 8 2.64459ZM8 9.78514C8.47345 9.78514 8.9275 9.59706 9.26228 9.26228C9.59706 8.92751 9.78514 8.47345 9.78514 8C9.78514 7.52655 9.59706 7.0725 9.26228 6.73772C8.9275 6.40294 8.47345 6.21486 8 6.21486C7.52655 6.21486 7.0725 6.40294 6.73772 6.73772C6.40294 7.0725 6.21486 7.52655 6.21486 8C6.21486 8.47345 6.40294 8.92751 6.73772 9.26228C7.0725 9.59706 7.52655 9.78514 8 9.78514Z"
    />
  </Svg>
);
