import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GraphQLIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <g clipPath="url(#clip0_4556_23425)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.10449L13.9717 4.55224V11.4477L8 14.8955L2.02832 11.4477V4.55224L8 1.10449ZM2.69835 4.93909V10.004L7.08472 2.40662L2.69835 4.93909ZM8 2.16138L2.9436 10.9193H13.0564L8 2.16138ZM12.3864 11.5893H3.61363L8 14.1218L12.3864 11.5893ZM13.3016 10.004L8.91528 2.40662L13.3016 4.93909V10.004Z"
        fill="currentcolor"
      />
      <path
        d="M8.00001 2.90255C8.77939 2.90255 9.41121 2.27073 9.41121 1.49135C9.41121 0.711963 8.77939 0.0801468 8.00001 0.0801468C7.22062 0.0801468 6.58881 0.711963 6.58881 1.49135C6.58881 2.27073 7.22062 2.90255 8.00001 2.90255Z"
        fill="currentcolor"
      />
      <path
        d="M13.6367 6.15688C14.4161 6.15688 15.0479 5.52506 15.0479 4.74568C15.0479 3.9663 14.4161 3.33448 13.6367 3.33448C12.8573 3.33448 12.2255 3.9663 12.2255 4.74568C12.2255 5.52506 12.8573 6.15688 13.6367 6.15688Z"
        fill="currentcolor"
      />
      <path
        d="M13.6367 12.6655C14.4161 12.6655 15.0479 12.0337 15.0479 11.2543C15.0479 10.475 14.4161 9.84314 13.6367 9.84314C12.8573 9.84314 12.2255 10.475 12.2255 11.2543C12.2255 12.0337 12.8573 12.6655 13.6367 12.6655Z"
        fill="currentcolor"
      />
      <path
        d="M8.00001 15.9199C8.77939 15.9199 9.41121 15.288 9.41121 14.5087C9.41121 13.7293 8.77939 13.0975 8.00001 13.0975C7.22062 13.0975 6.58881 13.7293 6.58881 14.5087C6.58881 15.288 7.22062 15.9199 8.00001 15.9199Z"
        fill="currentcolor"
      />
      <path
        d="M2.36255 12.6655C3.14194 12.6655 3.77375 12.0337 3.77375 11.2543C3.77375 10.475 3.14194 9.84314 2.36255 9.84314C1.58317 9.84314 0.951355 10.475 0.951355 11.2543C0.951355 12.0337 1.58317 12.6655 2.36255 12.6655Z"
        fill="currentcolor"
      />
      <path
        d="M2.36255 6.15688C3.14194 6.15688 3.77375 5.52506 3.77375 4.74568C3.77375 3.9663 3.14194 3.33448 2.36255 3.33448C1.58317 3.33448 0.951355 3.9663 0.951355 4.74568C0.951355 5.52506 1.58317 6.15688 2.36255 6.15688Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4556_23425">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
