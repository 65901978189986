import { FC } from 'react';
import styled from 'styled-components';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

type Props = SvgProps & {
  rounded?: boolean;
};

export const CycleIcon: FC<React.PropsWithChildren<Props>> = ({
  size = 16, rounded = false, ...props
}) => (
  <Container
    $size={size}
    $rounded={rounded}
  >
    <Svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90 46.3007C89.6896 55.4667 86.5745 64.3778 80.9652 71.8082C74.3212 80.6163 64.6874 86.6582 53.9209 88.8657C53.1476 89.1911 52.3361 89.4243 51.5029 89.5599C42.686 90.7965 33.7002 89.4135 25.6675 85.5843C17.6403 81.7552 10.9255 75.6482 6.36723 68.0334C1.81445 60.4185 -0.385696 51.6322 0.0554227 42.7753C0.496541 33.9185 3.55169 25.3925 8.84511 18.2604C14.1331 11.1283 21.4197 5.71543 29.7901 2.69445C38.1604 -0.32653 47.2388 -0.820083 55.8869 1.27888C64.535 3.37783 72.3716 7.9771 78.4057 14.4963C84.4397 21.0156 88.4044 29.1619 89.8094 37.9157C89.9891 38.9137 90.0218 39.9225 89.9183 40.915C89.597 42.2764 88.9653 43.5672 88.0613 44.7116C86.3948 46.8051 83.9932 48.1448 81.2539 48.4919C79.8652 48.7034 78.4656 48.6763 77.0878 48.4105C75.7481 48.1502 74.4846 47.6512 73.3464 46.9299C72.2627 46.2411 71.3532 45.357 70.6344 44.294C69.9591 43.3177 69.5016 42.1896 69.311 41.0235C69.3001 40.9693 69.2892 40.915 69.2729 40.8608C69.2239 40.3727 69.213 39.8791 69.2348 39.3856C68.6357 36.869 67.65 34.4554 66.294 32.2372C64.5513 29.3952 62.2531 26.9274 59.5247 24.9911C56.8018 23.0603 53.7139 21.6936 50.4464 20.9831C47.1734 20.2726 43.7969 20.2237 40.5131 20.8529C34.5879 22.0136 29.2836 25.2786 25.6022 30.046C21.9153 34.808 20.0909 40.7469 20.4776 46.7455C20.7172 50.0864 21.6321 53.3406 23.1787 56.3182C24.7199 59.2904 26.8493 61.9209 29.447 64.0524C32.061 66.1676 35.0726 67.7405 38.3129 68.6679C40.9923 69.4327 43.7806 69.731 46.558 69.5628C47.1625 69.373 47.7779 69.232 48.4151 69.1561C54.8685 68.2178 60.6847 64.7737 64.6057 59.5833C67.4158 55.8627 69.0877 51.4532 69.4798 46.8865C70.2041 47.7109 71.0482 48.4322 71.9958 49.0397C73.4063 49.9346 74.9584 50.5475 76.6085 50.8675C77.5398 51.0464 78.4765 51.1332 79.4132 51.1332C80.1484 51.1332 80.8781 51.079 81.6024 50.9705C84.9462 50.5475 87.9306 48.8878 90 46.3007Z"
        fill="url(#paint0_linear_1053_40398)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1053_40398"
          x1="45"
          y1="90"
          x2="45"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentcolor" />
          <stop
            offset="1"
            stopColor="currentcolor"
          />
        </linearGradient>
      </defs>
    </Svg>
  </Container>
);

export const Container = styled.div<{
  $size: number;
  $rounded: boolean;
}>`
  border-radius: ${p => (p.$rounded ? '50%' : '4px')};
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.background.blue};
  color: white;
  position: relative;
  svg {
    max-width: 66%;
    max-height: 66%;
  }
`;
