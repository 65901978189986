import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DocumentEmptyIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33334 2V4.66667C9.33334 4.84348 9.40358 5.01305 9.52861 5.13807C9.65363 5.2631 9.8232 5.33333 10 5.33333H12.6667"
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 14H4.66668C4.31305 14 3.97392 13.8595 3.72387 13.6095C3.47382 13.3594 3.33334 13.0203 3.33334 12.6667V3.33333C3.33334 2.97971 3.47382 2.64057 3.72387 2.39052C3.97392 2.14048 4.31305 2 4.66668 2H9.33334L12.6667 5.33333V12.6667C12.6667 13.0203 12.5262 13.3594 12.2762 13.6095C12.0261 13.8595 11.687 14 11.3333 14Z"
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>

);
