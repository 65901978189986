import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const DiamondIcon: FC<SvgProps> = (props) => (
  <Svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.49997 5L7.49997 7L9.49997 9M12.7071 16.7929L21.7929 7.70711C22.1834 7.31658 22.1834 6.68342 21.7929 6.29289L16.7929 1.29289C16.6053 1.10536 16.351 1 16.0858 1H7.91418C7.64897 1 7.39461 1.10536 7.20708 1.29289L2.20708 6.29289C1.81655 6.68342 1.81655 7.31658 2.20708 7.70711L11.2929 16.7929C11.6834 17.1834 12.3166 17.1834 12.7071 16.7929Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
