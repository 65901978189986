import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SyncIcon: FC<SvgProps> = props => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M12.25 1.1665V4.6665H8.75"
      stroke="currentcolor"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 7.0001C1.75092 5.98704 2.04493 4.99587 2.59657 4.14617C3.1482 3.29646 3.93389 2.6245 4.85888 2.21134C5.78387 1.79818 6.80864 1.66146 7.80958 1.81768C8.81053 1.97391 9.74491 2.41639 10.5 3.09177L12.25 4.66677"
      stroke="currentcolor"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 12.8335V9.3335H5.25"
      stroke="currentcolor"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 7C12.2491 8.01307 11.9551 9.00423 11.4034 9.85394C10.8518 10.7036 10.0661 11.3756 9.14112 11.7888C8.21613 12.2019 7.19136 12.3386 6.19041 12.1824C5.18947 12.0262 4.25509 11.5837 3.5 10.9083L1.75 9.33333"
      stroke="currentcolor"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
