import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PlusBoxRoundedIcon = (props: SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66663 9.99999C1.66663 5.39762 5.39759 1.66666 9.99996 1.66666C14.6023 1.66666 18.3333 5.39762 18.3333 9.99999C18.3333 14.6024 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6024 1.66663 9.99999ZM13.3333 10.8332C13.7936 10.8332 14.1667 10.4601 14.1666 9.99988C14.1666 9.53964 13.7935 9.16656 13.3333 9.16657L10.8333 9.16664V6.66676C10.8333 6.20652 10.4602 5.83342 9.99996 5.83342C9.53972 5.83342 9.16663 6.20652 9.16663 6.66676V9.16669L6.6666 9.16676C6.20637 9.16677 5.83328 9.53988 5.83329 10.0001C5.83331 10.4604 6.20641 10.8334 6.66665 10.8334L9.16663 10.8334V13.3333C9.16663 13.7936 9.53972 14.1667 9.99996 14.1667C10.4602 14.1667 10.8333 13.7936 10.8333 13.3333V10.8333L13.3333 10.8332Z"
      fill="currentColor"
    />
  </Svg>
);
