import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TrashIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.56009 2.33365C6.56835 2.32539 6.57954 2.32075 6.59122 2.32075H9.40883C9.42051 2.32075 9.4317 2.32539 9.43996 2.33365C9.44822 2.34191 9.45286 2.3531 9.45286 2.36478V3.8176H6.54719V2.36478C6.54719 2.3531 6.55183 2.34191 6.56009 2.33365ZM5.88242 5.13835C5.88389 5.13836 5.88535 5.13837 5.88682 5.13837C5.88828 5.13837 5.88975 5.13836 5.89121 5.13835H10.1088C10.1103 5.13836 10.1118 5.13837 10.1132 5.13837C10.1147 5.13837 10.1162 5.13836 10.1176 5.13835H12.213L11.5682 12.876C11.5667 12.8942 11.566 12.9125 11.566 12.9308C11.566 13.1293 11.4871 13.3197 11.3468 13.46C11.2064 13.6004 11.016 13.6792 10.8175 13.6792H5.18231C4.98382 13.6792 4.79345 13.6004 4.65309 13.46C4.51274 13.3197 4.43389 13.1293 4.43389 12.9308C4.43389 12.9125 4.43312 12.8942 4.4316 12.876L3.7868 5.13835H5.88242ZM5.22644 3.8176V2.36478C5.22644 2.00282 5.37023 1.65568 5.62618 1.39973C5.88212 1.14379 6.22926 1 6.59122 1H9.40883C9.77079 1 10.1179 1.14379 10.3739 1.39973C10.6298 1.65568 10.7736 2.00282 10.7736 2.36478V3.8176H12.9236H12.9364H13.6352C13.9999 3.8176 14.2955 4.11326 14.2955 4.47797C14.2955 4.84269 13.9999 5.13835 13.6352 5.13835H13.5384L12.8865 12.9609C12.8787 13.4988 12.6616 14.013 12.2807 14.3939C11.8926 14.782 11.3663 15 10.8175 15H5.18231C4.63353 15 4.10723 14.782 3.71918 14.3939C3.33823 14.013 3.12116 13.4988 3.11335 12.9609L2.46147 5.13835H2.36472C2.00001 5.13835 1.70435 4.84269 1.70435 4.47797C1.70435 4.11326 2.00001 3.8176 2.36472 3.8176H3.06348H3.0762H5.22644ZM6.59116 6.63522C6.95588 6.63522 7.25154 6.93088 7.25154 7.2956V11.522C7.25154 11.8867 6.95588 12.1824 6.59116 12.1824C6.22645 12.1824 5.93079 11.8867 5.93079 11.522V7.2956C5.93079 6.93088 6.22645 6.63522 6.59116 6.63522ZM10.069 7.2956C10.069 6.93088 9.77339 6.63522 9.40867 6.63522C9.04395 6.63522 8.74829 6.93088 8.74829 7.2956V11.522C8.74829 11.8867 9.04395 12.1824 9.40867 12.1824C9.77339 12.1824 10.069 11.8867 10.069 11.522V7.2956Z"
    />
  </Svg>
);
