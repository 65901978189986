import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CalendarTimeIcon: FC<SvgProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    data-no-fill
    {...props}
  >
    <path
      d="M5.8125 3.5625V2.0625M12.1875 3.5625V2.0625M12.75 11.0625V12.7497L14.0625 14.0625M6.5625 15.1875H4.3125C3.48407 15.1875 2.8125 14.5159 2.8125 13.6875V5.0625C2.8125 4.23407 3.48407 3.5625 4.3125 3.5625H13.6875C14.5159 3.5625 15.1875 4.23407 15.1875 5.0625V6.5625M11.2438 9.11319C13.2525 8.2811 15.5547 9.23525 16.3868 11.244C17.2189 13.2527 16.2647 15.5547 14.256 16.3868C12.2472 17.2189 9.94505 16.2647 9.11293 14.256C8.28146 12.248 9.23499 9.94529 11.2438 9.11319Z"
      stroke="currentcolor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
