import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CameraIcon = (props: SvgProps) => (
  <Svg
    width="34"
    height="22"
    viewBox="0 0 34 22"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.13218 21.875C2.31809 21.875 0.6875 20.3554 0.6875 17.5673V4.49879C0.6875 1.72388 2.41014 0.125 5.13218 0.125H19.0974C21.9115 0.125 23.4369 1.72388 23.4369 4.49879V17.5012C23.4369 20.2761 21.8063 21.875 18.9922 21.875H5.13218ZM25.3568 15.2152V6.83764L29.8804 2.92634C30.4195 2.45064 30.985 2.15993 31.5241 2.15993C32.5893 2.15993 33.3125 2.92634 33.3125 4.07594V17.9769C33.3125 19.1265 32.5893 19.8929 31.5241 19.8929C30.985 19.8929 30.4195 19.6022 29.8804 19.1397L25.3568 15.2152Z"
      fill="url(#paint0_linear_171_17326)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_171_17326"
        x1="4.3125"
        y1="-1.69631"
        x2="22.5684"
        y2="29.1886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A990FF" />
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        />
        <stop
          offset="1"
          stopColor="#7FEA96"
        />
      </linearGradient>
    </defs>
  </Svg>
);
