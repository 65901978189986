import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const WarningIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8227 12.3969L9.29655 2.20882C9.16026 1.95606 8.95242 1.74913 8.69906 1.61395C8.35484 1.42974 7.95181 1.3889 7.57763 1.5003C7.20346 1.6117 6.8884 1.86634 6.70099 2.20882L1.17569 12.3969C1.05487 12.6211 0.994462 12.8728 1.0004 13.1274C1.00634 13.3821 1.07841 13.6307 1.20955 13.8491C1.34069 14.0674 1.52637 14.2478 1.74835 14.3727C1.97033 14.4975 2.22098 14.5625 2.47566 14.5611H13.5228C13.7669 14.5611 14.0072 14.5011 14.2226 14.3862C14.3933 14.2956 14.5443 14.1721 14.667 14.0229C14.7897 13.8737 14.8816 13.7016 14.9375 13.5167C14.9933 13.3318 15.012 13.1376 14.9925 12.9454C14.973 12.7532 14.9156 12.5668 14.8236 12.3969H14.8227ZM7.99921 12.8115C7.82619 12.8115 7.65705 12.7602 7.51319 12.6641C7.36933 12.568 7.2572 12.4313 7.19099 12.2715C7.12478 12.1116 7.10745 11.9357 7.14121 11.766C7.17496 11.5963 7.25828 11.4405 7.38062 11.3181C7.50297 11.1958 7.65884 11.1125 7.82854 11.0787C7.99824 11.045 8.17413 11.0623 8.33398 11.1285C8.49383 11.1947 8.63046 11.3068 8.72659 11.4507C8.82271 11.5946 8.87402 11.7637 8.87402 11.9367C8.87402 12.1687 8.78185 12.3912 8.61779 12.5553C8.45373 12.7194 8.23122 12.8115 7.99921 12.8115ZM8.87402 9.74969C8.87402 9.86569 8.82793 9.97695 8.7459 10.059C8.66388 10.141 8.55262 10.1871 8.43661 10.1871H7.5618C7.4458 10.1871 7.33454 10.141 7.25251 10.059C7.17048 9.97695 7.1244 9.86569 7.1244 9.74969V6.25044C7.1244 6.13444 7.17048 6.02318 7.25251 5.94115C7.33454 5.85912 7.4458 5.81304 7.5618 5.81304H8.43661C8.55262 5.81304 8.66388 5.85912 8.7459 5.94115C8.82793 6.02318 8.87402 6.13444 8.87402 6.25044V9.74969Z" />
  </Svg>
);
