import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const NextArrowIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22 2.97001C8.36063 2.82956 8.55125 2.75067 8.75 2.75067C8.94875 2.75067 9.13937 2.82956 9.28 2.97001L13.53 7.22001C13.6705 7.36064 13.7493 7.55126 13.7493 7.75001C13.7493 7.94876 13.6705 8.13939 13.53 8.28001L9.28 12.53C9.13783 12.6625 8.94978 12.7346 8.75548 12.7312C8.56118 12.7278 8.37579 12.649 8.23838 12.5116C8.10097 12.3742 8.02225 12.1888 8.01882 11.9945C8.0154 11.8002 8.08752 11.6122 8.22 11.47L11.19 8.50001H3.75C3.55109 8.50001 3.36032 8.42099 3.21967 8.28034C3.07902 8.13969 3 7.94892 3 7.75001C3 7.5511 3.07902 7.36033 3.21967 7.21968C3.36032 7.07903 3.55109 7.00001 3.75 7.00001H11.19L8.22 4.03001C8.07955 3.88939 8.00066 3.69876 8.00066 3.50001C8.00066 3.30126 8.07955 3.11064 8.22 2.97001Z"
      fill="#171717"
    />
  </Svg>
);
