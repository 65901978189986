import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const Hierarchy2Icon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6.5 8C7.88071 8 9 6.88071 9 5.5C9 4.11929 7.88071 3 6.5 3C5.11929 3 4 4.11929 4 5.5C4 6.88071 5.11929 8 6.5 8ZM6.5 8V10C6.5 11.1046 7.39543 12 8.5 12H10C11.1046 12 12 12.8954 12 14M12 14C12 12.8954 12.8954 12 14 12H15.5C16.6046 12 17.5 11.1046 17.5 10V8M12 14V16M17.5 8C18.8807 8 20 6.88071 20 5.5C20 4.11929 18.8807 3 17.5 3C16.1193 3 15 4.11929 15 5.5C15 6.88071 16.1193 8 17.5 8ZM12 16C10.6193 16 9.5 17.1193 9.5 18.5C9.5 19.8807 10.6193 21 12 21C13.3807 21 14.5 19.8807 14.5 18.5C14.5 17.1193 13.3807 16 12 16Z"
      strokeWidth="1.8"
    />
  </Svg>
);
