import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const NotebookIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M6 2.33301H5C3.89543 2.33301 3 3.22844 3 4.33301V11.6663C3 12.7709 3.89543 13.6663 5 13.6663H6M6 2.33301H11C12.1046 2.33301 13 3.22844 13 4.33301V11.6663C13 12.7709 12.1046 13.6663 11 13.6663H6M6 2.33301V13.6663M8.66667 5.33301H10.3333M8.66667 7.99967H10.3333"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
