export const Gradient = () => (
  <svg
    width="0"
    height="0"
    style={{ position: 'absolute' }}
  >
    <defs>
      <linearGradient
        id="gradient"
        x1="0.666626"
        y1="8.73332"
        x2="18.359"
        y2="8.85752"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A990FF" />
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        />
        <stop
          offset="1"
          stopColor="#7FEA96"
        />
      </linearGradient>
      <linearGradient
        id="gradient-animated"
        x1="0.666626"
        y1="8.73332"
        x2="18.359"
        y2="8.85752"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          stopColor="#A990FF"
        >
          <animate
            dur="2s"
            attributeName="stop-color"
            values="#A990FF;#57CFEF;#7FEA96;#A990FF"
            repeatCount="indefinite"
          >
          </animate>
        </stop>
        <stop
          offset="0.523937"
          stopColor="#57CFEF"
        >
          <animate
            dur="2s"
            attributeName="stop-color"
            values="#57CFEF;#7FEA96;#A990FF;#57CFEF"
            repeatCount="indefinite"
          >
          </animate>
        </stop>
        <stop
          offset="1"
          stopColor="#7FEA96"
        >
          <animate
            dur="2s"
            attributeName="stop-color"
            values="#7FEA96;#A990FF;#57CFEF;#7FEA96"
            repeatCount="indefinite"
          >
          </animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
);
