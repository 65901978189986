import { AllHTMLAttributes } from 'react';

import logo from './yc.png';

type Props = AllHTMLAttributes<HTMLImageElement> & { className?: string };

export const YCIcon = (props: Props) => (
  <img
    alt=""
    src={logo}
    width={16}
    height={16}
    {...props}
  />
);
