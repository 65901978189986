import { useCallback, useEffect, useMemo } from 'react';

export type UseHotkeyParams = {
  separator?: string;
  enabled?: () => boolean;
  preventCallback?: () => boolean;
  capture?: boolean;
};

export type HandlerHotkey = { key: string };

export type HotkeyCallback = (event: KeyboardEvent, handler: HandlerHotkey) => void;

export type UseHotkey = (keys: string, callback: HotkeyCallback, options?: UseHotkeyParams, deps?: any[]) => void;

const letters = 'abcdefghijklmnopqrstuvwxyz';
const numbers = '1234567890';
// For AZERTY keyboards
const numbersMeta = '&é“\'(§è!çà';
const allowedKeys = [
  ...`${letters}${numbers}${numbersMeta}`.split(''),
  'space',
  'enter',
  'escape',
  'tab',
  'arrowdown',
  'arrowup',
  'arrowright',
  'arrowleft',
];

const notAllowedCombinaisons = [
  'a',
  'c',
  'v',
  'x',
  'z',
  'u',
  'i',
];

const notAllowedOnInput = [
  ...notAllowedCombinaisons.map((letter) => ([
    `command+${letter}`,
    `control+${letter}`,
  ])).flat(),
];

export const useHotkeys: UseHotkey = (
  keys,
  callback,
  opts = {},
  deps = [],
) => {
  const {
    enabled = () => true,
    separator = ',',
    preventCallback,
    capture = false,
  } = opts;

  const keysArray = useMemo(() => keys.split(separator), [keys, separator]);

  const listener = useCallback((e: KeyboardEvent | Event) => {
    if (!enabled()) return;
    /**
     * This event can be trigger when a browser is showing input suggestions
     * ans the user clicks on it.
     * The `keydown` event listener is triggered but the event doesn't contain
     * a `key` property.
     */
    if (!(e instanceof KeyboardEvent)) return;

    const key = e.key.toLocaleLowerCase().replace(' ', 'space');
    if (!allowedKeys.includes(key)) {
      return;
    }

    const keyPressed = getKeyWithModifier(e);

    if (isInputTarget(e) && (keyPressed.length === 1 || notAllowedOnInput.includes(keyPressed))) {
      return;
    }

    if (preventCallback?.()) {
      return;
    }
    if (keysArray.includes(keyPressed)) {
      callback(e, { key: keyPressed });
    }
  }, [preventCallback, callback, keysArray, enabled, ...deps]);

  useEffect(
    () => {
      if (keys) {
        window.addEventListener('keydown', listener, { capture });
      } else {
        window.removeEventListener('keydown', listener, { capture });
      }
      return () => {
        window.removeEventListener('keydown', listener, { capture });
      };
    },
    [keys, listener],
  );
};

function getKeyWithModifier(e: KeyboardEvent): string {
  const keyPressed = e.key.toLocaleLowerCase();

  if (e.ctrlKey && e.shiftKey) {
    return `control+shift+${keyPressed}`;
  }
  if (e.shiftKey) {
    return `shift+${keyPressed}`;
  }
  if (e.metaKey) {
    return `command+${keyPressed}`;
  }
  if (e.ctrlKey) {
    return `control+${keyPressed}`;
  }
  if (e.altKey) {
    return `alt+${keyPressed}`;
  }
  return keyPressed.replace(' ', 'space');
}

export function isInputTarget(event: KeyboardEvent) {
  if (!event.target || !(event.target instanceof HTMLElement)) {
    return false;
  }

  const {
    tagName,
    isContentEditable,
  } = event.target;

  return isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA';
}
