import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const BulletListIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6344 3.0835C3.6344 3.81078 3.04479 4.40039 2.3175 4.40039C1.59022 4.40039 1.00061 3.81078 1.00061 3.0835C1.00061 2.35621 1.59022 1.7666 2.3175 1.7666C3.04479 1.7666 3.6344 2.35621 3.6344 3.0835ZM6.91841 2.23486C6.53051 2.23486 6.21606 2.54931 6.21606 2.93721C6.21606 3.3251 6.53051 3.63955 6.91841 3.63955H14.293C14.6809 3.63955 14.9954 3.3251 14.9954 2.93721C14.9954 2.54931 14.6809 2.23486 14.293 2.23486H6.91841ZM6.91841 7.15131C6.53051 7.15131 6.21606 7.46576 6.21606 7.85365C6.21606 8.24154 6.53051 8.55599 6.91841 8.55599H14.293C14.6809 8.55599 14.9954 8.24154 14.9954 7.85365C14.9954 7.46576 14.6809 7.15131 14.293 7.15131H6.91841ZM6.21606 12.7698C6.21606 12.382 6.53051 12.0675 6.91841 12.0675H14.2977C14.6856 12.0675 15 12.382 15 12.7698C15 13.1577 14.6856 13.4722 14.2977 13.4722H6.91841C6.53051 13.4722 6.21606 13.1577 6.21606 12.7698ZM2.31689 9.55096C3.04418 9.55096 3.63379 8.96135 3.63379 8.23407C3.63379 7.50678 3.04418 6.91718 2.31689 6.91718C1.58961 6.91718 1 7.50678 1 8.23407C1 8.96135 1.58961 9.55096 2.31689 9.55096ZM3.6344 12.9164C3.6344 13.6437 3.04479 14.2333 2.3175 14.2333C1.59022 14.2333 1.00061 13.6437 1.00061 12.9164C1.00061 12.1892 1.59022 11.5995 2.3175 11.5995C3.04479 11.5995 3.6344 12.1892 3.6344 12.9164Z"
      fill="#171717"
    />
  </Svg>
);
