import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const OutlookIcon: FC<SvgProps> = (props) => (
  <Svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2636_13150)">
      <path
        d="M14 7.32546C14.0008 7.21608 13.9442 7.11409 13.851 7.05694H13.8493L13.8436 7.05366L8.99227 4.18202C8.97122 4.1678 8.94962 4.15495 8.92719 4.14319C8.73989 4.04667 8.51731 4.04667 8.33001 4.14319C8.30758 4.15495 8.28571 4.1678 8.26493 4.18202L3.4136 7.05339L3.40786 7.05667C3.25965 7.14882 3.21426 7.34378 3.30641 7.49198C3.33348 7.53573 3.37094 7.5721 3.41551 7.59808L8.26657 10.4697C8.28762 10.4837 8.30923 10.4968 8.33165 10.5085C8.51895 10.6051 8.74153 10.6051 8.92883 10.5085C8.95126 10.4968 8.97286 10.4839 8.99391 10.4697L13.845 7.59808C13.942 7.54175 14.0011 7.43757 14 7.32546Z"
        fill="#0A2767"
      />
      <path
        d="M3.97933 5.40824H7.16296V8.32637H3.97933V5.40824ZM13.3489 2.44172V1.1068C13.3566 0.77293 13.0925 0.496211 12.7586 0.488281H4.49722C4.16335 0.496211 3.89921 0.773203 3.90687 1.1068V2.44172L8.79046 3.7441L13.3489 2.44172Z"
        fill="#0364B8"
      />
      <path
        d="M3.90688 2.44189H7.1627V5.37205H3.90688V2.44189Z"
        fill="#0078D4"
      />
      <path
        d="M10.4185 2.44189H7.16269V5.37205L10.4185 8.30221H13.3487V5.37232L10.4185 2.44189Z"
        fill="#28A8EA"
      />
      <path
        d="M7.16269 5.37207H10.4185V8.30223H7.16269V5.37207Z"
        fill="#0078D4"
      />
      <path
        d="M7.16269 8.30225H10.4185V11.2324H7.16269V8.30225Z"
        fill="#0364B8"
      />
      <path
        d="M3.97934 8.32666H7.16297V10.9796H3.97934V8.32666Z"
        fill="#14447D"
      />
      <path
        d="M10.4185 8.30225H13.3487V11.2324H10.4185V8.30225Z"
        fill="#0078D4"
      />
      <path
        d="M13.851 7.58031L13.8447 7.58359L8.99363 10.312C8.97257 10.3251 8.95097 10.3374 8.92855 10.3483C8.84624 10.3874 8.75656 10.4098 8.6655 10.4134L8.40054 10.2584C8.37812 10.2471 8.35652 10.2346 8.33546 10.2209L3.41906 7.41516H3.41687L3.25609 7.3252V12.8486C3.25855 13.2172 3.55933 13.5139 3.92765 13.5114H13.3388C13.3443 13.5114 13.3492 13.5087 13.3552 13.5087C13.4332 13.5038 13.5097 13.4877 13.583 13.4611C13.6147 13.4477 13.6453 13.4319 13.6746 13.4136C13.6965 13.4012 13.7339 13.3742 13.7339 13.3742C13.9007 13.2509 13.9994 13.0559 14.0003 12.8484V7.32547C14 7.43102 13.9428 7.52836 13.851 7.58031Z"
        fill="url(#paint0_linear_2636_13150)"
      />
      <path
        opacity="0.5"
        d="M13.7394 7.304V7.64252L8.66688 11.1351L3.41524 7.41803C3.41524 7.41611 3.41387 7.41475 3.41196 7.41475L2.93016 7.1249V6.88072L3.12868 6.87744L3.54868 7.11834L3.55852 7.12162L3.59434 7.14432C3.59434 7.14432 8.53016 9.96072 8.54329 9.96701L8.73223 10.0778C8.74864 10.0712 8.76477 10.0646 8.78446 10.0583C8.7943 10.0518 13.6845 7.30072 13.6845 7.30072L13.7394 7.304Z"
        fill="#0A2767"
      />
      <path
        d="M13.851 7.58053L13.8447 7.58408L8.99363 10.3124C8.97257 10.3256 8.95097 10.3379 8.92855 10.3488C8.74015 10.441 8.51976 10.441 8.33136 10.3488C8.30894 10.3379 8.28734 10.3256 8.26628 10.3124L3.41523 7.58408L3.40949 7.58053C3.3157 7.52967 3.25718 7.43205 3.25609 7.32568V12.8488C3.25855 13.2172 3.55906 13.5141 3.92738 13.5117H13.329C13.6973 13.5141 13.9981 13.2172 14.0003 12.8488V7.32568C14 7.43123 13.9428 7.52857 13.851 7.58053Z"
        fill="#1490DF"
      />
      <path
        opacity="0.1"
        d="M9.06418 10.2725L8.99144 10.3132C8.97039 10.3266 8.94879 10.3389 8.92636 10.3501C8.84625 10.3895 8.75929 10.4125 8.67043 10.4179L10.5161 12.6005L13.7359 13.3762C13.8242 13.3095 13.8942 13.222 13.9401 13.1214L9.06418 10.2725Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M9.39312 10.0869L8.99172 10.3125C8.97066 10.3259 8.94906 10.3382 8.92664 10.3494C8.84652 10.3888 8.75957 10.4118 8.6707 10.4172L9.53531 12.8013L13.7369 13.3747C13.9024 13.2506 14 13.0556 14 12.8486V12.7773L9.39312 10.0869Z"
        fill="black"
      />
      <path
        d="M3.9364 13.5117H13.3279C13.4725 13.5125 13.6134 13.4668 13.7298 13.3815L8.39999 10.2594C8.37757 10.2482 8.35597 10.2356 8.33492 10.2219L3.41851 7.41619H3.41632L3.25581 7.32568V12.83C3.25554 13.206 3.56015 13.5114 3.9364 13.5117Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.1"
        d="M7.81402 3.6899V10.6347C7.81347 10.878 7.66527 11.0971 7.43968 11.1881C7.36968 11.2182 7.29449 11.2338 7.2182 11.2338H3.25581V3.41893H3.90687V3.09326H7.21792C7.54714 3.09436 7.8132 3.36096 7.81402 3.6899Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M7.48835 4.01539V10.9602C7.48917 11.0389 7.47249 11.1166 7.43941 11.1879C7.3489 11.4108 7.13288 11.5571 6.89253 11.5582H3.25581V3.41848H6.89253C6.98687 3.41766 7.08011 3.44117 7.16269 3.48684C7.3623 3.58746 7.48835 3.79199 7.48835 4.01539Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M7.48835 4.01539V10.3088C7.48671 10.6375 7.2212 10.9041 6.89253 10.9068H3.25581V3.41848H6.89253C6.98687 3.41766 7.08011 3.44117 7.16269 3.48684C7.3623 3.58746 7.48835 3.79199 7.48835 4.01539Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M7.16269 4.01537V10.3088C7.16242 10.638 6.89609 10.9052 6.56687 10.9068H3.25581V3.41846H6.56687C6.89609 3.41873 7.16296 3.68561 7.16269 4.01482C7.16269 4.0151 7.16269 4.0151 7.16269 4.01537Z"
        fill="black"
      />
      <path
        d="M0.596914 3.41846H6.56605C6.89555 3.41846 7.16297 3.68561 7.16297 4.01537V9.98451C7.16297 10.314 6.89582 10.5814 6.56605 10.5814H0.596914C0.267148 10.5814 0 10.314 0 9.98451V4.01537C0 3.68588 0.267148 3.41846 0.596914 3.41846Z"
        fill="url(#paint1_linear_2636_13150)"
      />
      <path
        d="M1.86538 5.92142C2.01249 5.60806 2.24984 5.34584 2.54706 5.16838C2.87628 4.97998 3.25117 4.88591 3.63015 4.89685C3.98152 4.8892 4.32824 4.97834 4.63257 5.15443C4.91859 5.32506 5.1491 5.57498 5.29566 5.87384C5.45562 6.20334 5.53519 6.56591 5.52808 6.93205C5.53574 7.31459 5.45398 7.69384 5.28882 8.03892C5.1387 8.34873 4.90081 8.6074 4.60523 8.78349C4.28913 8.96506 3.92956 9.05638 3.56535 9.04791C3.20632 9.05666 2.85195 8.96642 2.54078 8.78732C2.2523 8.61642 2.01906 8.36623 1.86867 8.06654C1.70788 7.74197 1.62722 7.38322 1.63351 7.02119C1.62695 6.64193 1.70624 6.26595 1.86538 5.92142ZM2.59191 7.68947C2.67038 7.88771 2.80328 8.05943 2.97554 8.18494C3.15081 8.30744 3.36081 8.3706 3.57464 8.36541C3.80242 8.37443 4.02691 8.30908 4.21421 8.1792C4.38429 8.05396 4.51363 7.8817 4.58663 7.68373C4.66839 7.46252 4.70859 7.22818 4.70558 6.99248C4.70804 6.75459 4.67031 6.51779 4.59347 6.29248C4.52566 6.08904 4.40042 5.90966 4.23281 5.77623C4.05015 5.64033 3.82648 5.57115 3.59898 5.58099C3.3805 5.57525 3.16585 5.63896 2.98593 5.7631C2.81093 5.88916 2.67531 6.06252 2.59519 6.26295C2.41745 6.72205 2.41636 7.23091 2.59245 7.69056L2.59191 7.68947Z"
        fill="white"
      />
      <path
        d="M10.4185 2.44189H13.3487V5.37205H10.4185V2.44189Z"
        fill="#50D9FF"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2636_13150"
        x1="8.62789"
        y1="7.32539"
        x2="8.62789"
        y2="13.5114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35B8F1" />
        <stop
          offset="1"
          stopColor="#28A8EA"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2636_13150"
        x1="1.24432"
        y1="2.95223"
        x2="5.91847"
        y2="11.0477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop
          offset="0.5"
          stopColor="#107AD5"
        />
        <stop
          offset="1"
          stopColor="#0A63C9"
        />
      </linearGradient>
      <clipPath id="clip0_2636_13150">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
