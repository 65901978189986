import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const JuneIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M17.8804 10.1823C18.243 12.2873 17.103 13.7613 14.9771 14.145C13.3529 14.4381 11.9873 13.9409 11.3451 12.7768C11.2677 12.6368 11.2526 12.4696 11.3034 12.3173C11.354 12.1651 11.4657 12.0416 11.6106 11.9791L12.5613 11.5692C12.7875 11.4716 13.0489 11.539 13.2033 11.7343C13.4874 12.0934 13.9488 12.294 14.5558 12.1845C15.4224 12.028 15.893 11.37 15.7361 10.4594L15.1615 7.12441L11.8334 7.72507C11.5337 7.77915 11.2479 7.57432 11.1951 7.2676L11.0584 6.47451C11.0056 6.16777 11.2057 5.87526 11.5054 5.82118L16.4168 4.93479C16.7165 4.88071 17.0023 5.08552 17.0551 5.39226L17.8804 10.1823ZM22.0448 3.47557C21.6508 1.18846 19.5199 -0.338692 17.2854 0.0645873L8.44162 1.66066C7.36855 1.85431 6.4146 2.47636 5.78961 3.38991L2.40965 8.33055C1.78466 9.24412 1.53985 10.3744 1.72906 11.4727L3.28845 20.5244C3.68246 22.8115 5.81333 24.3387 8.04789 23.9354L16.8916 22.3393C17.9647 22.1457 18.9187 21.5236 19.5437 20.6101L22.9236 15.6694C23.5486 14.7559 23.7934 13.6256 23.6042 12.5273L22.0448 3.47557ZM21.9959 12.8782C22.2323 14.2505 21.3371 15.5591 19.9964 15.8011L11.0951 17.4076C9.75437 17.6495 8.47586 16.7332 8.23945 15.361L6.66991 6.25035C6.43351 4.87807 7.32875 3.56947 8.66947 3.32752L17.5708 1.72106C18.9114 1.4791 20.19 2.39539 20.4264 3.76765L21.9959 12.8782Z"
      fill="#6968F4"
    />
  </Svg>
);
