import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const RightIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.22001 3.22001C5.36064 3.07956 5.55126 3.00067 5.75001 3.00067C5.94876 3.00067 6.13939 3.07956 6.28001 3.22001L10.53 7.47001C10.6705 7.61064 10.7494 7.80126 10.7494 8.00001C10.7494 8.19876 10.6705 8.38939 10.53 8.53001L6.28001 12.78C6.13784 12.9125 5.94979 12.9846 5.75549 12.9812C5.56119 12.9778 5.3758 12.899 5.23839 12.7616C5.10098 12.6242 5.02226 12.4388 5.01884 12.2445C5.01541 12.0502 5.08753 11.8622 5.22001 11.72L8.94001 8.00001L5.22001 4.28001C5.07956 4.13939 5.00067 3.94876 5.00067 3.75001C5.00067 3.55126 5.07956 3.36064 5.22001 3.22001Z"
      fill="currentcolor"
    />
  </Svg>
);
