import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CalendarEditIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M8 18H4C2.34315 18 1 16.6569 1 15V7M1 7V6C1 4.34315 2.34315 3 4 3H14C15.6569 3 17 4.34315 17 6V7M1 7H17M17 7V8M5 3V1M13 3V1M12 19H12.7574C13.553 19 14.3161 18.6839 14.8787 18.1213L18 15C18.5523 14.4477 18.5523 13.5523 18 13C17.4477 12.4477 16.5523 12.4477 16 13L12.8787 16.1213C12.3161 16.6839 12 17.447 12 18.2426V19Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
