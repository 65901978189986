import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const G2Icon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="50 50 552 552"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M571.4 321.4c0 138.1-111.9 250-250 250s-250-111.9-250-250 111.9-250 250-250 250 112 250 250"
      fill="#ff492c"
    />
    <path
      d="M429.6 280.6H365v-3c0-11 2.2-20.1 6.6-27.2 4.4-7.2 12-13.5 23-19.1l5-2.5c8.9-4.5 11.2-8.4 11.2-13 0-5.5-4.8-9.5-12.5-9.5-9.2 0-16.1 4.8-20.9 14.5L365 208.4c2.7-5.8 7.1-10.4 12.9-14.1 5.9-3.7 12.4-5.5 19.5-5.5 8.9 0 16.6 2.3 22.9 7.1 6.5 4.8 9.7 11.4 9.7 19.7 0 13.3-7.5 21.4-21.5 28.6l-7.9 4c-8.4 4.2-12.5 8-13.7 14.7h42.7v17.7zm-5.7 20.4h-70.7l-35.3 61.2h70.7l35.4 61.2 35.3-61.2-35.4-61.2zM324 403.1c-45 0-81.6-36.6-81.6-81.6 0-45 36.6-81.6 81.6-81.6l28-58.5c-9.1-1.8-18.4-2.7-28-2.7-78.9 0-142.9 64-142.9 142.8 0 78.9 63.9 142.9 142.9 142.9 31.4 0 60.5-10.2 84.1-27.4l-31-53.6c-14.2 12.2-32.8 19.7-53.1 19.7"
      fill="#fff"
    />
  </Svg>
);
