import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FathomIcon = (props: SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      d="M12.3713 9.27328L1.99366 3.58695C1.03526 3.02633 0.716059 1.90508 1.27526 0.944009C1.83366 0.0630318 3.03126 -0.257325 3.98966 0.22321L14.3673 5.90954C15.3257 6.47016 15.6449 7.59141 15.0857 8.55248C14.6073 9.51352 13.3297 9.83392 12.3713 9.27328ZM7.58166 12.7171L2.07366 9.67376C1.11526 9.11312 0.796058 7.99186 1.35446 7.03079C1.91366 6.1498 3.11126 5.82945 4.06886 6.30999L9.57767 9.35336C10.5353 9.914 10.8545 11.0352 10.2961 11.9963C9.73687 12.8773 8.54006 13.1977 7.58166 12.7171Z"
      fill="#00BEFF"
    />
    <path
      opacity="0.5"
      d="M1.03638 13.9981V8.07158C1.03638 6.95032 1.91397 6.06934 3.03157 6.06934C4.14997 6.06934 5.02758 6.95032 5.02758 8.07158V13.9981C5.02758 15.1194 4.14997 16.0004 3.03157 16.0004C1.91397 16.0004 1.03638 15.1194 1.03638 13.9981Z"
      fill="#00BEFF"
    />
  </Svg>
);
