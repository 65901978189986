import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CommentChatIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 32 32"
    fill="none"
    data-no-fill
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M22.6666 18.6668H25.3362C26.8089 18.6668 28.0028 17.4729 28.0028 16.0002V8.00016C28.0028 6.5274 26.8089 5.3335 25.3362 5.3335H12.0028C10.5301 5.3335 9.33618 6.5274 9.33618 8.00016"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6696 10.6665H20.0029C21.4757 10.6665 22.6696 11.8604 22.6696 13.3332V21.3332C22.6696 22.8059 21.4757 23.9998 20.0029 23.9998H14.0029L8.00293 27.3332V23.9998H6.6696C5.19684 23.9998 4.00293 22.8059 4.00293 21.3332V13.3332C4.00293 11.8604 5.19684 10.6665 6.6696 10.6665Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
