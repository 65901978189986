import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiExtendIcon = (props:SvgProps) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="signature, pencil, pencil, pen, write, draw">
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21968 1.66337C9.69122 1.19184 10.4557 1.19184 10.9273 1.66337L11.902 2.63815C12.3736 3.10968 12.3736 3.87419 11.902 4.34572L3.911 12.3368C3.68456 12.5632 3.37744 12.6904 3.05721 12.6904H1.46774C1.14038 12.6904 0.875 12.425 0.875 12.0977V10.5082C0.875 10.188 1.00221 9.88084 1.22865 9.65441L9.21968 1.66337ZM10.089 2.50164C10.0804 2.49306 10.0665 2.49306 10.0579 2.50164L2.06691 10.4927C2.0628 10.4968 2.06048 10.5024 2.06048 10.5082V11.5049H3.05721C3.06303 11.5049 3.06862 11.5026 3.07273 11.4985L11.0638 3.50746C11.0723 3.49889 11.0723 3.48499 11.0638 3.47641L10.089 2.50164L10.5081 2.0825L10.089 2.50164ZM12.0839 10.4806C12.2979 10.2331 12.672 10.2058 12.9197 10.4197C13.1674 10.6337 13.1948 11.008 12.9808 11.2557L12.5322 10.8683C12.9808 11.2557 12.9807 11.2559 12.9806 11.256L12.9804 11.2563L12.9798 11.2569L12.9785 11.2584L12.975 11.2624L12.9642 11.2745C12.9555 11.2843 12.9435 11.2974 12.9286 11.3135C12.8987 11.3455 12.8565 11.3893 12.8035 11.441C12.6981 11.5438 12.5464 11.6809 12.3599 11.8192C12.0042 12.0828 11.4417 12.4103 10.7902 12.4103C10.1777 12.4103 9.64599 12.1276 9.26098 11.9228C9.24765 11.9157 9.2345 11.9087 9.22153 11.9019C8.77799 11.6664 8.49979 11.5317 8.22115 11.5317C7.56266 11.5317 7.14206 11.8798 6.81612 12.2247C6.59128 12.4626 6.21613 12.4733 5.97819 12.2484C5.74026 12.0236 5.72964 11.6484 5.95448 11.4105C6.35476 10.9869 7.06902 10.3462 8.22115 10.3462C8.82208 10.3462 9.34821 10.6263 9.72916 10.8291C9.74548 10.8378 9.76152 10.8463 9.7773 10.8547C10.2173 11.0882 10.5019 11.2249 10.7902 11.2249C11.0608 11.2249 11.3694 11.0777 11.6541 10.8667C11.7878 10.7676 11.8988 10.6674 11.9759 10.5922C12.0142 10.5549 12.0433 10.5246 12.0618 10.5047C12.0711 10.4948 12.0777 10.4875 12.0814 10.4834L12.0844 10.48L12.0841 10.4804L12.0839 10.4806ZM12.0839 10.4806C12.0838 10.4807 12.0837 10.4807 12.0837 10.4808L12.503 10.843C12.0837 10.4808 12.0838 10.4807 12.0839 10.4806Z"
      />
    </g>
  </Svg>
);
