import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FileIcon: FC<React.PropsWithChildren<SvgProps & { isBump?: boolean }>> = ({
  isBump, ...props
}) => {
  return isBump ? (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3420_17424)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66669 1.33337H8.00002V4.66671C8.00002 5.77127 8.89542 6.66671 10 6.66671H13.3334V12.6667C13.3334 13.7713 12.438 14.6667 11.3334 14.6667H4.66669C3.56212 14.6667 2.66669 13.7713 2.66669 12.6667V3.33337C2.66669 2.22881 3.56212 1.33337 4.66669 1.33337ZM9.33331 1.72388L12.9428 5.33335H9.99998C9.63178 5.33335 9.33331 5.03487 9.33331 4.66668V1.72388Z"
          fill="url(#paint0_linear_3420_17424)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3420_17424"
          x="-0.666667"
          y="0"
          width="17.3333"
          height="17.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3420_17424"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3420_17424"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3420_17424"
          x1="8.00002"
          y1="1.33337"
          x2="8.00002"
          y2="14.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0.5"
          />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41954 1C9.75763 1 10.0317 1.27408 10.0317 1.61217V4.4512C10.0317 4.47709 10.042 4.50191 10.0603 4.52021C10.0786 4.53851 10.1034 4.5488 10.1293 4.5488H12.9683C13.3064 4.5488 13.5805 4.82287 13.5805 5.16096C13.5805 5.49905 13.3064 5.77313 12.9683 5.77313H10.1293C9.7787 5.77313 9.44247 5.63386 9.19456 5.38595C8.94665 5.13804 8.80737 4.8018 8.80737 4.4512V1.61217C8.80737 1.27408 9.08145 1 9.41954 1Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.45124 2.22433C4.23712 2.22433 4.03177 2.30939 3.88036 2.4608C3.72895 2.61221 3.64389 2.81756 3.64389 3.03169V12.9683C3.64389 13.1824 3.72895 13.3878 3.88036 13.5392C4.03177 13.6906 4.23712 13.7757 4.45124 13.7757H11.5488C11.763 13.7757 11.9683 13.6906 12.1197 13.5392C12.2711 13.3878 12.3562 13.1824 12.3562 12.9683V5.41453L9.16599 2.22433H4.45124ZM3.01462 1.59507C3.39564 1.21405 3.9124 1 4.45124 1H9.41956C9.58191 1 9.73762 1.0645 9.85242 1.1793L13.4012 4.7281C13.516 4.8429 13.5805 4.99861 13.5805 5.16096V12.9683C13.5805 13.5072 13.3665 14.0239 12.9855 14.4049C12.6044 14.7859 12.0877 15 11.5488 15H4.45124C3.9124 15 3.39564 14.7859 3.01462 14.4049C2.63361 14.0239 2.41956 13.5072 2.41956 12.9683V3.03169C2.41956 2.49285 2.63361 1.97608 3.01462 1.59507Z"
        fill="currentcolor"
      />
    </Svg>
  );
};
