import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ClaapIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46 41"
    width="16"
    height="16"
    fill="none"
    data-no-fill
  >
    <path
      id="c"
      fill="#ff5a80"
      d="M8.57 15.36c-1.54 0-3-.34-4.1-.94a7.4 7.4 0 0 1-2.74-2.65 7.92 7.92 0 0 1 0-7.44 6.31 6.31 0 0 1 2.73-2.65 8.08 8.08 0 0 1 4.1-.94 8.63 8.63 0 0 1 4.62 1.37v3.93h-.25a11.5 11.5 0 0 0-2.31-1.36 5.02 5.02 0 0 0-4.1 0c-.6.25-1.12.68-1.54 1.28a3.5 3.5 0 0 0-.6 2.13 3.5 3.5 0 0 0 2.13 3.42 5 5 0 0 0 4.1 0 7.12 7.12 0 0 0 2.23-1.37h.26v3.93a6.4 6.4 0 0 1-1.88.94c-.77.26-1.63.35-2.65.35Z"
    />
    <path
      id="l"
      fill="#ff5a80"
      d="M13.66 17.2 19.35.38l3.56 1.2-5.68 16.84-3.57-1.2Z"
    />
    <path
      id="a1"
      fill="#ff5a80"
      d="M28.4 23.31c-1.32.34-2.56.3-3.56-.16-1.08-.43-1.74-1.32-2.08-2.64a3.75 3.75 0 0 1 1.07-3.8c1.05-1.06 2.63-1.8 4.61-2.31 1.08-.27 2.26-.48 3.44-.7l-.04-.16a2.58 2.58 0 0 0-1.04-1.32c-.52-.31-1-.46-1.53-.5a5.51 5.51 0 0 0-1.72.17 7.4 7.4 0 0 0-2.53 1.17 7.27 7.27 0 0 0-1.98 1.91l-.25.06-.9-3.56a8.28 8.28 0 0 1 2.3-1.73c.92-.5 1.88-.91 2.95-1.18a7.5 7.5 0 0 1 3.94-.12c1.14.33 2.16.87 2.9 1.74a7.32 7.32 0 0 1 1.57 3.04l1.95 7.7-3.4.86-1.05-5.2-.25.07c1.1 4.66-2.32 6.14-4.4 6.66Zm.5-2.94a3.8 3.8 0 0 0 2.46-1.77c.5-.83.64-2.01.38-3.36l-1.98.5c-1.58.4-2.61.84-3.17 1.42s-.77 1.16-.6 1.83c.17.66.54 1.1 1.02 1.24.6.29 1.22.3 1.89.14Z"
    />
    <path
      id="a2"
      fill="#ff5a80"
      d="M17.44 36.54a5.43 5.43 0 0 1-3.57.23c-1.11-.32-1.87-1.13-2.35-2.41a3.75 3.75 0 0 1 .66-3.9c.93-1.16 2.41-2.07 4.34-2.79 1.04-.39 2.19-.72 3.34-1.06l-.06-.16c-.32-.6-.74-1-1.18-1.2a3.34 3.34 0 0 0-1.58-.33 5.5 5.5 0 0 0-1.69.36 7.4 7.4 0 0 0-2.38 1.43 7.3 7.3 0 0 0-1.77 2.12l-.24.09-1.28-3.45a8.24 8.24 0 0 1 2.1-1.96c.87-.6 1.77-1.12 2.81-1.5a7.54 7.54 0 0 1 3.9-.54c1.17.2 2.24.63 3.08 1.41a7.36 7.36 0 0 1 1.88 2.86l2.77 7.45-3.29 1.22-1.6-5.06-.24.09c1.66 4.49-1.66 6.36-3.66 7.1Zm.25-3.01a3.8 3.8 0 0 0 2.25-2.03c.4-.87.42-2.06.03-3.38l-1.93.72c-1.52.56-2.5 1.1-3 1.75-.49.64-.63 1.24-.39 1.88.24.64.66 1.03 1.15 1.12.55.25 1.17.2 1.89-.06Z"
    />
    <path
      id="p"
      fill="#ff5a80"
      d="m25.15 38.12 9.28-15.16 3.28 2-2.91 3.94.15.09a6.3 6.3 0 0 1 2.62-2c.91-.34 1.7-.47 2.53-.36a5.5 5.5 0 0 1 2.19.84 5.85 5.85 0 0 1 2.28 2.5c.49 1 .58 2.16.43 3.38a8.99 8.99 0 0 1-1.3 3.6 9.61 9.61 0 0 1-2.67 2.88 6.55 6.55 0 0 1-3.2 1.15 5.37 5.37 0 0 1-3.26-.9 6.37 6.37 0 0 1-2-1.82 4.19 4.19 0 0 1-.78-2.48c.01-1 .3-2.12.98-3.4l-.22-.14-1.34 3.18-2.86 4.67-3.2-1.97Zm9.88-2.07c.73.45 1.4.66 2.04.75.65.1 1.3 0 1.98-.29a4.2 4.2 0 0 0 1.63-1.5c.45-.73.66-1.4.56-2.07.02-.69-.2-1.32-.64-1.9a5.6 5.6 0 0 0-3.65-2.23c-.64-.09-1.3 0-1.98.3-.6.33-1.18.78-1.58 1.43a3.5 3.5 0 0 0-.6 2.14c-.02.69.2 1.32.64 1.9a5.6 5.6 0 0 0 1.6 1.47Z"
    />
  </Svg>
);
