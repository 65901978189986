import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CheckIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7803 3.83822C13.921 3.97908 14 4.17001 14 4.36908C14 4.56815 13.921 4.75909 13.7803 4.89994L6.51858 12.1617C6.37773 12.3023 6.1868 12.3814 5.98773 12.3814C5.78866 12.3814 5.59772 12.3023 5.45687 12.1617L2.20162 8.9064C2.06892 8.764 1.99668 8.57565 2.00012 8.38103C2.00355 8.18642 2.08239 8.00073 2.22003 7.8631C2.35766 7.72546 2.54335 7.64662 2.73796 7.64319C2.93258 7.63976 3.12093 7.712 3.26333 7.84469L5.98773 10.5691L12.7186 3.83822C12.8594 3.69755 13.0504 3.61853 13.2494 3.61853C13.4485 3.61853 13.6395 3.69755 13.7803 3.83822Z"
    />
  </Svg>
);
