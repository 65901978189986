import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const BotIcon: FC<SvgProps> = (props) => (
  <Svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 9.75H2.5M11.5 9.75C11.9142 9.75 12.25 9.41421 12.25 9V3.75C12.25 3.33579 11.9142 3 11.5 3H7M11.5 9.75V11.25M2.5 9.75C2.08579 9.75 1.75 9.41421 1.75 9V3.75C1.75 3.33579 2.08579 3 2.5 3H7M2.5 9.75L2.5 11.25M7 3V1.5M4.75 6V6.75M9.25 6V6.75M11.5 11.25C11.5 13.7353 9.48528 15.75 7 15.75C4.51472 15.75 2.5 13.7353 2.5 11.25M11.5 11.25L13 12.75M2.5 11.25L1 12.75"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
