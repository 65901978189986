import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CursorIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.73967 5.64427L8.65732 20.0263C9.10318 21.3302 10.9237 21.3934 11.4589 20.1235L13.7907 14.5904C13.9427 14.2297 14.2297 13.9427 14.5904 13.7907L20.1235 11.4589C21.3934 10.9237 21.3302 9.10315 20.0263 8.65729L5.64431 3.73964C4.46461 3.33627 3.3363 4.46457 3.73967 5.64427Z"
      stroke="currentcolor"
      strokeWidth="2.05556"
      strokeLinejoin="round"
    />
  </Svg>
);
