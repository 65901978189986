import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CodeIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.94998 4.68548C6.21452 4.42093 6.21452 3.99201 5.94998 3.72746C5.68543 3.46292 5.25651 3.46292 4.99196 3.72746L1.19841 7.52101C0.933863 7.78556 0.933863 8.21448 1.19841 8.47903L4.99196 12.2726C5.25651 12.5371 5.68543 12.5371 5.94998 12.2726C6.21452 12.008 6.21452 11.5791 5.94998 11.3146L2.63543 8.00002L5.94998 4.68548ZM11.008 3.72746C10.7434 3.46292 10.3145 3.46292 10.05 3.72746C9.78543 3.99201 9.78543 4.42093 10.05 4.68548L13.3645 8.00002L10.05 11.3146C9.78543 11.5791 9.78543 12.008 10.05 12.2726C10.3145 12.5371 10.7434 12.5371 11.008 12.2726L14.8015 8.47903C15.0661 8.21448 15.0661 7.78556 14.8015 7.52101L11.008 3.72746Z"
      fill="#171717"
    />
  </Svg>
);
