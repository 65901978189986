import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MondayIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <g
      id="monday-1 1"
      clipPath="url(#clip0_8950_13220)"
    >
      <path
        id="Vector"
        d="M8.02464 9.88128C7.32487 9.86402 6.74754 9.55258 6.37889 8.90249C6.00103 8.23048 6.01748 7.54254 6.42036 6.88713C7.37951 5.32731 8.35116 3.77611 9.31689 2.22094C9.51438 1.90286 9.70726 1.58014 9.91397 1.26804C10.1884 0.84117 10.6171 0.539215 11.109 0.426474C11.6008 0.313732 12.1168 0.399112 12.5472 0.664432C13.4563 1.22953 13.7604 2.40488 13.1943 3.33121C12.037 5.23257 10.8632 7.12464 9.67303 9.00741C9.30635 9.58977 8.75009 9.87066 8.02464 9.88128Z"
        fill="#FFCB00"
      />
      <path
        id="Vector_2"
        d="M2.01411 9.88115C1.30776 9.86389 0.73372 9.53851 0.372313 8.89107C-0.000285745 8.21906 0.0234131 7.52979 0.42761 6.87439C1.45939 5.20455 2.49577 3.53715 3.53676 1.87219C3.66842 1.65704 3.80008 1.43658 3.94096 1.22807C4.22663 0.810467 4.6622 0.5214 5.15546 0.422073C5.64872 0.322746 6.16093 0.420956 6.58366 0.695911C7.00639 0.970866 7.30645 1.40098 7.42029 1.89519C7.53414 2.38939 7.45285 2.90889 7.19363 3.3437C6.04028 5.22226 4.87114 7.09418 3.70134 8.96412C3.3215 9.57038 2.7626 9.87053 2.01411 9.88115Z"
        fill="#FF3D57"
      />
      <path
        id="Vector_3"
        d="M14.0736 6.07328C15.1381 6.07859 15.9998 6.93188 15.9979 7.97841C15.9979 9.04087 15.1131 9.89748 14.0387 9.8842C12.9644 9.87092 12.1178 9.02692 12.1191 7.97575C12.1205 6.90931 12.9855 6.06664 14.0736 6.07328Z"
        fill="#00D647"
      />
    </g>
    <defs>
      <clipPath id="clip0_8950_13220">
        <rect
          width="16"
          height="10"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
