import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MinusIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.16667 6.8363H13.8333C14.1428 6.8363 14.4395 6.95891 14.6583 7.17716C14.8771 7.3954 15 7.69141 15 8.00005C15 8.3087 14.8771 8.6047 14.6583 8.82295C14.4395 9.04119 14.1428 9.1638 13.8333 9.1638H6.83333H2.16667C1.85725 9.1638 1.5605 9.04119 1.34171 8.82295C1.12292 8.6047 1 8.3087 1 8.00005C1 7.69141 1.12292 7.3954 1.34171 7.17716C1.5605 6.95891 1.85725 6.8363 2.16667 6.8363H9.16667Z" />
  </Svg>
);
