import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CheckboxIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6672 1H3.33382C2.71498 1 2.12149 1.24583 1.68391 1.68342C1.24632 2.121 1.00049 2.71449 1.00049 3.33333V12.6667C1.00049 13.2855 1.24632 13.879 1.68391 14.3166C2.12149 14.7542 2.71498 15 3.33382 15H12.6672C13.286 15 13.8795 14.7542 14.3171 14.3166C14.7547 13.879 15.0005 13.2855 15.0005 12.6667V3.33333C15.0005 2.71449 14.7547 2.121 14.3171 1.68342C13.8795 1.24583 13.286 1 12.6672 1ZM13.4449 12.6667C13.4449 12.8729 13.363 13.0708 13.2171 13.2166C13.0713 13.3625 12.8734 13.4444 12.6672 13.4444H3.33382C3.12754 13.4444 2.92971 13.3625 2.78385 13.2166C2.63799 13.0708 2.55604 12.8729 2.55604 12.6667V3.33333C2.55604 3.12705 2.63799 2.92922 2.78385 2.78336C2.92971 2.6375 3.12754 2.55556 3.33382 2.55556H12.6672C12.8734 2.55556 13.0713 2.6375 13.2171 2.78336C13.363 2.92922 13.4449 3.12705 13.4449 3.33333V12.6667Z" />
    <path d="M10.1004 5.19231L7.16042 9.08119L5.89264 7.44008C5.76578 7.27712 5.57938 7.17123 5.37444 7.14571C5.16951 7.12018 4.96282 7.17711 4.79986 7.30397C4.6369 7.43083 4.53101 7.61724 4.50549 7.82217C4.47996 8.02711 4.53689 8.23379 4.66375 8.39675L6.55375 10.8156C6.627 10.9083 6.7204 10.9831 6.82686 11.0343C6.93333 11.0855 7.05006 11.1118 7.1682 11.1112C7.28698 11.1109 7.40411 11.0834 7.51062 11.0308C7.61713 10.9783 7.71018 10.902 7.78264 10.8079L11.3371 6.14119C11.4629 5.97617 11.518 5.76792 11.4903 5.56226C11.4626 5.35659 11.3543 5.17036 11.1893 5.04453C11.0243 4.9187 10.816 4.86358 10.6104 4.89129C10.4047 4.919 10.2185 5.02728 10.0926 5.19231H10.1004Z" />
  </Svg>
);
