import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const NoteIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 18 18"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 11H10M6 7H12M4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Svg>
);
