import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiShortenIcon = (props:SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="minimize, arrow, shrink"
      clipPath="url(#clip0_1627_18714)"
    >
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66842 1.39542C7.66842 1.108 7.90143 0.875 8.18885 0.875C8.47627 0.875 8.70927 1.108 8.70927 1.39542V4.55473L12.2366 1.02743C12.4398 0.82419 12.7693 0.82419 12.9726 1.02743C13.1758 1.23067 13.1758 1.56018 12.9726 1.76342L9.44526 5.29073H12.6046C12.892 5.29073 13.125 5.52373 13.125 5.81115C13.125 6.09857 12.892 6.33158 12.6046 6.33158H8.69836C8.12954 6.33158 7.66842 5.87046 7.66842 5.30164V1.39542ZM0.875 8.18885C0.875 7.90143 1.108 7.66842 1.39542 7.66842H5.30164C5.87046 7.66842 6.33158 8.12954 6.33158 8.69836V12.6046C6.33158 12.892 6.09857 13.125 5.81115 13.125C5.52373 13.125 5.29073 12.892 5.29073 12.6046V9.44526L1.76342 12.9726C1.56018 13.1758 1.23067 13.1758 1.02743 12.9726C0.82419 12.7693 0.82419 12.4398 1.02743 12.2366L4.55473 8.70927H1.39542C1.108 8.70927 0.875 8.47627 0.875 8.18885Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1627_18714">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </Svg>
);
