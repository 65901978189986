import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PlaceHolderIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 4.5V1.875C1 1.64294 1.09219 1.42038 1.25628 1.25628C1.42038 1.09219 1.64294 1 1.875 1H4.5V2.75H2.75V4.5H1Z" />
    <path d="M11.5 1H14.125C14.3571 1 14.5796 1.09219 14.7437 1.25628C14.9078 1.42038 15 1.64294 15 1.875V4.5H13.25V2.75H11.5V1Z" />
    <path d="M9.75 1H6.25V2.75H9.75V1Z" />
    <path d="M1 14.125V11.5H2.75V13.25H4.5V15H1.875C1.64294 15 1.42038 14.9078 1.25628 14.7437C1.09219 14.5796 1 14.3571 1 14.125Z" />
    <path d="M13.25 13.25H11.5V15H14.125C14.3571 15 14.5796 14.9078 14.7437 14.7437C14.9078 14.5796 15 14.3571 15 14.125V11.5H13.25V13.25Z" />
    <path d="M6.25 13.25H9.75V15H6.25V13.25Z" />
    <path d="M1 6.25V9.75H2.75V6.25H1Z" />
    <path d="M13.25 9.75V6.25H15V9.75H13.25Z" />
  </Svg>
);
