import hotkeys, { HotkeysEvent } from 'hotkeys-js';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useMemo,
} from 'react';

import { getBreakpoint } from '../utils/breakpoints.utils';
import { isChromeExtension } from '../utils/chrome.utils';
import { scrollIntoView } from '../utils/html.utils';
import { Direction, getTargetIndex } from '../utils/select.utils';

type NavMode = 'mouse' | 'keyboard';
export type ItemPropsFunction = (val: string) => {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  style?: CSSProperties;
  'data-value': string;
};

interface UseListNavParams {
  optionsValues: string[];
  value?: string | null;
  onSelect?: (value: string | null, event?: MouseEvent) => void;
  autoFocus?: boolean;
  defaultIndex?: number;
  enabled?: boolean;
  selectOnHover?: boolean;
}

interface UseListNavResult {
  selected: string | null;
  hovered: string | null;
  hoverDisabled: boolean;
  listProps: {
    onMouseEnter: () => void;
    onMouseMove: () => void;
    'data-scope': string;
  };
  itemProps: ItemPropsFunction;
  select: (o: string) => void;
}

export const useListNav = ({
  optionsValues,
  value,
  onSelect,
  enabled: enabledFromProps = true,
  autoFocus = false,
  defaultIndex = 0,
  selectOnHover = false,
}: UseListNavParams): UseListNavResult => {
  const scope = useMemo(() => `list-nav-${crypto.randomUUID()}`, []);
  const enabled = !isChromeExtension && getBreakpoint() === 'mobile' ? false : enabledFromProps;

  const optionsUpdatedRef = useRef(false);
  const initialSelect = optionsValues.find((optionsValue) => optionsValue === value && optionsValue) ?? null;
  const [selected, setSelected] = useState(initialSelect);
  const [hovered, setHovered] = useState(initialSelect);
  const selectedRef = useRef(initialSelect);
  const lastSelectedRef = useRef(initialSelect);

  const [navMode, setNavMode] = useState<NavMode>('mouse');

  useEffect(() => {
    // Don't select the first value when this effect is triggered for the first time
    if ((!autoFocus && !optionsUpdatedRef.current)) {
      optionsUpdatedRef.current = true;
      return;
    }

    if (optionsValues.length > 0 && (!selected || !optionsValues.includes(selected))) {
      // Focus the first value
      selectedRef.current = optionsValues[defaultIndex] ?? null;
      setSelected(optionsValues[defaultIndex] ?? null);
    }
  }, [optionsValues]);

  const navOptions = useCallback((direction: Direction) => {
    setNavMode('keyboard');

    const targetIndex = getTargetIndex(direction, optionsValues, selectedRef.current);
    selectedRef.current = optionsValues[targetIndex] ?? null;
    setSelected(optionsValues[targetIndex] ?? null);
    if (selectOnHover) {
      setHovered(optionsValues[targetIndex] ?? null);
    }
  }, [optionsValues, selectOnHover]);

  const hotkeyEvent = useCallback(
    (e: KeyboardEvent, handler: HotkeysEvent) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      switch (handler.key) {
        case 'down':
          navOptions('next');
          scrollIntoView(document.querySelector(`[data-scope="${scope}"] [data-value="${selectedRef.current}"]`), false);
          break;
        case 'up':
          navOptions('previous');
          scrollIntoView(document.querySelector(`[data-scope="${scope}"] [data-value="${selectedRef.current}"]`), true);
          break;
        case 'enter':
        default:
          onSelect?.(selectedRef.current);
      }
    },
    [navOptions, onSelect, scope],
  );

  // Lifecycle component
  useEffect(() => {
    if (enabled) {
      hotkeys.filter = () => true;
      hotkeys.setScope(scope);
      hotkeys('enter, down, up', scope, hotkeyEvent);
    } else {
      hotkeys.deleteScope(scope);
    }

    return () => hotkeys.deleteScope(scope);
  }, [enabled, hotkeyEvent, scope]);

  // Listeners
  const onMouseEnterContainer = () => {
    setSelected(null);
  };

  const onMouseMoveContainer = () => {
    setNavMode('mouse');
    setSelected(null);
    selectedRef.current = lastSelectedRef.current;
  };

  const onMouseEnterOption = (val: string) => {
    selectedRef.current = val;
    lastSelectedRef.current = val;
    if (selectOnHover) {
      setHovered(val);
    }
  };

  const onMouseLeaveOption = () => {
    if (navMode === 'mouse') {
      selectedRef.current = null;
    }
  };

  /**
   * Select function would let a programmatically list selection
   * @param option The option to select
   */
  const select = (option: string) => {
    const index = optionsValues.findIndex(o => o === option);

    if (index === -1) {
      console.warn('Option was not found in the optionsValues list');
      return;
    }

    selectedRef.current = optionsValues[index] ?? null;
    setSelected(optionsValues[index] ?? null);
  };

  const hoverDisabled = enabled && navMode === 'keyboard';

  /*
  We'd like to disable hover css with `pointer-events: none` in style props
  But in some cases, there's chrome/webkit issue, so for the moment we also send the `hoverDisabled`,
  and the implementation need to handle the hover logic
  */

  return {
    // state
    selected: enabled ? selected : null,
    hovered: enabled && selectOnHover ? hovered : null,
    hoverDisabled,

    // props to inject
    listProps: {
      onMouseEnter: onMouseEnterContainer,
      onMouseMove: onMouseMoveContainer,
      'data-scope': scope,
    },
    itemProps: (val: string) => ({
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect?.(val, e);
      },
      onMouseEnter: () => onMouseEnterOption(val),
      onMouseLeave: onMouseLeaveOption,
      'data-value': val,
    }),

    // Handler
    select,
  };
};
