import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DropboxIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path d="M5.1125 1.35059L1 4.16877L3.8875 6.5324L8 3.80513L12.1125 6.5324L15 4.16877L10.8875 1.35059L8 3.80513L5.1125 1.35059Z" />
    <path d="M1 8.89608L5.1125 11.6234L8 9.1688L10.8875 11.6234L15 8.89608L12.1125 6.5324L8 9.1688L3.8875 6.5324L1 8.89608Z" />
    <path d="M7.78122 9.98252L10.7509 12.1435L12.0541 11.2486V12.1435L7.97081 14.6492L3.88747 12.1435V11.2486L5.19066 12.1435L7.78122 9.98252Z" />
  </Svg>
);
