import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ArrowRightUpIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66715 3.83333C4.66715 3.3731 5.04025 3 5.50049 3H12.1672C12.6274 3 13.0005 3.3731 13.0005 3.83333V10.5C13.0005 10.9602 12.6274 11.3333 12.1672 11.3333C11.7069 11.3333 11.3338 10.9602 11.3338 10.5V5.84518L4.42308 12.7559C4.09764 13.0813 3.57 13.0813 3.24456 12.7559C2.91913 12.4305 2.91913 11.9028 3.24456 11.5774L10.1553 4.66667H5.50049C5.04025 4.66667 4.66715 4.29357 4.66715 3.83333Z"
      fill="currentcolor"
    />
  </Svg>
);
