import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TriangleIcon: FC<SvgProps> = ({
  direction = 'bottom', ...props
}) => (
  <Svg
    {...props}
    direction={direction}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.06043 6.43835L7.85438 9.9246C7.87348 9.9485 7.89618 9.96746 7.92117 9.9804C7.94616 9.99334 7.97295 10 8 10C8.02705 10 8.05384 9.99334 8.07883 9.9804C8.10382 9.96746 8.12652 9.9485 8.14562 9.9246L10.9396 6.43835C10.9684 6.40245 10.9881 6.35668 10.996 6.30684C11.004 6.25699 10.9999 6.20532 10.9843 6.15837C10.9687 6.11142 10.9423 6.0713 10.9084 6.04311C10.8745 6.01491 10.8347 5.99991 10.794 6H5.20605C5.16531 5.99991 5.12548 6.01491 5.09159 6.04311C5.05769 6.0713 5.03127 6.11142 5.01568 6.15837C5.00008 6.20532 4.99601 6.25699 5.00397 6.30684C5.01194 6.35668 5.03159 6.40245 5.06043 6.43835Z" />
  </Svg>
);
