import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GumroadIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M10.5961 24C4.13431 24 0.333344 18.766 0.333344 12.2554C0.333344 5.4893 4.51437 0 12.4964 0C20.732 0 23.5192 5.61712 23.6459 8.80844H17.6911C17.5645 7.02128 16.044 4.34047 12.3698 4.34047C8.44222 4.34047 5.90814 7.78728 5.90814 12.0001C5.90814 16.2129 8.44222 19.6595 12.3698 19.6595C15.9174 19.6595 17.4378 16.851 18.0713 14.0427H12.3698V11.7447H24.3333V23.4893H19.0849V16.0851C18.7047 18.766 17.0576 24 10.5961 24Z"
      fill="#FF90E8"
    />
  </Svg>
);
