import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const LockIcon: FC<SvgProps> = (props) => (
  <Svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    data-no-fill
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8H4M12 8C13.6569 8 15 9.34315 15 11V16C15 17.6569 13.6569 19 12 19H4C2.34315 19 1 17.6569 1 16V11C1 9.34315 2.34315 8 4 8M12 8V5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5V8M8 12V15"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
