import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SingleSelectIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13.6002C4.90721 13.6002 2.4 11.093 2.4 8.00024C2.4 4.90745 4.90721 2.40024 8 2.40024C11.0928 2.40024 13.6 4.90745 13.6 8.00024C13.6 11.093 11.0928 13.6002 8 13.6002ZM1 8.00024C1 4.13425 4.13401 1.00024 8 1.00024C11.866 1.00024 15 4.13425 15 8.00024C15 11.8662 11.866 15.0002 8 15.0002C4.13401 15.0002 1 11.8662 1 8.00024ZM5.86997 6.63027C5.59661 6.3569 5.15339 6.3569 4.88003 6.63027C4.60666 6.90364 4.60666 7.34685 4.88003 7.62022L7.50503 10.2452C7.77839 10.5186 8.22161 10.5186 8.49497 10.2452L11.12 7.62022C11.3933 7.34685 11.3933 6.90364 11.12 6.63027C10.8466 6.3569 10.4034 6.3569 10.13 6.63027L8 8.76029L5.86997 6.63027Z"
    />
  </Svg>
);
