import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const InfoIconOutline = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg
    {...props}
    ref={ref}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.3125C6.49158 2.3125 5.04494 2.91172 3.97833 3.97833C2.91172 5.04494 2.3125 6.49158 2.3125 8C2.3125 9.50842 2.91172 10.9551 3.97833 12.0217C5.04494 13.0883 6.49158 13.6875 8 13.6875C9.50842 13.6875 10.9551 13.0883 12.0217 12.0217C13.0883 10.9551 13.6875 9.50842 13.6875 8C13.6875 6.49158 13.0883 5.04494 12.0217 3.97833C10.9551 2.91172 9.50842 2.3125 8 2.3125ZM1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85651 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85651 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85651 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85651 1 8ZM6.6875 7.78125C6.6875 7.6072 6.75664 7.44028 6.87971 7.31721C7.00278 7.19414 7.1697 7.125 7.34375 7.125H8.21875C8.3928 7.125 8.55972 7.19414 8.68279 7.31721C8.80586 7.44028 8.875 7.6072 8.875 7.78125V10.1875H9.09375C9.2678 10.1875 9.43472 10.2566 9.55779 10.3797C9.68086 10.5028 9.75 10.6697 9.75 10.8438C9.75 11.0178 9.68086 11.1847 9.55779 11.3078C9.43472 11.4309 9.2678 11.5 9.09375 11.5H7.34375C7.1697 11.5 7.00278 11.4309 6.87971 11.3078C6.75664 11.1847 6.6875 11.0178 6.6875 10.8438C6.6875 10.6697 6.75664 10.5028 6.87971 10.3797C7.00278 10.2566 7.1697 10.1875 7.34375 10.1875H7.5625V8.4375H7.34375C7.1697 8.4375 7.00278 8.36836 6.87971 8.24529C6.75664 8.12222 6.6875 7.9553 6.6875 7.78125ZM8 6.25C8.23206 6.25 8.45462 6.15781 8.61872 5.99372C8.78281 5.82962 8.875 5.60706 8.875 5.375C8.875 5.14294 8.78281 4.92038 8.61872 4.75628C8.45462 4.59219 8.23206 4.5 8 4.5C7.76794 4.5 7.54538 4.59219 7.38128 4.75628C7.21719 4.92038 7.125 5.14294 7.125 5.375C7.125 5.60706 7.21719 5.82962 7.38128 5.99372C7.54538 6.15781 7.76794 6.25 8 6.25Z"
      fill="currentcolor"
    />
  </Svg>
));
